import {
  AfterViewInit,
  Component,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
} from '@angular/router';

import {
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';

import {
  EmptyPanelComponent,
  NavigationArea,
  PlatformNavigationComponent,
  TaskManagerService,
} from '@skykick/core';

import { InfoPanelComponent } from '../settings/integrations/connectwise/info/info-panel.component';
import { TaskType } from '../settings/models/task-type';
import { CustomerDetailsPanelComponent } from '../settings/users/components/customers/customer-details-panel/customer-details-panel.component';
import { MemberDetailsPanelComponent } from '../settings/users/components/members/member-details-panel/member-details-panel.component';

@Component({
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
})

export class PortalComponent implements AfterViewInit {
  @ViewChild(PlatformNavigationComponent)
  public skPlatformNavigation: PlatformNavigationComponent;

  routeData: any;
  currentNavigationArea: NavigationArea;

  constructor(private router: Router, private route: ActivatedRoute, private taskManagerService: TaskManagerService) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.firstChild),
        filter((activatedRoute: ActivatedRoute) => activatedRoute.outlet === 'primary'),
        mergeMap((activatedRoute: ActivatedRoute) => activatedRoute.data)
      )
      .subscribe((event: { [name: string]: any }) => {
        this.currentNavigationArea = event.SkNavigationArea;
      });

    /*
      TaskManagerService is a part of '@skykick/core' package implemented in SkyKick.Web.Angular repo.
      This service is responsible for opening panes next to the main content. 'Task' is an abstraction for a pane.
      Samples of how to use this service can be found in SkyKick.Web.Angular (playground project) and SkyKick.Manager
      (in the 'Web' folder).
      Here is a small guide for how to use the service:
      1) Call registerUniversalTask() with the task name to register a task.
      2) Call configureTask() with the task name, context for passing data to the pane and component you want to show.
      3) Call activateTask() with the task name to show the pane.
    */
    this.taskManagerService.registerUniversalTask(TaskType.Empty, EmptyPanelComponent);
    this.taskManagerService.registerUniversalTask(TaskType.MemberDetails, MemberDetailsPanelComponent);
    this.taskManagerService.registerUniversalTask(TaskType.CustomerDetails, CustomerDetailsPanelComponent);
    this.taskManagerService.registerUniversalTask(TaskType.ConnectWiseInfo, InfoPanelComponent);
  }

  ngAfterViewInit(): void {
    this.skPlatformNavigation.SkNavigationArea = this.currentNavigationArea;
  }
}
