import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskManagerService } from '@skykick/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, skipWhile, takeUntil, tap } from 'rxjs/operators';
import { TaskType } from 'src/app/settings/models/task-type';
import { PRORATIONSETTING } from '../../core/constants/proration-setting.const';
import { WizardStepComponent } from '../../core/models/WizardStepComponent';
import { ActionButtonsService } from '../../core/services/action-buttons.service';
import { ConnectwiseResourcesService } from '../../core/services/connectwise-resources.service';
import { ConnectwiseStateManagerService } from '../../core/services/connectwise-state-manager.service';

@Component({
    selector: 'sk-map-ticket',
    templateUrl: './map-ticket.component.html',
    styleUrls: ['./map-ticket.component.scss']
})
export class MapTicketComponent implements WizardStepComponent, OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    @HostBinding('class') class = 'd-flex flex-column flex-grow-1';
    @Input() data: any;
    @Input() settingType: string;
    ticketMappingForm: UntypedFormGroup;
    isLoadingServiceBoards: boolean = true;
    isBusy: boolean;
    errors: Array<string> = [];
    connectWiseServiceBoards$: Observable<any>;
    ticketMapping: any;
    partnerData: any;

    private addMapping(boardObject) {
        this.isBusy = true;
        this.isLoadingServiceBoards = true;
        this.connectwiseResourcesService.addTicketMapping(boardObject.name).subscribe(res => {
            this.ticketMapping = res;
            this.connectwiseStateManagerService.markAsCompleteWizardNav(this.data.type);
            this.toastrService.success('Ticket Mapping Activated');
            this.isBusy = false;
            this.ticketMappingForm.get('connectWiseServiceBoardSelect').disable({ emitEvent: false });
            this.isLoadingServiceBoards = false;
          });
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        public activeModal: NgbActiveModal,
        private actionButtonsService: ActionButtonsService,
        private connectwiseResourcesService: ConnectwiseResourcesService,
        private toastrService: ToastrService,
        private connectwiseStateManagerService: ConnectwiseStateManagerService,
        private taskManagerService: TaskManagerService
    ) { }

    ngOnInit(): void {
        this.partnerData = this.connectwiseStateManagerService.getConnectWisePartner();
        this.ticketMappingForm = this.formBuilder.group({
            connectWiseServiceBoardSelect: [{ value: null, disabled: true }]
        });

        const serviceBoards$ = this.connectwiseResourcesService.getServiceBoards().pipe(
            tap(res => {
                this.isLoadingServiceBoards = false;
                this.ticketMappingForm.get('connectWiseServiceBoardSelect').enable({ emitEvent: false });
            })
        );

        const ticketMapping$ = this.connectwiseResourcesService.getTicketMapping();

        forkJoin([serviceBoards$, ticketMapping$]).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(([serviceBoards, ticketMapping]) => {
            this.connectWiseServiceBoards$ = of(serviceBoards);
            if (ticketMapping) {
                this.connectwiseStateManagerService.markAsCompleteWizardNav(this.data.type);
                this.ticketMapping = ticketMapping;
                const serviceBoard = serviceBoards.find(item => item.name === ticketMapping.board);
                if (serviceBoard) {
                    this.ticketMappingForm.get('connectWiseServiceBoardSelect').patchValue(serviceBoard, {emitEvent: false});
                    this.ticketMappingForm.get('connectWiseServiceBoardSelect').disable({ emitEvent: false });
                }
             }
        });

        this.ticketMappingForm.get('connectWiseServiceBoardSelect').valueChanges.pipe(
            distinctUntilChanged(),
            skipWhile(val => !val)
        ).subscribe(boardObject => {
            if (boardObject) {
                this.addMapping(boardObject);
            }
        })
    }

    deleteMapping() {
        this.isBusy = true;
        this.isLoadingServiceBoards = true;
        this.connectwiseResourcesService.deleteTicketMapping(this.ticketMapping.ticketMappingId).subscribe(res => {
            this.ticketMappingForm.get('connectWiseServiceBoardSelect').patchValue(null);
            this.ticketMapping = null;
            this.connectwiseStateManagerService.markAsIncompleteWizardNav(this.data.type);
            this.toastrService.success('Ticket Mapping Removed');
            this.ticketMappingForm.get('connectWiseServiceBoardSelect').enable({ emitEvent: false });
            this.isLoadingServiceBoards = false;
            this.isBusy = false;
        });
        this.taskManagerService.abortTask(TaskType.ConnectWiseInfo);
    }

    submit() {
        this.isBusy = true;
        this.partnerData.prorationSetting = PRORATIONSETTING.CustomPush;
        this.errors.length = 0;
        this.connectwiseResourcesService.updatePartner(this.partnerData).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe({
            complete: () => {
                this.isBusy = false;
                this.toastrService.success('Update successful');
                this.actionButtonsService.submitWizardStep(this.data);
            }, 
            error: res => {
                this.isBusy = false;
                if (res.error) {
                    this.errors.push(res.error.message);
                }
            }
        });
        this.taskManagerService.abortTask(TaskType.ConnectWiseInfo);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

}
