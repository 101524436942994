import { Component, OnDestroy, OnInit } from '@angular/core';
import { SkyKickPanelTabView, SkyKickPanelTabViewBase } from '@skykick/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ActionButtonsService } from '../../core/services/action-buttons.service';
import { CustomerSettingsComponent } from '../customer-settings/customer-settings.component';

@Component({
    selector: 'sk-customer-aside-panel',
    templateUrl: './customer-aside-panel.component.html',
    styleUrls: ['./customer-aside-panel.component.scss']
})
export class CustomerAsidePanelComponent extends SkyKickPanelTabViewBase<any, void> implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    private subscription: Subscription;
    public panelTitle: string;

    private settingsNavTabTitle = new BehaviorSubject('Settings');
    private settingsNavTabTitle$: Observable<string> = this.settingsNavTabTitle.asObservable();

    constructor(
        public actionButtonsService: ActionButtonsService
    ) { 
        super();
    }

    ngOnInit(): void {
        this.panelTitle = this.data.orderName;
        this.bootstrap(
            new SkyKickPanelTabView(CustomerSettingsComponent, this.settingsNavTabTitle$, 'settings', this.data)
        );

        this.subscription = this.actionButtonsService.onCloseTask(() => this.operation.complete());
    }

    update() {
        this.actionButtonsService.submitPrimaryButton();
    }

    cancel() {
        this.operation.cancel();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.onDestroy$.next();
    }
}
