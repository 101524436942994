import { Component } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { PlatformNavigationService } from '@skykick/core';

@Component({
  selector: '[settings]',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  loading = false;

  constructor(
    private router: Router,
    private platformNavigationService: PlatformNavigationService
  )
  {
    this.router.events.subscribe(event => {
        this.toggleLoadingWhenRouterIsTriggered(event);
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  toggleLoadingWhenRouterIsTriggered(event: Event): void {
    switch (true) {
      case event instanceof NavigationStart: {
        this.loading = true;
        break;
      }
      case event instanceof NavigationEnd: {
        this.platformNavigationService.collapseNav();
        this.loading = false;
        break;
      }
      case event instanceof NavigationCancel:
      case event instanceof NavigationError: {
        this.loading = false;
        break;
      }
      default: {
        break;
      }
    }
  }
}
