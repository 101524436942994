import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { DeveloperComponent } from './developer.component';
import { ButtonsModule, FeedbackModule } from '@skykick/core';

@NgModule({
  declarations: [
    DeveloperComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ButtonsModule,
    FeedbackModule
  ]
})
export class DeveloperModule { }
