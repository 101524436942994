<!-- CONTENT COLUMN -->
<div class="sk-content-column">
    <div class="flex-center-center py-200" [ngClass]="{'d-none': !initialLoading}">
        <div class="sk-spin-loader"></div>
    </div>
    <!-- Heading  -->
    <div class="sk-scroll-container" *ngIf="!initialLoading">
        <div class="d-flex flex-row align-items-center justify-content-between py-100 px-200 border-bottom">
            <!-- Heading Left -->
            <div class="d-flex flex-row align-items-center">
                <div class="d-flex flex-column">
                    <h2 class="font-weight-normal">{{customer.fullName}}</h2>
                    <span class="text-muted">{{customer.email}}</span>
                </div>
            </div>
            <!-- Heading Right -->
            <div class="d-flex align-items-center">
                <sk-actions-menu
                    [showOutline] = "true"
                    *ngIf="customer.status === 'Active'">
                    <a (click)="resetPassword(customer)"
                        class="dropdown-item"
                        [hidden]="isResetPasswordUnavailable"
                        ngbDropdownItem>{{ 'settings.customers.actions.reset-password' | translate }}</a>
                    <a (click)="deactivateCustomer(customer)"
                        class="dropdown-item text-danger"
                        ngbDropdownItem>{{ 'settings.customers.actions.deactivate' | translate }}</a>
                </sk-actions-menu>
            </div>
        </div>
        <!-- /.flex-row -->

        <!-- CONTENT PRIMARY - SCROLLABLE -->
        <div class="sk-content-primary">
            <div class="sk-flex-table">
                <div id="scrollWrap" class="sk-scroll-container">
                    <div class="d-flex flex-column min-width-lg max-width-lg pt-150 pb-300">
                        <div class="row">
                            <div class="col-10">
                                <div class="pb-200">
                                    <div class="d-flex align-items-end pb-100">
                                        <h3 class="font-400">{{ 'settings.customer.set-permissions' | translate }}</h3>
                                        <a class="ml-100" target="_blank" href="https://support.skykick.com/hc/en-us/articles/4415630910739-Cloud-Backup-Customer-Self-Service-Portals">{{ 'settings.customer.learn-more' | translate }}</a>
                                    </div>

                                    <fieldset class="mb-100" *ngIf="isBackupOrder()">
                                        <h4 class="py-100">{{ 'settings.customer.cloud-backup' | translate }}</h4>
                                        <div class="form-check">
                                            <input type="radio" id="full-access" name="customRadio"
                                                class="form-check-input"
                                                [checked]="isPermissionSelected(CustomerPermission.FullAccess)">
                                            <label id="full-access-label" class="form-check-label font-medium" for="full-access"
                                                (click)="changePermission(CustomerPermission.FullAccess)">{{ 'settings.customer.full-access' | translate }}</label>
                                            <small class="sk-form-text">
                                                <ul class="sk-ul pl-100">
                                                    <li>{{ 'settings.customer.full-access-desc' | translate }}</li>
                                                </ul>
                                            </small>
                                        </div>
                                        <div class="form-check">
                                            <input type="radio" id="search-restore" name="customRadio"
                                                class="form-check-input"
                                                [checked]="isPermissionSelected(CustomerPermission.SearchAndRestore)">
                                            <label id="search-restore-label" class="form-check-label font-medium" for="search-restore"
                                                (click)="changePermission(CustomerPermission.SearchAndRestore)">{{ 'settings.customer.search-restore' | translate }}</label>
                                            <small class="sk-form-text">
                                                <ul class="sk-ul pl-100">
                                                    <li>{{ 'settings.customer.search-restore-desc' | translate }}</li>
                                                </ul>
                                            </small>
                                        </div>
                                    </fieldset>

                                    <fieldset class="mb-100" *ngIf="isMigrationOrder()">
                                        <h4 class="py-100">{{ 'settings.customer.migration-suits' | translate }}</h4>
                                        <div class="form-check">
                                            <input type="radio" id="read-only" name="customRadio"
                                                class="form-check-input"
                                                [checked]="isPermissionSelected(CustomerPermission.ReadOnly)">
                                            <label id="read-only-label" class="form-check-label font-medium" for="read-only"
                                                (click)="changePermission(CustomerPermission.ReadOnly)">{{ 'settings.customer.read-only' | translate }}</label>
                                            <small class="sk-form-text">
                                                <ul class="sk-ul pl-100">
                                                    <li>{{ 'settings.customer.read-only-desc' | translate }}</li>
                                                </ul>
                                            </small>
                                        </div>
                                        <div class="form-check">
                                            <input type="radio" id="read-write" name="customRadio"
                                                class="form-check-input"
                                                [checked]="isPermissionSelected(CustomerPermission.ReadAndWrite)">
                                            <label id="read-write-label" class="form-check-label font-medium" for="read-write"
                                                (click)="changePermission(CustomerPermission.ReadAndWrite)">{{ 'settings.customer.read-write' | translate }}</label>
                                            <small class="sk-form-text">
                                                <ul class="sk-ul pl-100">
                                                    <li>{{ 'settings.customer.read-write-desc' | translate }}</li>
                                                </ul>
                                            </small>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="col-2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sk-page-footer">
            <!-- Size Standard -->
            <div class="sk-footer-content py-150">
                <button id="update-customer" type="button" (click)="updateCustomer()" class="btn btn-lg btn-primary mr-100">{{ 'settings.customer.edit.update-customer' | translate }}</button>
                <button id="cancel" type="button" (click)="cancel()" class="btn btn-lg btn-outline-secondary mr-150">{{ 'settings.customer.edit.cancel' | translate }}</button>
            </div>
        </div>
    </div>
</div>