import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';

import {
  AppId,
  RouteResolverService,
} from '@skykick/core';

import { environment } from 'src/environments/environment';
import { Utils } from '../../../../../services/utils';
import {
  Customer,
  CustomerResult,
  CustomersSearchFilter,
  CustomerValidationStatus,
} from '../models';
import CustomerCreatedResponse from '../models/customer-created-response';
import CustomerUserRequest from '../models/customer-user-request';
import { CustomersConverterService } from './customers-converter.service';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  private basePath = `${this.routeResolverService.generatePipeRoute(AppId.PlatformApis)}${environment.apis.authenticationApimSuffix}/api/customers`;

  constructor(
    private http: HttpClient,
    private customersConverter: CustomersConverterService,
    private routeResolverService: RouteResolverService
  ) {}

  getCustomers(filter: CustomersSearchFilter): Observable<Customer[]> {
    return this.http
      .post<CustomerResult[]>(
        `${this.basePath}/search`,
        filter)
      .pipe(
        map<CustomerResult[], Customer[]>((resultArray) =>
          resultArray.map((res) =>
            this.customersConverter.convertToCustomer(res)
          )
        ),
        catchError(error => Utils.handleError(error, CustomersService.name))
      );
  }

  getCustomer(id: string): Observable<Customer> {
    return this.http.get<CustomerResult>(`${this.basePath}/${id}`)
      .pipe(
        map<CustomerResult, Customer>((result) => this.customersConverter.convertToCustomer(result)),
        catchError(error => Utils.handleError(error, CustomersService.name))
      );
  }

  addCustomer(customerInfo: CustomerUserRequest): Observable<CustomerCreatedResponse>{
    return this.http.post<any>(this.basePath, customerInfo)
      .pipe(
        catchError(error => Utils.handleError(error, CustomersService.name))
    );
  }

  validateCustomer(customerInfo: CustomerUserRequest): Observable<CustomerValidationStatus>{
    return this.http.post<any>(`${this.basePath}/validate`, customerInfo)
      .pipe(
        map(response => response.Status),
        catchError(error => Utils.handleError(error, CustomersService.name))
    );
  }

  editCustomer(customerInfo: CustomerUserRequest): Observable<boolean> {
    return this.http.put<any>(
      `${this.basePath}/${customerInfo.contactId}`, customerInfo,
      { observe: 'response' })
      .pipe(
        map(response => response.ok),
        catchError(error => Utils.handleError(error, CustomersService.name))
      );
  }

  deactivateCustomer(customerId: string): Observable<boolean> {
    return this.http.post<any>(
        `${this.basePath}/${customerId}/deactivate`,
        null,
        { observe: 'response' })
      .pipe(
          map(response => response.ok),
          catchError(error => Utils.handleError(error, CustomersService.name))
      );
  }

  deleteCustomer(customerId: string): Observable<boolean> {
    return this.http.delete<any>(`${this.basePath}/${customerId}`, { observe: 'response' })
      .pipe(
          map(response => response.ok),
          catchError(error => Utils.handleError(error, CustomersService.name))
      );
  }

  activateCustomer(customerId: string): Observable<boolean> {
    return this.http.post<any>(
        `${this.basePath}/${customerId}/activate`,
        null,
        { observe: 'response' })
      .pipe(
          map(response => response.ok),
          catchError(error => Utils.handleError(error, CustomersService.name))
      );
  }

  resetPassword(userName: string): Observable<boolean> {
    return this.http.post<any>(
        `${this.basePath}/resetpassword?userName=${encodeURIComponent(userName)}`,
        null,
        { observe: 'response' })
      .pipe(
          map(response => response.ok),
          catchError(error => Utils.handleError(error, CustomersService.name))
      );
  }
}
