export enum RecordType{
    A = 1,
    AAAA,
    CNAME,
    MX,
    NS,
    PTR,
    SOA,
    UKNOWN,
    SRV,
    TXT
}
