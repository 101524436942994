import { Injectable } from '@angular/core';

import { AuthenticationType, PartnerPortalUserClaims } from '../../../models/partner-portal-user-claims';

@Injectable({
  providedIn: 'root',
})

export class MemberValidator {
    getMemberErrors(member: any, partnerClaims: PartnerPortalUserClaims) : string[] {
        const errors: string[] = [];

        if (partnerClaims.authenticationType === AuthenticationType.M365Auth && !member.isUsernameMapped)
            errors.push('settings.O365.USER_IS_UNMAPPED');

        return errors;
    }
}
