<div class="sk-page-header header-light">
  <div class="sk-navbar-primary secondary-none">
    <div class="d-flex align-items-center min-w-0">
      <div class="d-flex min-w-0">
        <div class="sk-navbar-heading min-w-0">
          <h3 class="text-truncate">Developer</h3>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="sk-content-column">
  <div class="sk-content-primary">
    <div class="sk-flex-table">
      <div class="sk-scroll-container pt-200">
        <div class="row --g-0 min-width-md max-width-lg mb-200">
          <div class="col-lg">
            <div class="card pb-50">
              <div class="card-body">
                <div *ngIf="loading; else content">
                  <div class="d-flex align-items-center flex-column">
                    <div class="sk-spin-loader" ng-class="size"></div>
                  </div>
                </div>
                <ng-template #content>
                  <div *ngIf="failedToGetPartnerInfo; else success">
                    <div class="alert alert-danger" role="alert">
                      Failed to get API Developer role information. Please try again later.
                    </div>
                  </div>
                  <ng-template #success>
                    <div id="DeveloperPortal">
                      <div
                        id="RedirectionToDeveloperPortal"
                        *ngIf="partnerInfo.IsApiDeveloper; else howToEnable"
                      >
                        <div>
                          Click on the link to access the
                          <a
                            [href]="partnerInfo.ApimDeveloperPortalUrl"
                            target="_blank"
                            >SkyKick Developer Portal
                          </a>
                        </div>
                      </div>
                      <ng-template #howToEnable>
                        <div id="EnableAccess">
                          <div>
                            You currently do not have access to the Developer
                            Portal. Access can be granted by your SkyKick
                            Portal Administrator via the Edit Member feature.
                          </div>
                          <br />
                          <div>
                            <div>Here is what you need to do:</div>
                            <br />
                            <ol>
                              <li>
                                <!-- TODO - Need to make this URL link back to the old portal -->
                                <a
                                  href="/settings/users/members"
                                  target="_blank"
                                  >Login to SkyKick</a
                                >
                                with an Administrator account
                              </li>
                              <li>
                                On the left-hand navigation, select Settings >
                                Users > Members
                              </li>
                              <li>
                                Find your member in the list and in the action menu click Edit Permissions
                              </li>
                              <li>
                                Check the Developer Portal checkbox
                              </li>
                              <li>Click Update Member</li>
                              <li>You will receive an email with instructions</li>
                            </ol>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
