import {
  Pipe,
  PipeTransform,
} from '@angular/core';

@Pipe({
  name: 'applyPure',
  pure: true,
})
export class ApplyPurePipe implements PipeTransform {
  public transform(templateValue: any, fnReference: Function, ...fnArguments: any[]): any {
    // join the inputs from both sides of the function ref
    fnArguments.unshift(templateValue);

    return fnReference.apply(null, fnArguments);
  }
}
