import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { SkyKickPanelTabViewBase } from '@skykick/core';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { SKYKICKPRODUCTS } from '../core/constants/skykick-products.const';
import { WIZARDNAVS } from '../core/constants/wizard-navs.const';
import { ConnectwiseResourcesService } from '../core/services/connectwise-resources.service';
import { InfoPanelContext } from './models/info-panel-context';
import InfoViewModel from './models/info-view-model';

@Component({
    /* tslint:disable-next-line:component-selector */
    selector: '[sk-info-panel]',
    templateUrl: './info-panel.component.html',
    styleUrls: ['./info-panel.component.scss']
})

export class InfoPanelComponent extends SkyKickPanelTabViewBase<InfoPanelContext, void> implements OnInit, OnDestroy, OnChanges {

    info: InfoViewModel = {
        connectWiseUrl: '',
        connectWiseCompany: '',
        productMapping: [],
        companyMapping: null,
        isTicketingConfigured: false,
        connectionWizardHandler: () => {}
    };
    WIZARDNAVS = WIZARDNAVS;
    loading = false;

    private onDestroy$ = new Subject<void>();

    constructor(
        private connectwiseResourcesService: ConnectwiseResourcesService
    ) {
        super();
    }

    ngOnInit(): void {
        this.loading = true;
        forkJoin([
            this.connectwiseResourcesService.getCredentials().pipe(
                catchError(() => of(undefined))
            ),
            this.connectwiseResourcesService.getProductMappings().pipe(
                catchError(() => of([]))
            ),
            this.connectwiseResourcesService.getMissingProducts().pipe(
                catchError(() => of([]))
            ),
            this.connectwiseResourcesService.getTicketMapping().pipe(
                catchError(() => of(undefined))
            ),
            this.connectwiseResourcesService.getCompanyMappings({ searchTerm: '', resultsPerPage: 10000, pageNumber: 1 }).pipe(
                catchError(() => of(undefined))
            )
        ]).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(([credentials, productMappings, missingProducts, ticketMapping, companyMappings]) => {
            this.info = {
                connectWiseUrl: credentials?.connectWiseURL,
                connectWiseCompany: credentials?.companyName,
                productMapping: this.getProductMappings(productMappings, missingProducts),
                companyMapping: this.getCompanyMappings(companyMappings),
                isTicketingConfigured: !!ticketMapping,
                connectionWizardHandler: this.data.connectionWizardHandler
            }
            this.loading = false;
        });
    }

    getProductMappings(productMappings: any[], missingProducts: any[]){
        return SKYKICKPRODUCTS.map(product => { 
            return {
                skyKickProductName: product.name, 
                isMapped: productMappings?.some(mapping => mapping.source === product.source),
                mappingError: missingProducts.includes(product.name)
            }; });
    }

    getCompanyMappings(companyMappings: any){
        return {
            mappedCustomers: companyMappings.entities.filter(x => x.agreementId && x.companyId).length, 
            hasAgreementErrors: companyMappings.entities.some(x => x.hasAgreementError) || companyMappings.entities.some(x => x.isEnabled === false)
        };
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    ngOnChanges(): void {
    }

    close() {
        this.operation.complete();
    }
}
