/**
 * @summary All possible connection statuses for a M365 connection
 * From namespace SkyKick.Platform.AccessManagement.Contract;
 */
export enum M365ConnectionStatus {
    NoConnection = 0,
    Healthy = 1,
    Unhealthy = 2,
    ReauthenticationRequired = 3
}
