import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    AppId,
    RouteResolverService,
} from '@skykick/core';

import { environment } from 'src/environments/environment';
import { Utils } from '../../../services/utils';
import { PartnerPortalUserClaims } from '../models/partner-portal-user-claims';

@Injectable({
    providedIn: 'root'
})
export class ClaimsService {
    constructor(
        private http: HttpClient,
        private routeResolverService: RouteResolverService) { }

    getPartnerPortalUserClaimsAsync(userName: string): Observable<PartnerPortalUserClaims> {
        return this.http.get<PartnerPortalUserClaims>(
                `${this.routeResolverService.generatePipeRoute(AppId.PlatformApis)}${environment.apis.authenticationApimSuffix}/api/claims?userName=${encodeURIComponent(userName)}`)
            .pipe(
                catchError(error => Utils.handleError(error, ClaimsService.name))
            );
    }
}
