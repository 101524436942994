export enum M365ModalStatus {
    Failure = 'failure',
    Success = 'success',
    Warning = 'warning',
    Redirect = 'redirect',
}

export interface M365ModalResult {
    status: M365ModalStatus;
    adGroupSyncEnabled: boolean;
    url: string;
    rollbacked: boolean;
}
