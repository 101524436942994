import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsMonitorService } from './app-insights-monitor.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private appInsightsMonitor: AppInsightsMonitorService) {
        super();
    }

    handleError(error: Error) {
        this.appInsightsMonitor.logException(error);
    }
}
