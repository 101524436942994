import { Injectable } from '@angular/core';

import { FilterArea } from 'src/app/settings/models/filter-area';
import {
  FilterAreaWithGroups,
} from 'src/app/settings/models/filter-area-with-groups';
import { FilterItem } from 'src/app/settings/models/filter-item';
import {
  FilterItemWithGroup,
} from 'src/app/settings/models/filter-item-with-group';
import {
  PermissionFilterItemGroup,
} from 'src/app/settings/models/permission-filter-item-group';

import { CustomersPermissionProvider } from './customers.permission.provider';
import { CustomerStatusProvider } from './customers.status.provider';

@Injectable({
  providedIn: 'root'
})
export class FiltersManagerService {
  private permissionsFilterKey = 'permissions';
  private statusFilterKey = 'status';

  getFilterAreas(): FilterArea[] {
    const permissionFilterArea: FilterAreaWithGroups<PermissionFilterItemGroup> = {
      displayName: 'settings.customers.table.permissions',
      key: this.permissionsFilterKey,
      selectedFiltersCount: 0,
      filters: [],
      groups: [] = [
        {
          displayName: 'settings.customers.table.filter.groups.cloudBackup',
          group: PermissionFilterItemGroup.CloudBackup
        }
      ],
    };
    permissionFilterArea.filters.push(...CustomersPermissionProvider.Permissions
      .map(access => new FilterItemWithGroup(access.displayNameLocKey, access.value, false, true, access.group)));

    const statusFilterArea: FilterArea = {
      displayName: 'settings.customers.table.status',
      key: this.statusFilterKey,
      selectedFiltersCount: 0,
      filters: [],
    };
    statusFilterArea.filters.push(
      ...CustomerStatusProvider.Statuses
        .map(status => new FilterItem(status.displayNameLocKey, status.value)));

    const filterAreas = [permissionFilterArea, statusFilterArea];
    return filterAreas;
  }

  handleFilterChecked(filterChecked: FilterItem, filterAreas: FilterArea[]): void {
    filterChecked.isSelected = !filterChecked.isSelected;

    this.updateSelectedFiltersCount(filterAreas);
  }

  uncheckAllFilters(filterAreas: FilterArea[]): void {
    filterAreas.forEach(area => {
        area.selectedFiltersCount = 0;
        area.filters.forEach(filter => {
          filter.isSelected = false;
        });
    });
  }

  getSelectedFiltersCount(filterAreas: FilterArea[]): number {
    return filterAreas
      .map(x => x.selectedFiltersCount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  private updateSelectedFiltersCount(filterAreas: FilterArea[]): void {
    filterAreas.forEach(area => {
      area.selectedFiltersCount = area.filters
        .filter(filter => filter.isSelected).length;
    });
  }

  getPermissions(filterAreas: FilterArea[]): string[] {
    return this.getFilterArrayByAreaKey(filterAreas, this.permissionsFilterKey);
  }

  getStatuses(filterAreas: FilterArea[]): string[] {
    return this.getFilterArrayByAreaKey(filterAreas, this.statusFilterKey);
  }

  private getFilterArrayByAreaKey(filterAreas: FilterArea[], filterAreaKey: string): string[] {
    const filterArea: FilterArea = filterAreas.filter(x => x.key === filterAreaKey)[0];

    return filterArea.filters
      .filter(filterItem => filterItem.isSelected)
      .map(filterItem => filterItem.value);
  }

  getUniqueFilterValue(filterAreas: FilterArea[]): string {
    return filterAreas.map(
        filterArea => {
          const filtersString =  filterArea.filters
            .filter(x => x.isSelected).map(x => x.value).join();
          return `${filterArea.key}[${filtersString}]`;
        }
      ).join();
  }
}
