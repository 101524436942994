import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { MembersRoleProvider } from '../components/members/services/members.role.provider';

// Can be combined with HasPermissionGuard into a single guard
export const AdminUserGuard: CanActivateFn = () => {
    const isCurrentUserAdmin =
        inject(AbstractUserProvider).getCurrentUser().hasPermission(MembersRoleProvider.PartnerPortalAdmin.key);

    if (!isCurrentUserAdmin) {
        inject(Router).navigate(['settings']);
    }
    return isCurrentUserAdmin;
};
