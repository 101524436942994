export class MembersRoleProvider {

    static readonly PartnerPortalAdmin = {
        key: 'PartnerPortalAdmin',
        displayNameLocKey: 'settings.members.table.filter.roles.admin'
    };

    static readonly PartnerPortal = {
        key: 'PartnerPortal',
        displayNameLocKey: 'settings.members.table.filter.roles.collaborator'
    };

    static readonly Roles = [
        MembersRoleProvider.PartnerPortalAdmin,
        MembersRoleProvider.PartnerPortal
    ];

    public static getRoleDisplayNameLocKeyByKey(key: string): string {
        return this.Roles.filter(x => x.key === key)[0]?.displayNameLocKey;
    }

    public static isAdmin(userRole: string): boolean {
        return userRole == this.PartnerPortalAdmin.key;
    }
}
