import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'sk-error-banner',
  templateUrl: './error-banner.component.html',
  styleUrls: ['./error-banner.component.scss']
})
export class ErrorBannerComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  description: string;

  @Input()
  contactLink: string;

  constructor() { }

  ngOnInit(): void {
  }

}
