import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SkyKickModalService } from '@skykick/core';

import { M365ConnectionStatus } from '../../../models/connection-status';
import { PartnerAuthentication } from '../../../models/partner-authentication';
import {
    AuthenticationMethodResourcesService
} from '../../../services/authentication-method-resources.service';
import { M365AuthenticationComponent } from './m365-authentication.component';
import { M365ModalResult, M365ModalStatus } from './m365-modal-result';
import { defaultM365Handlers, M365ModalSettings } from './m365-modal-settings';
import { M365StepName } from './m365-setup-steps';

@Injectable({
    providedIn: 'root',
})
export class M365ModalService {
    private defaultOverlayConfig: NgbModalOptions = {
        backdrop: 'static',
        windowClass: 'modal-panel modal-right',
        size: 'lg',
    };

    constructor(
        private modal: NgbModal,
        private toastr: ToastrService,
        private translate: TranslateService,
        private authenticationMethodResourcesService: AuthenticationMethodResourcesService,
        private skyKickModalService: SkyKickModalService
    ) {}

    open(
        partnerAuthentication: PartnerAuthentication,
        groupsSyncEnabled: boolean,
        m365AuthConnectionStatus: M365ConnectionStatus,
        displayUserMatchStepOnly = false,
        initialStepName: M365StepName = M365StepName.GrantAccess,
        overlayConfig = this.defaultOverlayConfig
    ): NgbModalRef {
        const authModalRef = this.modal.open(M365AuthenticationComponent, overlayConfig);

        authModalRef.componentInstance.partnerAuthentication = partnerAuthentication;
        authModalRef.componentInstance.groupsSyncEnabled = groupsSyncEnabled;
        authModalRef.componentInstance.m365AuthConnectionStatus = m365AuthConnectionStatus;
        authModalRef.componentInstance.initialStepName = initialStepName;
        authModalRef.componentInstance.displayUserMatchStepOnly = displayUserMatchStepOnly;

        return authModalRef;
    }

    openAndHandleResult(settings: M365ModalSettings): Promise<any> {
        const wizardRef = this.open(settings.partnerAuthentication, settings.groupsSyncEnabled, settings.m365AuthConnectionStatus, settings.isM365MatchingProcess);

        settings.handlers ??= defaultM365Handlers;

        return wizardRef.result
            .then(({ status, adGroupSyncEnabled, url, rollbacked = false }: M365ModalResult) => {
                switch (status) {
                    case M365ModalStatus.Success:
                        settings.handlers.onSuccess(adGroupSyncEnabled);
                        if (!adGroupSyncEnabled || settings.isM365MatchingProcess)
                            this.toastr.success(this.translate.instant('settings.O365.ENABLING_SUCCESS'));
                        break;
                    case M365ModalStatus.Failure:
                        settings.handlers.onFailure(rollbacked);
                        this.toastr.error(this.translate.instant('settings.O365.ENABLING_ERROR'));
                        break;
                    case M365ModalStatus.Warning:
                        settings.handlers.onWarning();
                        this.toastr.warning(this.translate.instant('settings.O365.ENABLING_WITH_WARNING'));
                        break;
                    case M365ModalStatus.Redirect:
                        settings.handlers.onRedirect();
                        window.location.href = url;
                        break;
                }

                const m365AuthEnabled = status === M365ModalStatus.Success || status === M365ModalStatus.Warning;

                return { m365AuthEnabled, adGroupSyncEnabled, status };
            })
            .then(({ m365AuthEnabled, adGroupSyncEnabled, status }) => {
                if (m365AuthEnabled && adGroupSyncEnabled && !settings.isM365MatchingProcess) {
                    this.authenticationMethodResourcesService
                        .beginM365GroupSync()
                        .pipe(first())
                        .subscribe({
                            next: () => {
                                this.toastr.success(this.translate.instant('settings.O365.ENABLING_SUCCESS'));
                                settings.handlers.onGroupSyncEnabling();
                            },
                            error: () => {
                                this.showGroupSyncErrorModal();
                                window.location.reload();
                            },
                        });
                }

                return { m365AuthEnabled, adGroupSyncEnabled, status };
            })
            .catch((err) => {
                if (err) {
                    console.error('Something happened during m365 authentication setup:', err);
                    throw err;
                }
            });
    }

    private showGroupSyncErrorModal() {
        this.skyKickModalService.error({
            title: this.translate.instant('ERROR'),
            body: this.translate.instant('settings.O365.ENABLE_GROUP_SYNC_ERROR'),
            btnLabel: this.translate.instant('COMMON.CLOSE'),
        });
    }
}
