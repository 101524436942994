import { CustomerAccess } from '../models';

export class CustomersAccessProvider {
  static readonly AccessRights = [
    {
      key: CustomerAccess.Backup,
      displayNameLocKey: 'settings.customers.table.filter.access.backup',
      value: 'Backup',
    },
    {
      key: CustomerAccess.Migration,
      displayNameLocKey: 'settings.customers.table.filter.access.migration',
      value: 'Migration',
    },
  ];

  static getAccessValueByKey(key: number): string {
    return this.AccessRights.find((x) => x.key === key).value;
  }

  static getAccessKeyByValue(value: string): number {
    return this.AccessRights.find((x) => x.value === value).key;
  }

  static getAccessDisplayNameLocKeyByValue(value: string): string {
    return this.AccessRights.find((x) => x.value === value).displayNameLocKey;
  }
}
