import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppId, RouteResolverService, SkyKickPanelTabViewBase } from '@skykick/core';


import { catchError, EMPTY, Subject, takeUntil, tap } from 'rxjs';
import { CustomersAccessProvider } from '../services/customers.access.provider';
import { WhiteLabelDataService } from '../services/whitelabel/whitelabel-data.service';
import { CustomerDetailsPanelContext } from './models/customer-details-panel-context';
import CustomerDetailsViewModel from './models/customer-details-view-model';

@Component({
  /* tslint:disable-next-line:component-selector */
  selector: '[sk-customer-details-panel]',
  templateUrl: './customer-details-panel.component.html',
  styleUrls: ['./customer-details-panel.component.scss']
})
export class CustomerDetailsPanelComponent extends SkyKickPanelTabViewBase<CustomerDetailsPanelContext, void> implements OnInit {

  private onDestroy$ = new Subject<void>();
  isLoading: boolean = true;
  customer: CustomerDetailsViewModel;
  isM365Customer: boolean;

  constructor(
    private router: Router,
    private routeResolverService: RouteResolverService,
    private whitelabelDataService: WhiteLabelDataService) {
    super();
  }

  ngOnInit(): void {
    this.customer = this.data.viewModel;
    this.whitelabelDataService.getWhiteLabelSettings().pipe(
      takeUntil(this.onDestroy$),
      tap(res => {
          this.isLoading = false;
          this.isM365Customer = res.isEnabled;
      }),
      catchError(() => {
        this.isLoading = false;
        this.isM365Customer = false;
        return EMPTY;
      })
  ).subscribe();
  }

  getAuthTypeLocKey(): string {
    if (this.isM365Customer){
      return 'settings.common.authentication.m365';
    }
    return 'settings.common.authentication.skykick';
  }

  getSyndicationUrl(): string {
    return this.routeResolverService.generateRatRoute(AppId.Syndication, '/brandedservices/backup');
  }

  getAccessLocKey(customerAccessKey: string): string {
    return CustomersAccessProvider.getAccessDisplayNameLocKeyByValue(customerAccessKey);
  }

  showFooter(): boolean {
    return this.customer.status === 'Active';
  }

  editPermissions(): void {
    this.router.navigate(['settings', 'users', 'customers', this.customer.id, 'edit']);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

}
