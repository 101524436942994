import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NgbActiveModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { iif, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DropdownItem } from '@skykick/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';

import { AuthenticationType } from 'src/app/settings/account/models/authentication-type';
import { M365LoginMapUser } from 'src/app/settings/account/models/partner-authentication-settings';
import { AuthenticationMethodResourcesService } from 'src/app/settings/account/services/authentication-method-resources.service';
import { BaseComponent } from 'src/app/settings/shared/components/component-base/base.component';
import { ApiErrorCode } from 'src/app/shared/models/api-error-code';
import { ErrorModel } from 'src/app/shared/models/error.model';

import { HttpErrorResponse } from '@angular/common/http';
import { M365ModalStatus } from '../../m365-modal-result';
import { MatchUserFormFieldModel } from './match-user-form-field.model';

@Component({
    selector: 'sk-match-users-step',
    templateUrl: './match-users-step.component.html',
    styleUrls: ['./match-users-step.component.scss'],
})
export class MatchUsersStepComponent extends BaseComponent implements OnInit {
    @Input() groupSyncEnabled: boolean;
    // Should be 'true' by default, because if we do not pass this field,
    // it means that the user opened this Step from 'Match User' banner and before that he has already confirmed that he uses m365 mfa.
    @Input() mfaCheckValue: boolean = true;
    @Input() aadUrl: string;
    @Input() isMFAWasEnabledOnSkyKickAuth: boolean;
    @Input() isBackButtonVisible = true;

    @Output() goBack = new EventEmitter();

    isLoading = true;
    isUserSearchErrored = false;
    usersForm: UntypedFormGroup;

    users$: Observable<M365LoginMapUser[]>;
    cachedUsers$: Observable<M365LoginMapUser[]>;

    filterOptions: DropdownItem[] = [
        { key: this.translateService.instant('settings.O365.USERS_MATCHING.ALL'), value: true },
        { key: this.translateService.instant('settings.O365.USERS_MATCHING.UNMATCHED'), value: false },
    ];
    activeFilter = this.filterOptions[0];

    constructor(
        public activeModal: NgbActiveModal,
        private translateService: TranslateService,
        private authenticationMethodResourcesService: AuthenticationMethodResourcesService,
        private formBuilder: UntypedFormBuilder,
        private currentUserService: AbstractUserProvider
    ) {
        super();
    }

    ngOnInit(): void {
        this.users$ = iif(() => !this.activeFilter.value, this.cachedUsers$, this.fetchFilteredUsers());
    }

    handleChangeFilter(selectedValue: boolean) {
        this.activeFilter = this.filterOptions.find((o) => o.value === selectedValue);

        this.users$ = this.cachedUsers$.pipe(
            map((users) => {
                if (!this.activeFilter.value) return users.filter((u) => u.o365LoginName === '' || u.o365LoginName == null);

                return users;
            })
        );
    }

    stepBack() {
        this.goBack.emit();
    }

    saveUserMatching() {
        this.isLoading = true;

        const payload = {
            AuthenticationType: AuthenticationType.M365Auth,
            IsMFAEnabled: false,
            UsersToMap: this.formValuesToUsersMatchingPayload(),
            ConfirmProperMFAUse: this.mfaCheckValue,
        };

        this.authenticationMethodResourcesService.savePartnerAuthenticationSettings(payload).subscribe({
            next: (response) => {
                if (response) {
                    this.activeModal.close({
                        status: M365ModalStatus.Warning,
                    });
                } else {
                    this.removeHash();
                    this.activeModal.close({
                        status: M365ModalStatus.Success,
                        adGroupSyncEnabled: this.groupSyncEnabled,
                    });
                }
                this.isLoading = false;
            },
            error: (response: HttpErrorResponse) => {
                const error = response.error as ErrorModel;
                // rollback auth type to SkyKick if error happened during current admin user mapping.
                if (error && error.code === ApiErrorCode.M365CurrentAdminMappingFailure) {
                    const rollbackPayload = {
                        AuthenticationType: AuthenticationType.SkyKickAuth,
                        IsMFAEnabled: this.isMFAWasEnabledOnSkyKickAuth,
                    };
                    this.authenticationMethodResourcesService
                        .savePartnerAuthenticationSettings(rollbackPayload)
                        .pipe(
                            takeUntil(this.destroy$),
                            tap(() => {
                                this.activeModal.close({
                                    status: M365ModalStatus.Failure,
                                    rollbacked: true,
                                });
                            })
                        )
                        .subscribe();
                } else {
                    this.activeModal.close({
                        status: M365ModalStatus.Failure,
                    });
                }
            },
        });
    }

    handleBlur(event: FocusEvent, formControlName: string) {
        const inputElement = event.target as HTMLInputElement;
        const currentValue = inputElement.value;

        if (currentValue == null || currentValue === '') return;

        const formField = this.usersForm.controls[formControlName].get('o365LoginName');

        if (this.isUserSearchErrored) {
            this.isUserSearchErrored = false;
            formField.setValue('');
            return;
        }

        this.authenticationMethodResourcesService.searchO365LoginUserNames(currentValue).pipe(takeUntil(this.destroy$)).subscribe({
            next: users => {
                if (users == null) {
                    formField.setValue('');
                    return;
                }

                if (!users.some(u => u?.userPrincipalName?.toLowerCase() == currentValue?.toLowerCase())){
                    formField.setValue('');
                }
            },
            error: () => {
                formField.setValue('');
            }
        });
    }

    typeaheadOnSelect(evt: NgbTypeaheadSelectItemEvent) {
        if (evt.item == 'No Results Found') {
            evt.preventDefault();
        }
    }

    searchType: (formControlName: string) => OperatorFunction<string, readonly string[]> =
        (formControlName: string) => (text$: Observable<string>) => {
            return text$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                filter((res) => res?.length > 2 || res?.length === 0),
                tap(() => this.usersForm.controls[formControlName].get('isLoading').setValue(true)),
                switchMap((term) =>
                    this.authenticationMethodResourcesService.searchO365LoginUserNames(term).pipe(
                        map((users) => users.map((user) => user.userPrincipalName)),
                        catchError((err) => {
                            this.isUserSearchErrored = true;
                            return of(['No Results found']);
                        }),
                        finalize(() => this.usersForm.controls[formControlName].get('isLoading').setValue(false))
                    )
                ),
                finalize(() => this.usersForm.controls[formControlName].get('isLoading').setValue(false))
            );
        };

    shouldBannerBeVisible = (users: M365LoginMapUser[]) => users.some((u) => u.o365LoginName == null || u.o365LoginName === '');

    getUserFormGroupName = (skUserName: string) => `user-${skUserName}`;

    isM365FieldDisabled = (fieldName: string) => {
        return this.usersForm?.controls[fieldName]?.get('isUserAlreadyMatched').value === true;
    };

    private fetchFilteredUsers(): Observable<M365LoginMapUser[]> {
        return this.fetchUsers().pipe(
            map((users) => {
                if (!this.activeFilter.value) return users.filter((u) => u.o365LoginName === '' || u.o365LoginName == null);

                return users;
            })
        );
    }

    private fetchUsers(): Observable<M365LoginMapUser[]> {
        if (this.cachedUsers$) return this.cachedUsers$;

        return this.authenticationMethodResourcesService.getO365SetupLoginInfo(true).pipe(
            map((res) => {
                const users = res?.users?.partnerUsers ?? [];

                this.usersForm = this.createUsersForm(users);
                this.cachedUsers$ = of(users); // Cache response inside this component 😎 - So, do not make too many requests to API.

                return users;
            }),
            catchError(() => of([])),
            finalize(() => (this.isLoading = false))
        );
    }

    private createUsersForm(users: M365LoginMapUser[]): UntypedFormGroup {
        const formGroup = this.formBuilder.group({});

        users.forEach((user) => {
            const isCurrentUserEmail = this.currentUserService.getCurrentUser().email === user.skUserName;

            formGroup.addControl(this.getUserFormGroupName(user.skUserName), this.createUserFormGroup(user, isCurrentUserEmail));
        });

        return formGroup;
    }

    private createUserFormGroup(user: M365LoginMapUser, isCurrentUserEmail = false): UntypedFormGroup {
        const isUserAlreadyMatched = user.o365LoginName != null && user.o365LoginName !== '';

        // Add required validator for field if this is current user email.
        const o365LoginNameValidators = isCurrentUserEmail && [Validators.required];

        const group = this.formBuilder.group({
            skUserName: [user.skUserName],
            o365LoginName: [user.o365LoginName, o365LoginNameValidators],
            contactId: [user.contactId],
            isLoading: false,
            // these fields are needed when the user has already been matched and in CRM Contact cv_websiteusername === user.o365Username
            isUserAlreadyMatched,
        });

        if (isUserAlreadyMatched) group.get('o365LoginName').disable();

        return group;
    }

    private removeHash(): void {
        history.pushState('', document.title, window.location.pathname + window.location.search);
    }

    private formValuesToUsersMatchingPayload(): M365LoginMapUser[] {
        if (this.usersForm?.value == null) {
            return null;
        }

        return Object.values(this.usersForm.value)
            .map(({ skUserName, o365LoginName, contactId, isUserAlreadyMatched }: MatchUserFormFieldModel) => ({
                contactId: contactId,
                skUserName: skUserName,
                o365LoginName: o365LoginName?.toLocaleLowerCase() ?? '',
                isAlreadyMapped: isUserAlreadyMatched,
            }));
    }
}
