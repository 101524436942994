<sk-modal id="sk-terms-conditions-modal">
  <sk-modal-header class="modal-header hide-close">
    <h4 class="modal-title" [innerHTML]="'COMMON.TERMS_AND_CONDITIONS.UPDATED_TITLE' | translate"></h4>
  </sk-modal-header>

  <sk-modal-body >
    <p [innerHTML]="'COMMON.TERMS_AND_CONDITIONS.UPDATED_MESSAGE' | translate"></p>
  </sk-modal-body>

  <sk-modal-footer>
    <button id="sk-accept-terms-and-conditions" color="primary" (click)="accept()" sk-button>
      {{ 'COMMON.TERMS_AND_CONDITIONS.CONTINUE' | translate }}
    </button>
  </sk-modal-footer>
</sk-modal>
