<form [formGroup]="form" novalidate class="mt-150">
    <fieldset class="mb-100" [class.fieldset-is-invalid]="accountSelectHasMappingError()">
        <label class="font-medium">ConnectWise Customer Name</label>
        <div class="sk-input-group hidden-input">
            <select id="connectWiseAccountName" class="form-control form-select"
                formControlName="connectWiseAccountSelect">
                <option id="connectWiseAccountOption" [ngValue]="null" [disabled]="true">Select Customer</option>
            </select>
            <div *ngIf="isLoadingCWAccounts" class="sk-spin-loader loader-sm"></div>
        </div>
        <div ngbDropdown class="btn-group width-fill">
            <button ngbDropdownToggle [class.active]="isLoadingCWAccounts" type="button" id="accountSelectBtn" class="btn btn-outline-secondary dropdown-toggle sk-has-spinner sk-abs-spinner" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="sk-btn-spinner">
                    <div class="sk-spin-loader loader-dark"></div>
                </div>
                <span class="sk-loader-label">{{ getSelectedAccount().companyName ?? 'Select Customer' }}</span>
            </button>
            <div ngbDropdownMenu [style.visibility]="isLoadingCWAccounts ? 'hidden' : 'visible'" class="dropdown-menu sk-menu-input --sk-form-menu width-fill">
                <div class="sk-input" style="max-width: none">
                    <input (keyup)="searchAccounts($event)" class="form-control" type="text" placeholder="Search">
                    <i *ngIf="searchingAccounts" class="sk-spin-loader loader-sm material-icons spin-component-custom"></i>
                </div>
                <div class="dropdown-divider"></div>
                <div id="skScrollContainer" class="sk-menu-scroll">
                    <a *ngFor="let account of connectWiseAccounts$ | async"
                        [ngClass]="{'active': account.companyName === getSelectedAccount().companyName}"
                        (click)="selectAccount(account)" ngbDropdownItem class="dropdown-item">
                        {{ account.companyName }}
                    </a>
                </div>
            </div>
        </div>
        <div *ngIf="accountSelectHasMappingError()">
          <div class="mb-100 is-invalid">
            <small class="invalid-feedback">ConnectWise Account Name has already mapped to another order.</small>
          </div>
        </div>
    </fieldset>
    <fieldset class="mb-100">
        <label class="font-medium">ConnectWise Agreement
            <i class="material-icons md-16 icon-muted align-middle" 
                ngbPopover="Map which Agreement within ConnectWise you would like SkyKick to push billing data into.">help_outline</i>
        </label>
        <div class="sk-input-group">
            <select id="agreementName" class="form-control form-select"
                [class.is-invalid]="agreementMessage"
                formControlName="agreementSelect">
                <option id="agreementOption" [ngValue]="null" [disabled]="true">Select Agreement Name</option>
                <option *ngFor="let item of agreements$ | async" [ngValue]="item">{{item.agreementName}}</option>
            </select>
            <div *ngIf="isLoadingAgreements" class="sk-spin-loader loader-sm"></div>
        </div>
        <small *ngIf="agreementMessage" class="invalid-feedback">{{agreementMessage}}</small>
    </fieldset>
    <fieldset class="mb-100 mt-200">
        <label class="font-medium">Include User Email address on the invoice?
            <i class="material-icons md-16 icon-muted align-middle" 
                ngbPopover="Usernames for all users consuming a license mapped to the 'Backup Exchange' product will be provided within the 'Product Invoice Description' field. This field has a character limit that may prohibit all users from being listed.">help_outline</i>
        </label>
        <div class="form-check">
            <input type="checkbox" id="includeUserNamesOnInvoice" class="form-check-input" formControlName="includeUserNamesOnInvoice" />
            <label for="includeUserNamesOnInvoice" class="form-check-label">Yes</label>
        </div>
    </fieldset>
    <fieldset *ngIf="customer.isEnabled" class="mb-100">
        <button type="button" class="btn btn-link ps-0" (click)="removeMapping()">Remove Mapping</button>
    </fieldset>
    <div *ngIf="customer.missingProducts">
        <hr>
        <div class="d-flex align-items-center">
            <h4>Product Usage</h4>
            <i class="material-icons md-18 ml-50 icon-muted" ngbPopover="Cloud Backup Product(s) must be mapped to ensure data synchronization." triggers="mouseenter:mouseleave">help_outline</i>
        </div>
        <div class="font-medium m-0 p-0 mt-150 align-content-center">Cloud Backup Product</div>
        <div *ngFor="let productName of getMissingProducts();" class="d-flex">
            <i class="material-icons md-18 icon-danger mr-50">warning</i>
            <p class="mb-0">{{productName}}</p>
        </div>
        <p *ngIf="customer.missingProducts === 25" class="text-danger mt-100">Products Not Mapped</p>
        <p *ngIf="customer.missingProducts !== 25" class="text-danger mt-100">Product Not Mapped</p>
    </div>
</form>
