import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'sk-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss']
})
export class ActionsMenuComponent implements OnInit {
  constructor() { }

  @Input()
  showOutline: boolean;

  ngOnInit(): void {
  }
}
