import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import {
    combineLatest,
    merge,
    Observable,
    of,
    OperatorFunction,
    Subject,
    throwError,
} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';

import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
    AbstractUserProvider,
} from '@skykick/platform-identity-auth-auth0-angular';

import { ClaimsService } from '../../../services/claims.service';
import {
    CustomerPermission,
    CustomerValidationStatus,
} from '../models';
import Contact from '../models/contact/contact';
import CustomerUserRequest from '../models/customer-user-request';
import { CustomersSearchFilter } from '../models/customers-search-filter';
import Order from '../models/order/order';
import { ProductTag } from '../models/order/product-tag';
import {
    ContactsService,
    CustomersPermissionProvider,
    CustomersService,
    CustomersValidator,
    CustomerValidationStatusProvider,
} from '../services';
import { OrderService } from '../services/orders';

@Component({
    templateUrl: './add-customer.component.html',
    styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit, OnDestroy {
    readonly DEFAULT_PAGE_NUMBER = 1;
    readonly PAGE_SIZE = 10;
    readonly CONTACTS_PAGE_SIZE = 100;

    @ViewChild('instance', { static: true }) instance: NgbTypeahead;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    loading = false;
    loadingOrder = false;
    loadingCustomer = false;
    orderQueryIsEmpty = true;
    customerQueryIsEmpty = true;
    executingMainAction = false;

    orders: Order[] = [];
    contacts: Contact[] = [];
    isSelfServicePageEnabled: boolean;

    addCustomerForm: UntypedFormGroup;
    isFormSubmitted = false;

    isBackendValidationFailed = false;
    backendValidationMessage: string;

    public CustomerPermission = CustomerPermission;
    public ProductTag = ProductTag;

    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private customersService: CustomersService,
        private orderService: OrderService,
        private contactsService: ContactsService,
        private claimsService: ClaimsService,
        private abstractUserProvider: AbstractUserProvider,
        private router: Router,
        private toastrService: ToastrService,
        private formBuilder: UntypedFormBuilder,
        private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.loading = true;

        this.addCustomerForm = this.formBuilder.group({
            order: new UntypedFormControl('', [(control) => CustomersValidator.isOrderValid(control, this.orders)]),
            contact: new UntypedFormControl({ value: '', disabled: true }, [(control) => CustomersValidator.isContactValid(control, this.contacts)]),
            permission: new UntypedFormControl('', [CustomersValidator.isPermissionValid])
        });

        this.addCustomerForm.controls.order.valueChanges.subscribe(() => {
            const contact = this.addCustomerForm.controls.contact;
            this.addCustomerForm.controls.order.valid ? contact.enable() : contact.disable();
            contact.setValue(null);
            const permission = this.addCustomerForm.controls.permission;
            permission.setValue(null);
        });

        this.addCustomerForm.controls.contact.valueChanges.subscribe(() => {
            this.isBackendValidationFailed = false;
            this.backendValidationMessage = null;
        });

        this.claimsService.getPartnerPortalUserClaimsAsync(this.abstractUserProvider.getCurrentUser().email).pipe(
            takeUntil(this.destroy$),
            catchError(error => {
                this.loading = false;
                return throwError(() => error);
            })
        ).subscribe(claims => {
            this.isSelfServicePageEnabled = claims !== undefined && claims.isSelfServicePageEnabled;
            this.loading = false;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    cancel = (): Promise<boolean> => this.navigateToCustomers();

    submitForm = (): void => {
        this.isFormSubmitted = true;

        this.addCustomerForm.invalid ?
            this.markInvalidFormFields() :
            this.sendInvite();
    }

    sendInvite(): void {
        this.executingMainAction = true;
        const customerUserRequest = this.createCustomerUserRequest();
        this.customersService.validateCustomer(customerUserRequest).pipe(
            tap(validationStatus => {
                if (validationStatus === CustomerValidationStatus.Valid) {
                    this.customersService.addCustomer(customerUserRequest).pipe(
                        tap(result => {
                            if (result) {
                                this.translateService.get('settings.customers.actions.send-invite-success').pipe(
                                    tap((successText: string) => this.toastrService.success(successText))
                                ).subscribe();
                                this.navigateToCustomers(result.ContactId);
                            }
                            else {
                                throw new Error('Unsuccessful response when adding customer');
                            }
                        }),
                        catchError(this.handleError)
                    ).subscribe(() => this.executingMainAction = false);
                } else {
                    this.executingMainAction = false;
                    this.isBackendValidationFailed = true;
                    this.backendValidationMessage = CustomerValidationStatusProvider.getStatusDisplayMessageByKey(validationStatus);
                }
            }),
            catchError(this.handleError)
        ).subscribe();
    }

    searchOrder: OperatorFunction<string, readonly Order[]> = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(term => {
          this.orderQueryIsEmpty = term === '';
          this.loadingOrder = !this.orderQueryIsEmpty;
        }),
        switchMap(term => {
          if (this.orderQueryIsEmpty) {
              return of([]);
          }

          return this.orderService.getOrders({
              searchTerm: term,
              pageNumber: this.DEFAULT_PAGE_NUMBER,
              resultsPerPage: this.PAGE_SIZE,
              includeCanceledOrders: true,
              includeSavedOrders: false
            }).pipe(
              catchError(() => {
                this.loadingOrder = false;
                return of([]);
              }),
              tap(orders => {
                this.orders = [...orders];
                this.loadingOrder = false;
              }));
        }),
        takeUntil(this.destroy$),
        finalize(() => {
            this.loadingOrder = false;
        })
      );
    }

    searchCustomerName: OperatorFunction<string, readonly Contact[]> = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(300), distinctUntilChanged());
      const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));

      return merge(debouncedText$, clicksWithClosedPopup$).pipe(
          tap(term => {
              this.customerQueryIsEmpty = term === '';
              this.loadingCustomer = !this.customerQueryIsEmpty;
          }),
          switchMap(term => {
                if (this.customerQueryIsEmpty) {
                    return of([]);
                }

                return combineLatest([
                    this.contactsService.getContacts({
                        searchTerm: term,
                        orderId: this.addCustomerForm.controls.order.value.id,
                        maxResults: this.CONTACTS_PAGE_SIZE },
                        this.addCustomerForm.controls.order.value.productTag),
                    this.customersService.getCustomers(new CustomersSearchFilter({
                        searchTerm: term,
                        pageNumber: 1,
                        pageSize: this.CONTACTS_PAGE_SIZE,
                        accesses: [],
                        orderBy: 'Name,EmailAddress',
                        orderName: this.addCustomerForm.controls.order.value.name }))
                        .pipe(catchError(() => of([])))
                ]).pipe(
                    takeUntil(this.destroy$),
                    map(([contacts, customers]) => {
                        const customerEmails = customers.map(x => x.email.toLowerCase());
                        const filteredContacts = contacts.filter(x => !customerEmails.includes(x.emailAddress.toLowerCase()))
                        return filteredContacts.length > this.PAGE_SIZE ? filteredContacts.slice(0, this.PAGE_SIZE) : filteredContacts;
                    }),
                    tap(contacts => {
                        this.contacts = [...contacts];
                        this.loadingCustomer = false;
                    }),
                    catchError(() => {
                        this.translateService.get('settings.customer.add.errors.no-customer-emails-from-order').pipe(
                            tap((errorText: string) => this.toastrService.error(errorText))
                        ).subscribe();
                        this.loadingCustomer = false;
                        return of([]);
                    })
                );
            }
          ),
          takeUntil(this.destroy$),
          finalize(() => this.loadingCustomer = false)
      );
    }

    orderFormatter = (order: Order): string => order.name;

    contactFormatter = (contact: Contact): string => contact.emailAddress;

    isPermissionSelected(permission: CustomerPermission): boolean {
        return this.addCustomerForm.controls.permission.value === permission;
    }

    isFieldInvalid(field: string): boolean {
        return this.addCustomerForm.get(field).invalid && this.isFormSubmitted;
    }

    markInvalidFormFields(): void {
        this.addCustomerForm.markAllAsTouched();
        Object.keys(this.addCustomerForm.controls).forEach(field => {
            const control = this.addCustomerForm.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            }
        });
    }

    isOrderSelected = (): boolean => this.addCustomerForm.controls.order.valid;

    isBackupOrder = (): boolean => this.addCustomerForm.controls.order.value &&
        this.addCustomerForm.controls.order.value.productTag === ProductTag.Backup;

    // In story #298576 we removed support of Migration customer users per product management request.
    // In later PRs UI can be adjusted to remove references to Migration orders.
    isMigrationOrder = (): boolean => false;

    private navigateToCustomers = (id: string = undefined): Promise<boolean> =>
        this.router.navigateByUrl('settings/users/customers', { state: { addCustomerId: id } });

    private createCustomerUserRequest = (): CustomerUserRequest => {
        const contact = this.addCustomerForm.controls.contact.value;
        const [firstName, lastName] = contact.fullName && contact.fullName.includes(' ') ? contact.fullName.split(' ') : ['', ''];

        return {
            orderId: this.addCustomerForm.controls.order.value.salesOrderId,
            contactId: contact.id,
            emailAddress: contact.emailAddress,
            firstName,
            lastName,
            isReadOnly: this.addCustomerForm.controls.permission.value === CustomerPermission.ReadOnly,
            isBackupCustomer: this.addCustomerForm.controls.order.value.productTag === ProductTag.Backup,
            backupRole: CustomersPermissionProvider.getPermissionValueByKey(this.addCustomerForm.controls.permission.value)
        };
    }

    private handleError = error => {
        this.loading = false;
        this.translateService.get('settings.common.error.something-wrong').pipe(
            tap((errorText: string) => this.toastrService.error(errorText))
        ).subscribe();

        return throwError(() => error);
    }

    onOrderSearchClear(){
      this.addCustomerForm.controls.order.setValue('');
      this.orderQueryIsEmpty = true;
      const contact = this.addCustomerForm.controls.contact;
      contact.disable();
    }

    onCustomerSearchClear(){
      this.addCustomerForm.controls.contact.setValue('');
      this.customerQueryIsEmpty = true;
    }

    orderSelected($event: any) {
      this.orderQueryIsEmpty = false;
    }

    customerSelected($event: any) {
      this.customerQueryIsEmpty = false;
    }
}
