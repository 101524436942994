import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BannerModule, ButtonsModule, FeedbackModule, HeaderModule, LayoutModule, NavigationModule } from '@skykick/core';
import { AbstractUserProvider, AuthModule } from '@skykick/platform-identity-auth-auth0-angular';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { DeveloperModule } from '../developer/developer.module';
import { HomeModule } from '../home/home.module';
import { MpnCheckService } from '../mpn-registration/services/mpn-check.service';
import { PapiHttpService } from '../mpn-registration/services/papi-http.service';
import { SettingsModule } from '../settings/settings.module';
import { PortalRoutingModule } from './portal-routing.module';
import { PortalComponent } from './portal.component';

@NgModule({ declarations: [
        PortalComponent
    ],
    bootstrap: [PortalComponent], imports: [HomeModule,
        DeveloperModule,
        SettingsModule,
        PortalRoutingModule,
        BannerModule,
        BrowserModule,
        BrowserAnimationsModule, // required by ToastrModule
        ToastrModule.forRoot(),
        NavigationModule,
        HeaderModule,
        LayoutModule,
        NgbModule,
        ButtonsModule,
        FeedbackModule,
        AuthModule.forRoot({
            LicenseAssignmentServiceBaseUrl: '',
            SewrSystemDomain: ''
        })], providers: [
        AuthService,
        {
            provide: AbstractUserProvider,
            useExisting: environment.userProvider
        },
        PapiHttpService,
        MpnCheckService,
        {
            provide: APP_INITIALIZER,
            useFactory: (authService: AuthService) => {
                return () => {
                    authService.run();
                };
            },
            multi: true,
            deps: [AuthService]
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class PortalModule { }
