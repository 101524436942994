import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { DnsManagerComponent } from './dns-manager.component';
import { ButtonsModule, FeedbackModule } from '@skykick/core';

@NgModule({
  declarations: [
    DnsManagerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
    FeedbackModule,
    TranslateModule,
  ],
})
export class DnsManagerModule { }
