<!-- Google Tag Manager (noscript) -->
<noscript>
  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K895G2Q" height="0" width="0"
      style="display:none;visibility:hidden"></iframe>
</noscript>
<!-- End Google Tag Manager (noscript) -->
<section class="sk-flex-page">
    <div class="flex-center-center pb-100" [ngClass]="{'d-none': !loading}">
        <div class="sk-spin-loader"></div>
    </div>
  <ng-container *ngIf="!loading">
    <sk-left-nav></sk-left-nav>

    <main class="sk-content-pane">
      <router-outlet></router-outlet>
    </main>
  </ng-container>
</section>
