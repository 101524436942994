<div class="sk-page-header">
  <div class="sk-navbar-primary single-navbar">
    <div class="d-flex align-items-center min-w-0">
      <div class="d-flex min-w-0">
        <div class="min-w-0">
          <h3>{{ "settings.navigation.payment" | translate }}</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sk-page-content">
  <div class="sk-content-primary">
    <div class="sk-flex-table">
      <div class="sk-scroll-container">
        <div class="d-flex flex-column min-width-lg max-width-lg py-30 pr-300">
          <div class="col-12">
            <sk-customer-payment-profiles
              [config]="config"
              [displayOnly]="true"
            ></sk-customer-payment-profiles>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
