<div class="container">
  <div class="row">
    <div class="col text-center">
      <h1>BUILT with LOVE by</h1>
    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <img id="shared-services-logo" src="/assets/images/Shared-Services-Logo.png" alt="Shared Services">
    </div>
  </div>
  <div class="row mb-200 justify-content-center">
    <div class="col-6">
      <ul class="list-group text-center">
        <li class="list-group-item active">Maksym Marianchuk</li>
        <li class="list-group-item active">Todd Asher</li>
        <li class="list-group-item active">Sergei Ropin</li>
        <li class="list-group-item active">Maksym Morozov</li>
        <li class="list-group-item active">Zoey McCullough</li>
        <li class="list-group-item active">Nataliia Lynnyk</li>
        <li class="list-group-item active">Vladyslav Beniukh</li>
        <li class="list-group-item active">Artur Vasyliev</li>
        <li class="list-group-item active">Oleh Petrenko</li>
        <li class="list-group-item active">Anton Petrenko</li>
        <li class="list-group-item active">Cliff Stolk</li>

        <li class="list-group-item">Alyona Nikitenko</li>
        <li class="list-group-item">Nataliia Tyryshkina</li>
        <li class="list-group-item">Kostiantyn Soloviov</li>
        <li class="list-group-item">Oleksandr Kurnishov</li>
        <li class="list-group-item">Vasyl Korzhyk</li>
        <li class="list-group-item">Ivan Zymin</li>
        <li class="list-group-item">Anton Shyrokykh</li>
        <li class="list-group-item">Adam Prescott</li>
        <li class="list-group-item">Albert Garcia</li>
        <li class="list-group-item">Andy Dopieralski</li>
        <li class="list-group-item">Dan Schnau</li>
        <li class="list-group-item">Hugo Gallo</li>
        <li class="list-group-item">Jacob Borchers</li>
        <li class="list-group-item">Jean-Francois Peyroux</li>
        <li class="list-group-item">Matt Wozniak</li>
        <li class="list-group-item">Rob Morrison</li>
        <li class="list-group-item">Zach Knowles</li>
      </ul>
    </div>
  </div>
</div>

