import {
  PermissionScope,
} from 'src/app/settings/users/models/permission-scope';

export interface Customer {
    id: string;
    fullName: string;
    username: string;
    email: string;
    orderName: string;
    status: string;
    access: string;
    permission: string;
    permissionScopes: PermissionScope[];
}
