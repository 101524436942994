import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule, DropdownsModule, FeedbackModule, ModalsModule } from '@skykick/core';

import { M365AuthenticationAlertComponent } from '../users/components/m365-authentication-alert/m365-authentication-alert.component';
import { ActionsMenuComponent } from './components/actions-menu/actions-menu.component';
import { ErrorBannerComponent } from './components/error-banner/error-banner.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { LoadMoreComponent } from './components/load-more/load-more.component';
import { UnmatchedUsersFeedbackComponent } from './components/unmatched-users-feedback/unmatched-users-feedback.component';

@NgModule({
  declarations: [
    LoadMoreComponent,
    ErrorBannerComponent,
    ErrorModalComponent,
    ActionsMenuComponent,
    UnmatchedUsersFeedbackComponent,
    M365AuthenticationAlertComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonsModule,
    ModalsModule,
    NgbModule,
    FeedbackModule,
    DropdownsModule,
  ],
  exports: [
    CommonModule,
    LoadMoreComponent,
    ErrorBannerComponent,
    TranslateModule,
    ButtonsModule,
    ActionsMenuComponent,
    NgbModule,
    FeedbackModule,
    DropdownsModule,
    UnmatchedUsersFeedbackComponent,
    M365AuthenticationAlertComponent,
  ]
})
export class SharedModule { }
