<div ngbDropdown container="body">
    <button id="migrations-card-dropdown" class="btn btn-primary" ngbDropdownToggle
        translate>home.create_new</button>
    <div ngbDropdownMenu aria-labelledby="migrations-card-dropdown">
        <button id="create_smb_migration_button" ngbDropdownItem>
            <a ngbDropdownItem class="dropdown-item" [href]="getSMBUrl()"
                translate>home.product_types.smb_migration</a>
        </button>
        <button id="create_enterprise_migration_button" ngbDropdownItem>
            <a ngbDropdownItem class="dropdown-item" [href]="getEnterpriseUrl()"
                translate>home.product_types.enterprise_migration</a>
        </button>
        <button id="create_data_only_migration_button" ngbDropdownItem>
            <a ngbDropdownItem class="dropdown-item" [href]="getDataOnlyUrl()"
                translate>home.product_types.data_only_migration</a>
        </button>
        <div class="dropdown-divider"></div>
        <button id="create_data_only_migration_button" ngbDropdownItem>
            <a ngbDropdownItem class="dropdown-item" [href]="getBackupSubscriptionUrl()"
                translate>home.product_types.backup_subscription</a>
        </button>
    </div>
</div>