import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonsModule, CreditCardModule, FeedbackModule } from '@skykick/core';
import { SharedModule } from '../shared/shared.module';
import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentComponent } from './payment/payment.component';

@NgModule({
  declarations: [
    PaymentComponent
  ],
  imports: [
    CommonModule,
    PaymentRoutingModule,
    NgbModule,
    CreditCardModule,
    SharedModule,
    ButtonsModule,
    FeedbackModule
],
  providers: [
    NgbModule
  ]
})
export class PaymentModule { }
