export interface CustomerResult {
    Id: string;
    Username: string;
    FirstName: string;
    LastName: string;
    EmailAddress: string;
    OrderName: string;
    PartnerPortalRole: string;
    Status: number;
    Access: number;
    Permission: number;
}
