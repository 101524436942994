import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from "@angular/router";
import { CustomersComponent } from "./connectwise/customers/customers.component";
import { DetailsComponent } from "./connectwise/details/details.component";
import { TicketsComponent } from "./connectwise/tickets/tickets.component";

export class CustomReuseStrategy extends BaseRouteReuseStrategy {

    // We added this setup because a router by default ignores navigation to the same state/path.
    // It is crucial when we map products on the details page.
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        if(curr.component !== DetailsComponent && curr.component !== TicketsComponent && curr.component !== CustomersComponent) {
            return super.shouldReuseRoute(future, curr);
        }

        return false;
    }
}