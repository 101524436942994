<div class="sk-page-content">
    <div class="flex-center-center py-200" [ngClass]="{'d-none': !loading}">
        <div class="sk-spin-loader"></div>
    </div>
    <!-- CONTENT COLUMN -->
    <div class="sk-content-column" [ngClass]="{'d-none': loading}">

        <div class="d-flex flex-row align-items-center justify-content-between py-100 px-200 border-bottom">
            <!-- Heading Left -->
            <div class="d-flex flex-row align-items-center">
                <div class="d-flex flex-column">
                    <h2 class="font-weight-normal">{{ 'settings.customer.add.title' | translate }}</h2>
                    <span class="text-muted">{{ 'settings.customer.add.subtitle' | translate }}</span>
                </div>
            </div>
        </div>
        <!-- /.flex-row -->

        <!-- CONTENT PRIMARY - SCROLLABLE -->
        <div class="sk-content-primary">
            <div class="sk-flex-table">
                <div id="scrollWrap" class="sk-scroll-container">
                    <div class="d-flex flex-column min-width-lg h-75 max-width-lg pt-150 pb-300">
                        <div class="row">
                            <div class="col-10">
                                <div [formGroup]="addCustomerForm" class="pb-100">
                                    <div class="d-flex align-items-center pb-200">
                                        <h3 class="font-400">{{ 'settings.customer.add.search-customer' | translate }}</h3>
                                    </div>

                                    <ng-template #orderResultTemplate let-order="result" let-t="term">
                                        <div class="d-flex justify-content-between align-items-center w-100">
                                            <ngb-highlight [result]="order.name" [term]="t"></ngb-highlight>
                                            <span class="badge badge-default rounded-pill ml-50">{{ order.productTag
                                                }}</span>
                                        </div>
                                    </ng-template>

                                    <fieldset id="search-order-form" class="mb-100">
                                        <div class="d-flex align-items-center">
                                            <h5 class="font-600">{{ 'settings.customer.add.search-order' | translate }}</h5>
                                        </div>
                                        <div class="sk-input-icon input-width-lg">
                                            <input id="search-order" type="text" class="form-control"
                                                formControlName="order"
                                                [class.is-invalid]="isFieldInvalid('order')"
                                                [ngbTypeahead]="searchOrder"
                                                [resultTemplate]="orderResultTemplate"
                                                [inputFormatter]="orderFormatter"
                                                (selectItem)="orderSelected($event)"
                                                placeholder="{{ 'settings.customer.add.search' | translate }}">
                                            <i *ngIf="loadingOrder" class="sk-spin-loader loader-sm material-icons spin-component-custom"></i>
                                            <i *ngIf="!orderQueryIsEmpty && !loadingOrder" (click)="onOrderSearchClear()" class="material-icons md-18 sk-reset-search-btn ng-star-inserted" title="Reset and Exit Search"></i>
                                            <i *ngIf="orderQueryIsEmpty && !loadingOrder" class="material-icons md-18">search</i>
                                        </div>
                                        <small *ngIf="isFieldInvalid('order')" class="invalid-feedback">{{ 'settings.customer.add.validation-error' | translate }}</small>
                                    </fieldset>

                                    <ng-template #contactResultTemplate let-contact="result" let-t="term">
                                        <sk-avatar [fullName]="contact.fullName" size="md" shape="rounded" class="align-self-center pr-100"></sk-avatar>
                                        <div class="d-flex flex-column flex-grow-1 my-25">
                                            <ngb-highlight [result]="contact.fullName" [term]="t" class="text-truncate line-height-22"></ngb-highlight>
                                            <ngb-highlight [result]="contact.emailAddress" [term]="t" class="text-truncate line-height-22 text-muted"></ngb-highlight>
                                        </div>
                                    </ng-template>

                                    <fieldset id="search-contact-form" class="mb-100">
                                        <div class="d-flex align-items-center">
                                            <h5 class="font-600">{{ 'settings.customer.add.select-email' | translate }}</h5>
                                        </div>

                                        <div class="sk-input-icon input-width-lg">
                                            <input id="search-contact" type="text" class="form-control"
                                                #instance="ngbTypeahead"
                                                formControlName="contact"
                                                [ngbTypeahead]="searchCustomerName"
                                                [resultTemplate]="contactResultTemplate"
                                                [inputFormatter]="contactFormatter"
                                                [resultFormatter]="contactFormatter"
                                                [popupClass]="'sk-scrollable-typeahead'"
                                                (selectItem)="customerSelected($event)"
                                                [class.is-invalid]="isFieldInvalid('contact') || isBackendValidationFailed"
                                                placeholder="{{ 'settings.customer.add.search' | translate }}" />
                                            <i *ngIf="loadingCustomer" class="sk-spin-loader loader-sm material-icons spin-component-custom"></i>
                                            <i *ngIf="!customerQueryIsEmpty && !loadingCustomer" (click)="onCustomerSearchClear()" class="material-icons md-18 sk-reset-search-btn ng-star-inserted" title="Reset and Exit Search"></i>
                                            <i *ngIf="customerQueryIsEmpty && !loadingCustomer" class="material-icons md-18">search</i>
                                        </div>
                                        <small *ngIf="isFieldInvalid('contact')"
                                            class="invalid-feedback">{{ 'settings.customer.add.validation-error' | translate }}</small>
                                        <small *ngIf="isBackendValidationFailed" class="invalid-feedback">{{ backendValidationMessage | translate }}</small>
                                    </fieldset>

                                    <div class="d-flex align-items-end py-100" *ngIf="isOrderSelected()">
                                        <h3 class="font-400">{{ 'settings.customer.set-permissions' | translate }}</h3>
                                        <a class="ml-100" target="_blank" href="https://support.skykick.com/hc/en-us/articles/4415630910739-Cloud-Backup-Customer-Self-Service-Portals">{{ 'settings.customer.learn-more' | translate }}</a>
                                    </div>
                                    <ng-template [ngIf]="isBackupOrder()">
                                        <sk-feedback *ngIf="isSelfServicePageEnabled" class="mb-100" type="info"
                                            [message]="'settings.customer.info-banner' | translate">
                                        </sk-feedback>
                                        <fieldset id="backup-permission-form" *ngIf="isBackupOrder()" class="mb-100">
                                            <h4 class="py-100">{{ 'settings.customer.cloud-backup' | translate }}</h4>
                                            <div class="form-check">
                                                <input id="full-access" type="radio" name="permission"
                                                    class="form-check-input" formControlName="permission"
                                                    [class.is-invalid]="isFieldInvalid('permission')"
                                                    [value]="CustomerPermission.FullAccess"
                                                    [checked]="isPermissionSelected(CustomerPermission.FullAccess)">
                                                <label id="full-access-label" class="form-check-label font-medium" for="full-access">{{ 'settings.customer.full-access' | translate }}</label>
                                                <small class="sk-form-text">
                                                    <ul class="sk-ul pl-100">
                                                        <li>{{ 'settings.customer.full-access-desc' | translate }}</li>
                                                    </ul>
                                                </small>
                                            </div>
                                            <div class="form-check">
                                                <input id="search-restore" type="radio" name="permission"
                                                    class="form-check-input" formControlName="permission"
                                                    [class.is-invalid]="isFieldInvalid('permission')"
                                                    [value]="CustomerPermission.SearchAndRestore"
                                                    [checked]="isPermissionSelected(CustomerPermission.SearchAndRestore)">
                                                <label id="search-restore-label" class="form-check-label font-medium" for="search-restore">{{ 'settings.customer.search-restore' | translate }}</label>
                                                <small class="sk-form-text">
                                                    <ul class="sk-ul pl-100">
                                                        <li>{{ 'settings.customer.search-restore-desc' | translate }}</li>
                                                    </ul>
                                                </small>
                                            </div>
                                            <small *ngIf="isFieldInvalid('permission')"
                                                class="invalid-feedback">{{ 'settings.customer.add.validation-error' | translate }}</small>
                                        </fieldset>
                                    </ng-template>

                                    <fieldset id="migration-permission-form" *ngIf="isMigrationOrder()" class="mb-100">
                                        <h4 class="py-100">{{ 'settings.customer.migration-suits' | translate }}</h4>
                                        <div class="form-check">
                                            <input id="read-only" type="radio" name="permission"
                                                class="form-check-input" formControlName="permission"
                                                [class.is-invalid]="isFieldInvalid('permission')"
                                                [value]="CustomerPermission.ReadOnly"
                                                [checked]="isPermissionSelected(CustomerPermission.ReadOnly)">
                                            <label class="form-check-label font-medium" for="read-only">{{ 'settings.customer.read-only' | translate }}</label>
                                            <small class="sk-form-text">
                                                <ul class="sk-ul pl-100">
                                                    <li>{{ 'settings.customer.read-only-desc' | translate }}</li>
                                                </ul>
                                            </small>
                                        </div>
                                        <div class="form-check">
                                            <input id="read-write" type="radio" name="permission"
                                                class="form-check-input" formControlName="permission"
                                                [class.is-invalid]="isFieldInvalid('permission')"
                                                [value]="CustomerPermission.ReadAndWrite"
                                                [checked]="isPermissionSelected(CustomerPermission.ReadAndWrite)">
                                            <label class="form-check-label font-medium" for="read-write">{{ 'settings.customer.read-write' | translate }}</label>
                                            <small class="sk-form-text">
                                                <ul class="sk-ul pl-100">
                                                    <li>{{ 'settings.customer.read-write-desc' | translate }}</li>
                                                </ul>
                                            </small>
                                        </div>
                                        <small *ngIf="isFieldInvalid('permission')"
                                            class="invalid-feedback">{{ 'settings.customer.add.validation-error' | translate }}</small>
                                    </fieldset>
                                </div>
                            </div>
                            <!-- /.col -->
                            <div class="col-2">
                            </div>
                            <!-- /.col -->
                        </div>
                        <!-- /.row -->
                    </div>
                </div>
            </div>
            <!-- /.sk-flex-table -->
        </div>
        <!-- /.sk-content-primary -->
        <div class="sk-page-footer">
            <!-- Size Standard -->
            <div id="footer" class="sk-footer-content py-150">
                <button id="send-invite" sk-spinner-button class="btn-lg mr-100" [color]="'primary'" (click)="submitForm()" [isSpinning]="executingMainAction">
                    {{ 'settings.customer.add.send-invite' | translate }}
                </button>
                <button id="cancel" type="button" (click)="cancel()" class="btn btn-lg btn-outline-secondary mr-150">{{ 'settings.customer.add.cancel' | translate }}</button>
                <div class="d-flex align-items-center" *ngIf="addCustomerForm.invalid && isFormSubmitted">
                    <i class="material-icons md-20 icon-danger">warning</i> <small class="text-danger ml-50">{{ 'settings.customer.add.errors.submit-validation-error' | translate }}</small>
                </div>
            </div>
        </div>
    </div>

</div>
