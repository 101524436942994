<sk-modal type="danger">
    <sk-modal-header (dismiss)="dismiss()">{{ title }}</sk-modal-header>

    <sk-modal-body>
        <p [innerHtml]="primaryText" class="display-as-is"></p>
        <small [innerHtml]="secondaryText" class="text-muted"></small>
    </sk-modal-body>

    <sk-modal-footer>
        <button sk-button color="primary" (click)="close()">{{ btnText }}</button>
    </sk-modal-footer>
</sk-modal>
