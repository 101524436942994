import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable()
export class SessionExpiredInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(httpErrorResponse => this.handleAuthError(httpErrorResponse)));
  }

  private handleAuthError(httpErrorResponse: HttpErrorResponse): Observable<any> {
    if (httpErrorResponse.status === 401) {
        this.authService.apiAuthError();
        return of(httpErrorResponse.message); 
    }
    return throwError(() => httpErrorResponse);
  }
}