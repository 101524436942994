import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonsModule, FeedbackModule, LayoutModule, SortingModule, StatusSpinnerModule, TasksModule } from '@skykick/core';
import { AuthenticateComponent } from './connectwise/connection-wizard/authenticate/authenticate.component';
import { ConnectionWizardComponent } from './connectwise/connection-wizard/connection-wizard.component';
import { MapProductsComponent } from './connectwise/connection-wizard/map-products/map-products.component';
import { MapTicketComponent } from './connectwise/connection-wizard/map-ticket/map-ticket.component';
import { SyncSettingsComponent } from './connectwise/connection-wizard/sync-settings/sync-settings.component';
import { ConnectwiseComponent } from './connectwise/connectwise.component';
import { WizardStepsDirective } from './connectwise/core/directives/wizard-steps.directive';
import { WizardStepsService } from './connectwise/core/services/wizard-steps.service';
import { CustomerAsidePanelComponent } from './connectwise/customers/customer-aside-panel/customer-aside-panel.component';
import { CustomerSettingsComponent } from './connectwise/customers/customer-settings/customer-settings.component';
import { CustomersComponent } from './connectwise/customers/customers.component';
import { DetailsComponent } from './connectwise/details/details.component';
import { InfoPanelComponent } from './connectwise/info/info-panel.component';
import { TicketsComponent } from './connectwise/tickets/tickets.component';
import { HomeComponent } from './home/home.component';
import { IntegrationsRoutingModule } from './integrations-routing.module';
import { IntegrationsComponent } from './integrations.component';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs/breadcrumbs.component';

@NgModule({
    declarations: [
        IntegrationsComponent,
        HomeComponent,
        ConnectwiseComponent,
        DetailsComponent,
        CustomersComponent,
        TicketsComponent,
        BreadcrumbsComponent,
        ConnectionWizardComponent,
        CustomerSettingsComponent,
        CustomerAsidePanelComponent,
        WizardStepsDirective,
        AuthenticateComponent,
        MapProductsComponent,
        SyncSettingsComponent,
        MapTicketComponent,
        InfoPanelComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        LayoutModule,
        IntegrationsRoutingModule,
        ReactiveFormsModule,
        NgbModule,
        StatusSpinnerModule,
        NgbTypeaheadModule,
        FormsModule,
        SortingModule,
        TasksModule,
        ButtonsModule,
        FeedbackModule,
        LayoutModule
    ],
    providers: [
        WizardStepsService
    ]
})
export class IntegrationsModule { }
