<div class="sk-page-header">
    <div class="sk-navbar-primary single-navbar">
        <div class="d-flex align-items-center min-w-0">
            <div class="d-flex min-w-0">
                <div class="min-w-0">
                    <sk-breadcrumbs></sk-breadcrumbs>    
                </div>             
            </div>
        </div>
        <div class="d-flex" *ngIf="isInfoButtonVisible">
            <button sk-icon-button class="btn-toggle" icon="info_outline" skUniversalTaskButton [taskName]="taskType" ngbTooltip="Info" placement="bottom">
            </button>
        </div>  
    </div>
</div>

<div class="sk-page-content">

    <div class="sk-content-primary">
        <router-outlet></router-outlet>

    </div>

    <!-- PANEL RIGHT -->
    <div sk-panel></div>

</div>