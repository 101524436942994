import { Injectable } from '@angular/core';
import {
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

import { CustomerPermission } from '../models';
import Contact from '../models/contact/contact';
import Order from '../models/order/order';

@Injectable({
    providedIn: 'root',
  })
  export class CustomersValidator {
    static isOrderValid = (control: AbstractControl, orders: Order[]): ValidationErrors => {
        return orders && orders.find(x => x.name === control.value?.name) ? null : { isValid: false };
      }
    
      static isContactValid = (control: AbstractControl, contacts: Contact[]): ValidationErrors => {
        return contacts && contacts.find(x => x.emailAddress === control.value?.emailAddress) ? null : { isValid: false }
      }
    
      static isPermissionValid = (control: AbstractControl): ValidationErrors => {
        return Object.values(CustomerPermission).includes(control.value) ? null : { isValid: false };
      }
  }