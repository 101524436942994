import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ApiErrorCode } from '../models/api-error-code';
import { ErrorModel } from '../models/error.model';

@Injectable({
    providedIn: 'root',
})
export class ErrorFactory {
    private rootErrorsKey = 'settings.common.error';
    private defaultErrorKey = `${this.rootErrorsKey}.request-failed`;
    private emailAccountAlreadyInUseKey = 'settings.common.email.exists';

    constructor(private translateService: TranslateService) {}

    /**
     * @summary Error handling that determines the message for the user. (with translation)
     * @param error The model that we expect to receive from the backend, which has an error code.
     * @returns Observable of translation message.
     *          If the error is processed with 'code', we will return an error message,
     *          if not, we will return the general message with key 'settings.common.error.request-failed'
     */
    getMessage(error: ErrorModel): Observable<any> {
        const errorKey = error?.code ? this.determineErrorKey(error.code) : this.defaultErrorKey;
        return this.translateService.get(errorKey);
    }

    private determineErrorKey(errorCode: ApiErrorCode): string {
        switch (errorCode) {
            case ApiErrorCode.AzureCosmosFailure:
                return `${this.rootErrorsKey}.error-occurred-during-action-try-again-later`;
            case ApiErrorCode.UserAliasShouldBeNotTheSameAsUserName:
                return this.emailAccountAlreadyInUseKey;
            case ApiErrorCode.UserAliasAlreadyExistsForTheUser:
                return this.emailAccountAlreadyInUseKey;
            case ApiErrorCode.CrmContactNotFound:
                return `${this.rootErrorsKey}.crm-contact-not-found`;
            case ApiErrorCode.KenticoUserNotFound:
                return `${this.rootErrorsKey}.kentico-user-not-found`;
            case ApiErrorCode.OrderNotFound:
                return `${this.rootErrorsKey}.order-not-found`;
            case ApiErrorCode.PartnerPrimaryContactNotFound:
                return `${this.rootErrorsKey}.partner-primary-contact-not-found`;
            default:
                return this.defaultErrorKey;
        }
    }
}
