import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PRORATIONSETTING } from '../../core/constants/proration-setting.const';
import { WizardStepComponent } from '../../core/models/WizardStepComponent';
import { ActionButtonsService } from '../../core/services/action-buttons.service';
import { ConnectwiseResourcesService } from '../../core/services/connectwise-resources.service';
import { ConnectwiseStateManagerService } from '../../core/services/connectwise-state-manager.service';

@Component({
    selector: 'sk-sync-settings',
    templateUrl: './sync-settings.component.html',
    styleUrls: ['./sync-settings.component.scss']
})
export class SyncSettingsComponent implements WizardStepComponent, OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    @HostBinding('class') class='d-flex flex-column flex-grow-1';
    @Input() data: any;
    @Input() settingType: string;
    PRORATIONSETTING = PRORATIONSETTING;
    syncSettingsForm: UntypedFormGroup;
    isLoadingProrationSettings: boolean;
    errors: Array<string> = [];
    partnerData: any;

    private prorationSettingRequired(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control && control.value === PRORATIONSETTING.Unset) {
                return { 'prorationSettingRequired': true };
            }
            return null;
        };
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        public activeModal: NgbActiveModal,
        private connectwiseStateManagerService: ConnectwiseStateManagerService,
        private connectwiseResourcesService: ConnectwiseResourcesService,
        private toastrService: ToastrService,
        private actionButtonsService: ActionButtonsService
    ) { }

    ngOnInit(): void {
        this.partnerData = this.connectwiseStateManagerService.getConnectWisePartner();
        this.syncSettingsForm = this.formBuilder.group({
            prorationSetting: [PRORATIONSETTING.Unset, [this.prorationSettingRequired()]]
        });
        this.syncSettingsForm.patchValue(this.partnerData);
    }

    submit() {
        this.partnerData.prorationSetting = parseInt(this.syncSettingsForm.get('prorationSetting').value);
        this.syncSettingsForm.disable();
        this.errors.length = 0;
        this.connectwiseResourcesService.updatePartner(this.partnerData).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe({
            complete: () => {
                this.syncSettingsForm.enable();
                this.toastrService.success('Update successful');
                if (this.settingType === 'wizard') {
                    this.actionButtonsService.submitWizardStep(this.data);
                } else {
                    this.activeModal.close();
                }
            }, 
            error: res => {
                if (res.error) {
                    this.errors.push(res.error.message);
                }
                this.syncSettingsForm.enable();
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

}
