import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import {
    combineLatest,
    forkJoin,
    fromEvent,
    merge,
    Observable,
    of,
    Subject,
    throwError
} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    skip,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { FilterArea } from 'src/app/settings/models/filter-area';
import { FilterItem } from 'src/app/settings/models/filter-item';
import { TaskType } from 'src/app/settings/models/task-type';
import {
    ErrorModalService,
} from 'src/app/settings/shared/services/error-modal.service';

import { TranslateService } from '@ngx-translate/core';
import {
    EmptyPanelComponent,
    OperationCancelledError,
    SkyKickModalService,
    TaskManagerService
} from '@skykick/core';
import {
    AbstractUserProvider,
} from '@skykick/platform-identity-auth-auth0-angular';

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorFactory } from 'src/app/shared/factories/errors.factory';
import {
    PartnerPortalUserClaims,
} from '../../models/partner-portal-user-claims';
import { ClaimsService } from '../../services/claims.service';
import { UsersComponentContext } from '../users-component-context';
import {
    CustomerDetailsPanelComponent,
} from './customer-details-panel/customer-details-panel.component';
import {
    CustomerDetailsPanelContext,
} from './customer-details-panel/models/customer-details-panel-context';
import {
    Customer,
    CustomerStatus,
} from './models';
import { CustomersSearchFilter } from './models/customers-search-filter';
import {
    RemoveCustomerModalComponent,
} from './remove-customer-modal/remove-customer-modal.component';
import {
    CustomersAccessProvider,
    CustomersPermissionProvider,
    CustomersService,
    CustomerStatusProvider,
    FiltersManagerService,
} from './services';
import { CustomerActionEffectService } from './services/customer-action-effect.service';

@Component({
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, AfterViewInit, OnDestroy {

    ActiveStatus = CustomerStatusProvider.getStatusValueByKey(CustomerStatus.Active);
    InactiveStatus = CustomerStatusProvider.getStatusValueByKey(CustomerStatus.Inactive);

    private filterSubject = new Subject<string>();
    private filter$: Observable<string> = this.filterSubject.asObservable();
    private currentPageNumber = 1;
    private destroy$: Subject<void> = new Subject<void>();
    private addCustomerId: string = undefined;
    private refreshCustomerId: string = undefined;

    initialLoading = true;
    loadCustomersError = false;
    showLoadMore = false;
    loadingMore = false;
    loading = false;
    showFilterBar = false;

    filterAreas: FilterArea[] = [];
    customers: Customer[];
    isResetPasswordUnavailable = true;
    partnerClaims: PartnerPortalUserClaims;

    @ViewChild('searchCustomersInput')
    input: ElementRef;
    @ViewChild('clearSearchBtn')
    clearSearchBtn: ElementRef;
    @ViewChild('searchCustomersBtn')
    searchCustomersBtn: ElementRef;

    constructor(
        private customersService: CustomersService,
        private claimsService: ClaimsService,
        private abstractUserProvider: AbstractUserProvider,
        private filtersManager: FiltersManagerService,
        private modalService: SkyKickModalService,
        private errorModalService: ErrorModalService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private router: Router,
        private taskManagerService: TaskManagerService,
        private usersComponentContext: UsersComponentContext,
        private customerActionEffectService: CustomerActionEffectService,
        private errorFactory: ErrorFactory) {
        this.addCustomerId = this.router.getCurrentNavigation()?.extras?.state?.addCustomerId;
        this.refreshCustomerId = this.router.getCurrentNavigation()?.extras?.state?.refreshCustomerId;
        this.populateFilterAreas();
    }

    ngOnInit(): void {
        forkJoin([
            this.getCustomers('', 1),
            this.claimsService.getPartnerPortalUserClaimsAsync(this.abstractUserProvider.getCurrentUser().email).pipe(
                takeUntil(this.destroy$),
                catchError(error => {
                    this.handleLoadingError();
                    return throwError(() => error);
                })
            ),
            this.addCustomerId || this.refreshCustomerId
                ? this.customersService.getCustomer(this.addCustomerId ?? this.refreshCustomerId).pipe(
                    catchError(error => {
                        this.handleLoadingError();
                        return throwError(() => error);
                    }))
                : of<Customer>(null)])
            .subscribe({
                next: ([customers, claims, customerToAddOrRefresh]) => {
                    this.customers = customers;
                    if (customerToAddOrRefresh) {
                        let customerIndex = this.customers.findIndex(x => x.id == customerToAddOrRefresh.id);
                        if (this.addCustomerId && customerIndex < 0)
                            this.customers.push(customerToAddOrRefresh);
                        if (this.refreshCustomerId && customerIndex >= 0)
                            this.customers[customerIndex] = customerToAddOrRefresh;
                    }
                    this.isResetPasswordUnavailable =
                        claims === undefined ||
                        claims.isSelfServicePageEnabled;
                    this.partnerClaims = claims;
                    this.initialLoading = false;
                    this.addCustomerId = undefined;
                    this.refreshCustomerId = undefined;
                },
                error: () => {
                    this.initialLoading = false;
                }
            });

        this.taskManagerService.configureTask(
            TaskType.Empty,
            null,
            EmptyPanelComponent
        );
    }

    ngAfterViewInit(): void {
        combineLatest([
            this.filterClicksObservable().pipe(startWith('')),
            this.searchInputObservable().pipe(startWith('')),
        ]).pipe(
            skip(1),
            debounceTime(500),
            tap(() => this.loading = true),
            switchMap(([_, searchTerm]) => this.getCustomers(searchTerm, 1))
        ).subscribe(customers => {
            this.loading = false;
            this.customers = customers;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    showLoadMoreCustomers(): boolean {
        return this.showLoadMore && !this.loadingMore && !this.loading && this.customers?.length > 0;
    }

    loadMoreCustomers(): void {
        this.loadingMore = true;
        this.getCustomers(this.searchInputValue, this.currentPageNumber + 1)
            .subscribe(customers => {
                this.loadingMore = false;
                this.appendCustomers(customers, []);
            });
    }

    editCustomer(customer: Customer): void {
        this.router.navigate(['settings', 'users', 'customers', customer.id, 'edit']);
    }

    deactivateCustomer(customer: Customer): void {
        this.customerActionEffectService.deactivate(
            this.customersService.deactivateCustomer(customer.id),
            customer
        ).subscribe();
    }

    activateCustomer(customer: Customer): void {
        this.customersService.activateCustomer(customer.id).pipe(
            tap(result => {
                if (result) {
                    customer.status = CustomerStatusProvider.getStatusValueByKey(CustomerStatus.Active);
                    this.translateService.get('settings.customers.actions.activate-success').pipe(
                        tap((successText: string) => this.toastrService.success(successText))
                    ).subscribe();
                }
            }),
            catchError((response: HttpErrorResponse) => this.errorFactory.getMessage(response?.error).pipe(
                tap((errorText: string) => this.toastrService.error(errorText))
            ))
        ).subscribe();
    }

    removeCustomer(customer: Customer): void {
        const modalRef = this.modalService.open<RemoveCustomerModalComponent, any>(
            RemoveCustomerModalComponent,
            { backdrop: 'static' }
        );
        modalRef.componentInstance.customerId = customer.id;
        modalRef.result.then(result => {
            if(result.data)
            {
                this.getCustomers(this.searchInputValue, this.currentPageNumber)
                    .pipe(tap(customers => this.appendCustomers(customers, [customer])))
                    .subscribe();
            }
        });
    }

    resetPassword(customer: Customer): void {
        this.customerActionEffectService.resetPassword(
            this.customersService.resetPassword(customer.username),
            customer
        ).subscribe();
    }

    checkFilter(filter: FilterItem): void {
        if (filter.isEnabled) {
            this.filtersManager.handleFilterChecked(filter, this.filterAreas);
            this.filterSubject.next(this.filtersManager.getUniqueFilterValue(this.filterAreas));
        }
    }

    showClearFiltersButton(): boolean {
        return this.filtersManager.getSelectedFiltersCount(this.filterAreas) > 0;
    }

    clearAllFilters(): void {
        this.filtersManager.uncheckAllFilters(this.filterAreas);
        this.filterSubject.next(this.filtersManager.getUniqueFilterValue(this.filterAreas));
    }

    get searchInputValue(): string {
        return this.input.nativeElement.value;
    }

    set searchInputValue(value: string) {
        this.input.nativeElement.value = value;
    }

    selectCustomer(customer: Customer, event: Event): void {
        // Checking if action menu button was clicked in the table row
        const actionMenu = (event.target as Element).closest('sk-actions-menu');
        if (actionMenu) {
            return;
        }

        // Open details pane if table row was clicked (not action menu button)
        const viewModel = {
            id: customer.id,
            fullName: customer.fullName,
            email: customer.email,
            access: customer.access,
            status: customer.status,
            permissionScopes: customer.permissionScopes,
            partnerClaims: this.partnerClaims
        };

        this.usersComponentContext.infoButtonTaskType = TaskType.CustomerDetails;

        this.taskManagerService.configureTask(
            TaskType.CustomerDetails,
            new CustomerDetailsPanelContext(viewModel),
            CustomerDetailsPanelComponent
        );

        this.taskManagerService.activateTask(TaskType.CustomerDetails)
            .catch(e => {
                OperationCancelledError.suppressOnCancel(e);
            });
    }

    getAccessLocKey(accessKey: string): string {
        return CustomersAccessProvider.getAccessDisplayNameLocKeyByValue(accessKey);
    }

    getPermissionLocKey(permissionKey: string): string {
        return CustomersPermissionProvider.getPermissionDisplayNameLocKeyByValue(permissionKey);
    }

    getStatusLocKey(statusKey: string): string {
        return CustomerStatusProvider.getStatusDisplayNameLocKeyByValue(statusKey);
    }

    private populateFilterAreas(): void {
        this.filterAreas = this.filtersManager.getFilterAreas();
    }

    private filterClicksObservable(): Observable<string> {
        return this.filter$
            .pipe(
                distinctUntilChanged()
            );
    }

    private searchInputObservable(): Observable<string> {
        return merge(
            fromEvent<any>(this.input.nativeElement, 'keyup')
                .pipe(
                    filter(event => event.code === 'Enter'),
                    map(event => event.target.value)),
            fromEvent<any>(this.searchCustomersBtn.nativeElement, 'click')
                .pipe(
                    map(() => this.searchInputValue)),
            fromEvent<any>(this.clearSearchBtn.nativeElement, 'click')
                .pipe(
                    map(() => {
                        this.searchInputValue = '';
                        return '';
                    }))
        ).pipe(
            distinctUntilChanged()
        );
    }

    private getCustomers(searchString: string, pageNumberToGet: number): Observable<Customer[]> {
        const searchFilter = new CustomersSearchFilter(
            {
                searchTerm: searchString,
                pageNumber: pageNumberToGet,
                permissions: this.getPermissionsFilter(),
                status: this.getStatusFilter(),
                orderBy: 'Name,EmailAddress'
            });
        return this.customersService.getCustomers(searchFilter).pipe(
            tap(() => this.currentPageNumber = pageNumberToGet),
            tap(customers => this.showLoadMore = customers.length === searchFilter.pageSize),
            takeUntil(this.destroy$),
            catchError(error => {
                this.handleLoadingError();
                return throwError(() => error);
            })
        );
    }

    private handleLoadingError(): void {
        this.customers = [];
        this.loadCustomersError = true;
        this.errorModalService.openErrorModal();
    }

    private getPermissionsFilter(): number[] {
        return this.filtersManager.getPermissions(this.filterAreas)
            .map(x => CustomersPermissionProvider.getPermissionKeyByValue(x));
    }

    private getStatusFilter(): CustomerStatus[] {
        return this.filtersManager.getStatuses(this.filterAreas)
            .map(x => CustomerStatusProvider.getStatusKeyByValue(x));
    }

    private appendCustomers(customersToAdd: Customer[], customersToExclude: Customer[]) {
        this.customers = this.customers.filter(customer => customersToExclude.every(customerToExclude => customer.id !== customerToExclude.id));
        this.customers.push(
            ...customersToAdd.filter(customer =>
                this.customers.every(customerToAdd => customer.id !== customerToAdd.id) &&
                customersToExclude.every(customerToExclude => customer.id !== customerToExclude.id)));
    }
}
