import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { last, map, tap } from "rxjs/operators";
import { LogoUpload } from "../models/logo-upload";

import {
    AppId,
    RouteResolverService,
} from '@skykick/core';

@Injectable({
    providedIn: "root"
})
export class UploaderService {
    public progressSource = new BehaviorSubject<number>(0);

    constructor(
        private http: HttpClient,
        private routeResolverService: RouteResolverService
    ) { }

    upload(file: File, payload: LogoUpload) {
        const uploadUrl = `${this.getPAPIRoute()}partner/logo`;
        const req = new HttpRequest(
            "POST",
            uploadUrl,
            payload,
            {
                reportProgress: true
            }
        );

        return this.http.request(req).pipe(
            map(event => this.getEventMessage(event, file)),
            tap((envelope: any) => this.processProgress(envelope)),
            last()
        );
    }

    private processProgress(envelope: any): void {
        if (typeof envelope === "number") {
            this.progressSource.next(envelope);
        }
    }

    private getEventMessage(event: HttpEvent<any>, file: File) {
        switch (event.type) {
            case HttpEventType.Sent:
                return `Uploading file "${file.name}" of size ${file.size}.`;
            case HttpEventType.UploadProgress:
                return Math.round((100 * event.loaded) / event.total);
            case HttpEventType.Response:
                return `File "${file.name}" was completely uploaded!`;
            default:
                return `File "${file.name}" surprising upload event: ${event.type}.`;
        }
    }

    private getPAPIRoute(): string {
        return this.routeResolverService.generatePipeRoute(AppId.Papi);
    }
}
