import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WIZARDNAVS } from 'src/app/settings/integrations/connectwise/core/constants/wizard-navs.const';

@Injectable({
    providedIn: 'root'
})
export class ConnectwiseStateManagerService {
    private partnerSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    connectWisePartner$: Observable<any> = this.partnerSubject.asObservable();

    constructor() { }

    getConnectWisePartner() {
        return this.partnerSubject.getValue();
    }

    setConnectWisePartner(partnerData: any) {
        this.partnerSubject.next(partnerData);
    }

    markAsCompleteWizardNav(type: string) {
        let wizardNav = WIZARDNAVS.find(item => item.value === type);
        wizardNav.complete = true;
    }

    markAsIncompleteWizardNav(type: string) {
        let wizardNav = WIZARDNAVS.find(item => item.value === type);
        wizardNav.complete = false;
    }

    markAsActiveWizardNav(type: string) {
        WIZARDNAVS.forEach(item => item.active = false);
        let wizardNav = WIZARDNAVS.find(item => item.value === type);
        wizardNav.active = true;
    }
}
