import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { SkyKickModalService } from '@skykick/core';

import { ErrorModalComponent } from '../components/error-modal/error-modal.component';
import { PublicUrlsProvider } from './public.urls.provider';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ErrorModalService {
    constructor(
      private translateService: TranslateService,
      private modalService: SkyKickModalService
    ) {}

    public async openErrorModal(): Promise<void> {
        const modalRef = this.modalService.open<ErrorModalComponent, any>(ErrorModalComponent);
        const somethingWrongLocKey = 'settings.common.error.something-wrong';
        const requestIssueLocKey = 'settings.common.error.request-issue';
        const tryAgainLocKey = 'settings.common.try-again-later';
        const contactForAssistance = 'settings.common.error.contact-for-assistance';
        const okGotItLocKey = 'settings.common.error.ok-got-it';

        const statusPageUrl = PublicUrlsProvider.StatusPageUrl;
        const supportPageUrl = PublicUrlsProvider.SupportPageUrl;

        const translations = await lastValueFrom(this.translateService.get([
              somethingWrongLocKey,
              requestIssueLocKey,
              tryAgainLocKey,
              contactForAssistance,
              okGotItLocKey
            ],
            { statusPageUrl, supportPageUrl }
        ));

        modalRef.componentInstance.title = translations[somethingWrongLocKey];
        modalRef.componentInstance.primaryText = `${translations[requestIssueLocKey]}. ${translations[tryAgainLocKey]}`;
        modalRef.componentInstance.secondaryText = translations[contactForAssistance];
        modalRef.componentInstance.btnText = translations[okGotItLocKey];
        await modalRef.result;
    }
}
