export enum CustomerValidationStatus{
  Valid = 0,
  HasActiveOrder = 1,
  AlreadyAssociatedToProject = 2,
  BoundToActiveOrder = 3,
  ContactDoesNotExist = 4,
  ContactCanNotBeModified = 5,
  OrderNotFound = 6,
  InvalidOrderType = 7
}
