import { Component, OnInit } from '@angular/core';
import { AppId, RouteResolverService } from '@skykick/core';

@Component({
  selector: 'sk-create-new-button',
  templateUrl: './create-new-button.component.html',
  styleUrls: ['./create-new-button.component.scss']
})
export class CreateNewButtonComponent implements OnInit {

  constructor(private routeResolverService: RouteResolverService) { }

  ngOnInit(): void {
  }

  getEnterpriseUrl(): string {
    return this.routeResolverService.generateRatRoute(AppId.Migrate, '/partner/enterprise/new');
  }

  getSMBUrl(): string {
    return this.routeResolverService.generateRatRoute(AppId.Migrate, '/partner/migrations/smb');

  }

  getDataOnlyUrl(): string {
    return this.routeResolverService.generateRatRoute(AppId.Migrate, '/partner/dataonly/new');
  }

  getBackupSubscriptionUrl(): String {
    return this.routeResolverService.generateRatRoute(AppId.Backup, '/partner/cloud-backup/subscriptions');
  }
}
