<ng-container *ngIf="form && !failedToLoadPreferences; else loadingOrError">
  <form
    class="mb-100"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    (keydown.enter)="$event.preventDefault()"
    [@fadeIn]
  >
    <label class="contacts-label font-medium" for="criticalContacts" translate>
      <span *ngIf="productType == ProductType.account" translate>platform.alerts.severity.account</span>
      <span *ngIf="productType != ProductType.account" translate>platform.alerts.severity.critical</span>
    </label>
    <sk-tags-input-email
      class="sk-tags-input mb-100"
      initialMode="write"
      id="criticalContacts"
      formControlName="criticalContacts"
      placeholder="Add Recipients"
      allowed-tags-pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
      allowPastingMultipleEmails="true"
    ></sk-tags-input-email>

    <div *ngIf="productType != ProductType.account">
      <label class="contacts-label font-medium" for="warningContacts" translate>
        platform.alerts.severity.warning
      </label>
      <sk-tags-input-email
        class="sk-tags-input mb-100"
        initialMode="write"
        id="warningContacts"
        formControlName="warningContacts"
        placeholder="Add Recipients"
        allowed-tags-pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
        allowPastingMultipleEmails="true"
      ></sk-tags-input-email>

      <label class="contacts-label font-medium" for="informationContacts" translate>
        platform.alerts.severity.informational
      </label>
      <sk-tags-input-email
        class="sk-tags-input mb-100"
        initialMode="write"
        id="informationContacts"
        formControlName="informationContacts"
        placeholder="Add Recipients"
        allowed-tags-pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
        allowPastingMultipleEmails="true"
      ></sk-tags-input-email>

      <label class="contacts-label font-medium" for="successContacts" translate>
        platform.alerts.severity.success
      </label>
      <sk-tags-input-email
        class="sk-tags-input mb-100"
        initialMode="write"
        id="successContacts"
        formControlName="successContacts"
        placeholder="Add Recipients"
        allowed-tags-pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
        allowPastingMultipleEmails="true"
      ></sk-tags-input-email>

      <label class="contacts-label font-medium" for="billingContacts" translate>
        platform.alerts.severity.billing
      </label>
      <sk-tags-input-email
        class="sk-tags-input mb-100"
        initialMode="write"
        id="billingContacts"
        formControlName="billingContacts"
        placeholder="Add Recipients"
        allowed-tags-pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
        allowPastingMultipleEmails="true"
      ></sk-tags-input-email>
    </div>

    <button
      type="submit"
      sk-spinner-button
      [isSpinning]="savingPreferences"
      [disabled]="savingPreferences"
      color="primary"
      class="btn-sm">
      {{ "settings.notifications.save_preferences" | translate }}
    </button>
  </form>
</ng-container>

<ng-template #loadingOrError>
  <sk-text-spinner
    *ngIf="!failedToLoadPreferences"
    message="Fetching Product Preferences"
  ></sk-text-spinner>
  <ngb-alert
    *ngIf="failedToLoadPreferences"
    [dismissible]="false"
    [type]="'danger'"
  >
    <strong translate>
      {{ "settings.notifications.error_title" | translate }}</strong
    >
    {{ "settings.notifications.error_message" | translate }}
  </ngb-alert>
</ng-template>
