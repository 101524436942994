import { FilterItem } from './filter-item';

export class FilterItemWithGroup<T> extends FilterItem {

  constructor(displayName: string, value: string, isSelected: boolean = false, isEnabled: boolean = true, group: T) {
    super(displayName, value, isSelected, isEnabled);
    this.group = group;
}

  public group: T;
}
