export class MembersSearchFilter {

    public constructor(init?: Partial<MembersSearchFilter>) {
        Object.assign(this, init);
    }

    public searchTerm: string = null;
    public status: string[] = null;
    public access: string[] = null;
    public roles: string[] = null;
    public error: string[] = null;
    public pageNumber: number = null;
    public pageSize = 10;
    public orderBy: string = null;
}
