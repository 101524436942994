import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HasPermissionGuard } from '../notifications/guards/has-permission.guard';
import { MembersRoleProvider } from '../users/components/members/services/members.role.provider';
import { PaymentComponent } from './payment/payment.component';

const BillingFullAccessPermission = 'Billing_Full_Access';

export const PAYMENT_ROUTES_PERMISSIONS = [MembersRoleProvider.PartnerPortalAdmin.key, BillingFullAccessPermission];

export const PAYMENT_ROUTES: Routes = [
  {
      path: 'payment',
      component: PaymentComponent,
      canActivate: [HasPermissionGuard],
      data: {
        permissions: PAYMENT_ROUTES_PERMISSIONS,
        permissionAbsentRedirect: 'settings',
      },
  }
];

@NgModule({
  imports: [RouterModule.forChild(PAYMENT_ROUTES)],
  exports: [RouterModule]
})
export class PaymentRoutingModule { }
