import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'sk-load-more',
  templateUrl: './load-more.component.html',
  styleUrls: ['./load-more.component.scss']
})
export class LoadMoreComponent implements OnInit {

  @Input()
  text: string;

  @Output()
  loadMoreClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onLoadMoreClicked(): void{
    this.loadMoreClicked.emit();
  }

}
