import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OperationCancelledError, ScopedTaskBase, SkyKickModal, SkyKickModalService, SkyKickModalWarningOptions, TaskManagerService, WarningModalComponent } from '@skykick/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { map, skipWhile, startWith, takeUntil, tap } from 'rxjs/operators';
import { FilterItem } from 'src/app/settings/models/filter-item';
import { ConnectionWizardComponent } from '../connection-wizard/connection-wizard.component';
import { ERRORSTATES } from '../core/constants/error-state.const';
import { FILTERTYPE } from '../core/constants/filter-type.const';
import { PRORATIONSETTING } from '../core/constants/proration-setting.const';
import { WIZARDTYPES } from '../core/constants/wizard-types.const';
import { ActionButtonsService } from '../core/services/action-buttons.service';
import { ConnectwiseResourcesService } from '../core/services/connectwise-resources.service';
import { ConnectwiseStateManagerService } from '../core/services/connectwise-state-manager.service';
import { CustomerAccountsService } from '../core/services/customer-accounts.service';
import { CustomerAsidePanelComponent } from './customer-aside-panel/customer-aside-panel.component';

@Component({
    selector: '[sk-customers]',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss']
})
export class CustomersComponent extends ScopedTaskBase implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    FILTERTYPE = FILTERTYPE;
    PRORATIONSETTING = PRORATIONSETTING;
    ERRORSTATES = ERRORSTATES;
    @HostBinding('class.sk-content-primary') isActive = true;
    form: UntypedFormGroup;
    filteredCustomerAccounts$: Observable<any>;
    totalRecords: number = 0;
    resultsPerPage: number = 1000;
    pageNumber: number = 1;
    filterTypeValue: string = FILTERTYPE[0].value;
    filterTypeName: string = FILTERTYPE[0].name;
    selectedCustomer$: Observable<any>;
    isSyncBillingInfo: boolean;
    partnerData$: Observable<any>;
    hasAgreementErrors: boolean;
    hasMissingProducts: boolean;

    constructor(
        protected taskManagerService: TaskManagerService,
        private connectwiseResourcesService: ConnectwiseResourcesService,
        private customerAccountsService: CustomerAccountsService,
        private formBuilder: UntypedFormBuilder,
        private actionButtonsService: ActionButtonsService,
        private toastrService: ToastrService,
        private connectwiseStateManagerService: ConnectwiseStateManagerService,
        private skyKickModalService: SkyKickModalService,
        private modalService: NgbModal
    ) {
        super(taskManagerService);
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            search: ['']
        });
        this.form.get('search').valueChanges.pipe(
            startWith('')
        ).subscribe(() => {
            this.filterCustomerAccountsByMappingFilter(this.resultsPerPage);
        });

        this.filteredCustomerAccounts$ = this.customerAccountsService.getFilteredCustomerAccounts();

        this.loadCustomerAccounts();

        this.selectedCustomer$ = this.customerAccountsService.getSelectedCustomer();

        this.partnerData$ = this.connectwiseStateManagerService.connectWisePartner$.pipe(
            skipWhile(val => !val)
        );
    }

    async selectItem(company: any, event: Event) {
        const actionMenu = (event.target as Element).closest('#sk-actions-menu');
        if (actionMenu) {
            return;
        }
        try {
            this.customerAccountsService.setSelectedCustomer(company);
            await this.openTask(CustomerAsidePanelComponent, company);
        } catch (e) {

            OperationCancelledError.suppressOnCancel(e);

            if (OperationCancelledError.wasCancelledByUser(e)) {
                this.customerAccountsService.setSelectedCustomer(null);
            }
        }
    }

    loadCustomerAccounts() {
        const payload = { searchTerm: "", resultsPerPage: null, pageNumber: null };

        this.connectwiseResourcesService.getCompanyMappings(payload).pipe(
            tap(res => this.totalRecords = res.totalRecords),
            map(res => res.entities),
            tap(res => {
                res.sort((x,y) => x.orderName.toLowerCase() < y.orderName.toLowerCase() ? -1 : 1);
                this.customerAccountsService.setCustomerAccounts(res);
                const filteredAccounts = res.slice(this.resultsPerPage * (this.pageNumber - 1), this.resultsPerPage * this.pageNumber);
                this.customerAccountsService.setFilteredCustomerAccounts(filteredAccounts);

                this.hasAgreementErrors = res.some(x => x.hasAgreementError);
                this.hasMissingProducts = res.some(x => x.missingProducts);
            }),
            takeUntil(this.onDestroy$)
        ).subscribe();
    }

    filterCustomerAccountsByMappingFilter(resultsPerPage: number) {
        this.customerAccountsService.filterCustomerAccountsByMappingFilter(this.filterTypeValue, this.form.get('search').value, resultsPerPage);
    }

    filterCustomerAccountsByErrorFilter(filter: FilterItem, resultsPerPage: number) {
      filter.isSelected = !filter.isSelected;
      this.customerAccountsService.filterCustomerAccountsByErrorFilter(
        this.ERRORSTATES.filter(x => x.isSelected).map(x => x.value),
        this.form.get('search').value,
        resultsPerPage);
    }

    syncBillingInfo() {
        this.isSyncBillingInfo = true;
        this.connectwiseResourcesService.syncBillingInfo().subscribe({
            next: _ => {
                this.isSyncBillingInfo = false;
                this.toastrService.success('Sync operation started');
            },
            error: _ => {
                this.toastrService.error('Sync operation failed');
        }});
    }

    removeMapping(company: any) {
      this.form.disable();
      let warningModal: SkyKickModal<WarningModalComponent, void>;
      const options: SkyKickModalWarningOptions = {
          body: `<p class="mb-200">We will no longer be able to push billing data for Cloud Backup Subscriptions.</p>`,
          title: 'Remove Customer Mapping to ConnectWise',
          btnLabel: 'Yes, Remove Mapping',
          alternative: {
              btnLabel: 'Cancel',
              btnCallback: () => {
                  warningModal.dismiss();
                  this.form.enable();
              }
          }
      };
      warningModal = this.skyKickModalService.warning(options);
      warningModal.result.then(res => {
          if (res.wasClosed) {
              this.connectwiseResourcesService.deleteOrderMapping(company).pipe(
                  tap(() => {
                      company.orderMappingId = company.agreementId = company.agreementName = company.companyId =
                      company.companyName = company.includeUserNamesOnInvoice = company.isEnabled = company.prorated = null;
                  }))
                  .subscribe({
                    complete: () => {
                      this.toastrService.success('Removed successfully');
                      this.actionButtonsService.closeTask();
                    }, 
                    error: () => {
                        this.actionButtonsService.setPrimaryIsBusy(false);
                        this.toastrService.error('There is a problem with the service. We are notified & working on it. Please try again later.');
                    }
                  });

              this.form.enable();
          }
      });
    }

    connectionWizard(settingType: string = WIZARDTYPES.mapproducts) {
        const overlayConfig: NgbModalOptions = {
          backdrop: 'static',
          windowClass: 'modal-panel modal-right',
          size: 'lg'
        };
        const modalRef = this.modalService.open(ConnectionWizardComponent, overlayConfig);
        modalRef.componentInstance.settingType = settingType;

        modalRef.result.then(() => {
            if (settingType === WIZARDTYPES.mapproducts){
                this.loadCustomerAccounts();
            }
        });
    }

    ngOnDestroy(): void {
        this.customerAccountsService.setCustomerAccounts(null);
        this.customerAccountsService.setFilteredCustomerAccounts(null);
        this.customerAccountsService.setSelectedCustomer(null);
        this.actionButtonsService.closeTask();
        this.onDestroy$.next();
    }
}
