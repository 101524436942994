import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';

import {
  AccountNotificationSettingsComponent,
} from './components/account-notification-settings/account-notification-settings.component';
import {
  BackupNotificationSettingsComponent,
} from './components/backup-notification-settings/backup-notification-settings.component';
import {
  MigrationNotificationSettingsComponent,
} from './components/migration-notification-settings/migration-notification-settings.component';
import {
  NotificationSettingsComponent,
} from './components/notification-settings.component';
import { HasPermissionGuard } from './guards/has-permission.guard';

const AccountRoutePermission = 'PartnerPortalAdmin';
const MigrationsRoutePermission = 'Migrations_Full_Access';
const BackupRoutePermission = 'Cloud_Backup_Full_Access';

export const NOTIFICATIONS_ROUTES_PERMISSIONS = [AccountRoutePermission, MigrationsRoutePermission, BackupRoutePermission];

export const NOTIFICATIONS_ROUTES: Routes = [
  {
    path: 'notifications',
    redirectTo: 'notifications/account',
    pathMatch: 'prefix',
  },
  {
    path: 'notifications',
    component: NotificationSettingsComponent,
    canActivate: [HasPermissionGuard],
      data: {
        permissions: NOTIFICATIONS_ROUTES_PERMISSIONS,
        permissionAbsentRedirect: '/settings/users',
      },
    children: [
      {
        path: 'account',
        component: AccountNotificationSettingsComponent,
        canActivate: [HasPermissionGuard],
        data: {
          name: 'Account',
          type: 'secondary',
          permissions: [AccountRoutePermission],
          permissionAbsentRedirect: '/settings/notifications/migrations',
        },
      },
      {
        path: 'migrations',
        component: MigrationNotificationSettingsComponent,
        canActivate: [HasPermissionGuard],
        data: {
          name: 'Migrations',
          type: 'secondary',
          permissions: [MigrationsRoutePermission],
          permissionAbsentRedirect: '/settings/notifications/backup',
        },
      },
      {
        path: 'backup',
        component: BackupNotificationSettingsComponent,
        canActivate: [HasPermissionGuard],
        data: {
          name: 'Backup',
          type: 'secondary',
          permissions: [BackupRoutePermission],
          permissionAbsentRedirect: '/settings/notifications/account',
        },
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(NOTIFICATIONS_ROUTES)],
  exports: [RouterModule],
})
export class NotificationsRoutingModule {}
