import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from 'src/app/auth.service';
import { M365ModalResult, M365ModalStatus } from 'src/app/settings/account/authentication/modals/ms365-authentication/m365-modal-result';
import { M365StepName } from 'src/app/settings/account/authentication/modals/ms365-authentication/m365-setup-steps';
import { M365ModalService } from 'src/app/settings/account/authentication/modals/ms365-authentication/m365modal.service';
import { AuthenticationType } from 'src/app/settings/account/models/authentication-type';
import { M365ConnectionStatus } from 'src/app/settings/account/models/connection-status';
import { PartnerAuthentication } from 'src/app/settings/account/models/partner-authentication';
import { MembersService } from 'src/app/settings/users/components/members/services/members.service';

import { BaseComponent } from '../component-base/base.component';

@Component({
    selector: 'sk-unmatched-users-feedback',
    templateUrl: './unmatched-users-feedback.component.html',
})
export class UnmatchedUsersFeedbackComponent extends BaseComponent implements OnInit {
    areAllUsersMatched: boolean;

    @Input() styles: string = '';

    @Input() partnerAuthentication: PartnerAuthentication = null;
    @Input() connectionStatus: M365ConnectionStatus = null;
    @Input() groupSyncEnabled: boolean = null;
    @Input() authType: AuthenticationType = null;

    @Output() onSuccessMatchingCallback = new EventEmitter();
    @Output() areAllUsersMatchedChange = new EventEmitter<boolean>();

    areAllUsersMatched$: Observable<boolean> = of(true);
    isM365AuthType = false;

    constructor(private ms365ModalService: M365ModalService, private membersService: MembersService, private authService: AuthService) {
        super();
    }

    ngOnInit(): void {
        if (this.authType == null && this.partnerAuthentication == null){
            this.isM365AuthType = false;
            return;
        }

        if (this.authType != null && this.authType !== AuthenticationType.M365Auth) {
            this.isM365AuthType = false;
            return;
        }
        if (this.partnerAuthentication != null && this.partnerAuthentication.authenticationType !== AuthenticationType.M365Auth) {
            this.isM365AuthType = false;
            return;
        }

        this.isM365AuthType = true;

        this.membersService.areAllM365MembersMapped().subscribe(value => {
            this.areAllUsersMatched = value;
            this.areAllUsersMatchedChange.emit(value);
        });
    }

    openUsersMatchingWizard() {
        this.ms365ModalService
            .openAndHandleResult({
                partnerAuthentication: this.partnerAuthentication,
                groupsSyncEnabled: this.groupSyncEnabled,
                m365AuthConnectionStatus: this.connectionStatus,
                isM365MatchingProcess: true,
                initialStepName: M365StepName.MatchUsers,
            })
            .then(({ status }: M365ModalResult) => {
                if (status === M365ModalStatus.Success || status === M365ModalStatus.Warning) {
                    // Re-indexing is an asynchronous operation that can take some time, so let's wait a bit (3sec) and try to make a request.
                    setTimeout(() => {
                        this.membersService.areAllM365MembersMapped().pipe(takeUntil(this.destroy$)).subscribe((value) => {
                            this.onSuccessMatchingCallback.emit();
                            this.areAllUsersMatched = value;
                        })
                    }, 3000);
                }
            });
    }

    isCurrentUserAdmin = () => this.authService.isCurrentUserAdmin();
}
