import { Component, OnInit } from '@angular/core';
import { AppId, CustomerPaymentProfile, CustomerPaymentProfileConfig, CustomerPaymentProfileService, RouteResolverService } from '@skykick/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'sk-payment',
    templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit {

    private onDestroy$ = new Subject<void>();
    private customerPaymentProfile: CustomerPaymentProfile;
    config: CustomerPaymentProfileConfig = {
        acceptJSHostname: environment.apis.acceptJS,
        accountingHostname: `${this.routeResolverService.generatePipeRoute(AppId.PlatformApis)}${environment.apis.accountingApimSuffix}`
    }

    constructor(
        private routeResolverService: RouteResolverService,
        private customerPaymentProfileService: CustomerPaymentProfileService
    ) { }

    ngOnInit(): void {
        this.customerPaymentProfileService.customerPaymentProfile$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(res => {
            this.customerPaymentProfile = res;
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }
}
