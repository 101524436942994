export class FilterItem {

    constructor(displayName: string, value: string, isSelected: boolean = false, isEnabled: boolean = true) {
        this.displayName = displayName;
        this.isSelected = isSelected;
        this.value = value;
        this.isEnabled = isEnabled;
    }

    public displayName: string;
    public value: string;
    public isSelected: boolean;
    public isEnabled: boolean;
}
