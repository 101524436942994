<aside sk-panel-view [skTitle]="customer.fullName">
    <div class="sk-panel-content">
        <div class="sk-panel-scroll px-150">
            <div class="mb-150">
                <h4 class="mb-50">{{ 'settings.customers.account-info' | translate }}</h4>
                <dl class="row list-light line-height-24">
                    <dt class="col-sm-3 text-truncate">{{ 'settings.customers.email-address' | translate }}</dt>
                    <dd class="col-sm-9 text-truncate">{{ customer.email }}</dd>
                    <dt class="col-sm-3 text-truncate">{{ 'settings.customers.display-name' | translate }}</dt>
                    <dd class="col-sm-9 text-truncate">{{ customer.fullName }}</dd>
                    <dt class="col-sm-3 text-truncate">{{ 'settings.customers.permission' | translate }}</dt>
                    <dd class="col-sm-9 text-truncate">{{ getAccessLocKey(customer.access) | translate }}</dd>
                </dl>
            </div>
            <hr>
            <div class="mb-150">
                <h4 class="mb-50">{{ 'settings.customers.access.access' | translate }}</h4>
                <dl class="sk-dl line-height-24">
                    <ng-container *ngFor="let scope of customer.permissionScopes">
                        <dt>{{ scope.displayNameLocKey | translate }}</dt>
                        <ng-container *ngFor="let permissionDisplayNameLocKey of scope.permissionDisplayNameLocKeys">
                            <dd class="d-flex align-items-center">
                                <i class="material-icons md-18 icon-success mr-50">check_circle</i>
                                {{ permissionDisplayNameLocKey | translate }}
                            </dd>
                        </ng-container>
                    </ng-container>
                </dl>
            </div>
            <div class="mb-150">
                <h4 class="mb-50">{{ 'settings.common.authentication.authentication' | translate }}</h4>
                <div *ngIf="!isLoading">
                    <div class="d-inline-flex">
                        <p class="badge badge-default" [class.badge-info]="isM365Customer">
                            {{ getAuthTypeLocKey() | translate }}
                        </p>
                    </div>
                    <div class="sk-form-text" *ngIf="!isM365Customer">
                        <small [innerHtml]="'settings.common.authentication.to-change-auth-customers' 
                            | translate: { syndicationUrl: getSyndicationUrl() }">
                        </small>
                    </div>
                </div>
                <div class="d-inline-flex">
                    <sk-text-spinner *ngIf="isLoading" size="sm"></sk-text-spinner>
                </div>
            </div>
        </div>
    </div>
    <footer *ngIf="showFooter()">
        <button type="button" class="btn btn-outline-secondary" (click)="editPermissions()">
            {{ 'settings.customers.actions.edit-permissions' | translate }}
        </button>
    </footer>
</aside>
