import groupBy from 'lodash-es/groupBy';
import {
    PermissionResult,
} from 'src/app/settings/users/models/permission-result';
import {
    PermissionScope,
} from 'src/app/settings/users/models/permission-scope';

export class MembersPermissionSummaryProvider {

    private static sortingList = ["Cloud_Backup", "Security_Manager", "Cloud_Manager", "Migrations", "Billing"];

    public static getPermissionScopes(permissions: PermissionResult[]): PermissionScope[] {
        permissions.sort((a, b) => this.sortingList.indexOf(a.Scope) - this.sortingList.indexOf(b.Scope));

        return Object.entries<PermissionResult[]>(groupBy(permissions, x => x.Scope))
            .map(([permissionScope, permissionResultGroup]) => {
                return {
                    displayNameLocKey: `settings.members.access.${permissionScope.toLowerCase().split('_').join('-')}`,
                    permissionDisplayNameLocKeys: permissionResultGroup
                        .map(result => {
                            return `settings.members.permissions.${result.Name.toLowerCase().split('_').join('-')}`;
                        })
                };
            });
    }
}
