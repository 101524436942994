import { M365ConnectionStatus } from '../../../models/connection-status';
import { PartnerAuthentication } from '../../../models/partner-authentication';
import { M365StepName } from './m365-setup-steps';

export interface M365ModalSettings {
    // Required parameters
    partnerAuthentication: PartnerAuthentication;
    groupsSyncEnabled: boolean;
    m365AuthConnectionStatus: M365ConnectionStatus;
    /**
     * @summary Indicate if wizard should have only 'Users match' view
     */
    isM365MatchingProcess: boolean;
    initialStepName: M365StepName;

    handlers?: M365ModalHandlers;
}

/**
 * @summary Interface that contains necessary m365 modal handlers for different status.
 */
export interface M365ModalHandlers {
    onSuccess(syncGroupsEnabled: boolean): void;
    onGroupSyncEnabling(): void;
    onFailure(rollbacked: boolean): void;
    onRedirect(): void;
    onWarning(): void;
}

/**
 * @summary Default M365 status handlers that perform no action.
 * This is necessary if the wizard user does not want to use any handlers for different statuses.
 */
export const defaultM365Handlers: M365ModalHandlers = {
    onSuccess: (syncGroupsEnabled: boolean) => {},
    onGroupSyncEnabling: () => {},
    onFailure: (rollbacked = false) => {},
    onRedirect: () => {},
    onWarning: () => {},
};
