import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TaskType } from '../models/task-type';
import { PRORATIONSETTING } from './connectwise/core/constants/proration-setting.const';
import { ConnectwiseStateManagerService } from './connectwise/core/services/connectwise-state-manager.service';
import { BreadcrumbService } from './shared/breadcrumbs/breadcrumb.service';

@Component({
    selector: 'sk-integrations',
    templateUrl: './integrations.component.html',
    styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit, OnDestroy {
    taskType = TaskType.ConnectWiseInfo;
    isInfoButtonVisible: boolean = false;

    private onDestroy$ = new Subject<void>();

    constructor(
        private breadcrumbService: BreadcrumbService,
        private connectwiseStateManagerService: ConnectwiseStateManagerService
    ) { }

    ngOnInit(): void {
        combineLatest([
            this.breadcrumbService.breadcrumbs$,
            this.connectwiseStateManagerService.connectWisePartner$
        ]).pipe(
            takeUntil(this.onDestroy$),
            tap(([breadcrumbs, connectWisePartner]) =>
                this.isInfoButtonVisible =
                    breadcrumbs.some(breadcrumb => breadcrumb.display.toLowerCase() == 'connectwise') &&
                    !!connectWisePartner && connectWisePartner.prorationSetting !== PRORATIONSETTING.Unset)
        ).subscribe();

        this.breadcrumbService.push('Integrations', '/settings/integrations');
    }

    ngOnDestroy(): void {
        this.breadcrumbService.pop();

        this.onDestroy$.next();
    }
}
