<div class="sk-flex-table">
    <div class="sk-scroll-container">
        <div class="d-flex flex-column min-width-sm max-width-lg py-200">
            <div class="row">
                <div class="col-xl-10">
                    <h5>Description</h5>
                    <ng-container *ngIf="isPartnerActive(); else inactivePartnerDetails">
                        <p>
                            To further empower our partners' success in the Cloud, SkyKick has built an integration between its Cloud Backup 
                            application and ConnectWise Manage.
                            This integration makes it even easier to manage invoicing by pushing the necessary information for all backup projects
                            to ConnectWise Manage, where partners can manage invoices.
                            <a href="https://support.skykick.com/hc/en-us/articles/360004178353-SkyKick-Cloud-Backup-ConnectWise-Manage-integration-overview"
                                target="_blank" class="font-medium">Read more</a>
                        </p>

                        <div class="card sk-card mb-100">
                            <div class="card-header sk-card-header d-flex align-items-center">
                                <i class="material-icons icon-primary mr-50">restore</i>
                                <h4>Cloud Backup Products</h4>
                                <i class="material-icons md-18 ml-50 icon-muted" ngbPopover="The integration requires product mapping to successfully synchronize billing data." triggers="mouseenter:mouseleave">help_outline</i>
                            </div>
    
                            <div class="card-block">
                                <div *ngIf="hasMissingProducts()" class="row px-100 pb-100">
                                    <sk-feedback type="danger" class="min-width-sm max-width-lg width-full justify-content-between"
                                        [subject]="'Product mapping issue'" [message]="'Additional mapping required to sync all customers data with ConnectWise.'">
                                    </sk-feedback>
                                </div>

                                <div class="row">
                                    <div class="col-3 d-flex flex-column">
                                        <div *ngIf="!loadingProducts; else productsSpinner" class="d-flex align-items-end">
                                            <h1 class="display-2">{{ mappedProducts }}</h1>
                                            <h5 class="mx-50 mb-25">of</h5>
                                            <h1 class="display-2">{{ totalProducts }}</h1>
                                        </div>
                                        <ng-template #productsSpinner>
                                          <div class="loading-spinner-position">
                                            <div class="sk-spin-loader loader-sm"></div>
                                          </div>
                                        </ng-template>
                                        <span class="text-muted cw-text-muted">Products Mapped</span>
                                    </div>
                                    <div class="col-5 d-flex flex-column">
                                        <table class="text-start">
                                            <tbody>
                                                <tr *ngFor="let item of skykickProducts; let index = index">
                                                    <td class="icon-td">
                                                        <div *ngIf="!item.mappingError; else mappingErrorIcon">
                                                            <i *ngIf="item.productMappingId" class="material-icons md-18 icon-success">check_circle</i>
                                                            <i *ngIf="!item.productMappingId" class="material-icons md-18">cancel</i>
                                                        </div>
                                                        <ng-template #mappingErrorIcon>
                                                            <i class="material-icons md-18 icon-danger">warning</i>
                                                        </ng-template>
                                                    </td>
                                                    <td class="pb-50">{{item.name}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-4 d-flex justify-content-end align-items-start">
                                        <button class="btn btn-outline-primary" (click)="connectionWizard(WIZARDTYPES.mappedProducts)">Map Products</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card sk-card mb-100">
                          <div class="card-header sk-card-header d-flex  align-items-center">
                              <i class="material-icons icon-primary mr-50">domain</i>
                              <h4>Customers</h4>
                              <i class="material-icons md-18 ml-50 icon-muted" ngbPopover="Fully mapped and enabled customers against all active Cloud Backup subscriptions." triggers="mouseenter:mouseleave">help_outline</i>
                          </div>

                          <div class="card-block">
                              <div *ngIf="hasAgreementErrors" class="row">
                                  <sk-feedback class="mb-100 mx-25 width-full" type="danger"
                                      [subject]="'Customer mapping issue'">
                                      Resolve Customer Agreement mapping issues to resume data sync with ConnectWise.
                                      <a href="https://support.skykick.com/hc/en-us/articles/360004178353-SkyKick-Cloud-Backup-ConnectWise-Manage-integration-overview"
                                          target="_blank">Learn More</a>
                                  </sk-feedback>
                              </div>
                              <div class="row">
                                  <div class="col-3 d-flex flex-column">
                                      <div *ngIf="!loadingCustomers; else customersSpinner" class="d-flex align-items-end">
                                          <h1 class="display-2">{{ mappedCustomers }}</h1>
                                          <h5 class="mx-50 mb-25">of</h5>
                                          <h1 class="display-2">{{ totalCustomers }}</h1>
                                      </div>
                                      <ng-template #customersSpinner>
                                        <div class="loading-spinner-position">
                                          <div class="sk-spin-loader loader-sm"></div>
                                        </div>
                                      </ng-template>
                                      <span class="text-muted  cw-text-muted">Customers Mapped</span>
                                  </div>
                                  <div class="col-5 d-flex flex-column">
                                      <table class="text-start">
                                          <tbody>
                                              <tr>
                                                  <td class="icon-td">
                                                      <div *ngIf="!hasAgreementErrors; else agreementErrorIcon">
                                                          <i *ngIf="mappedCustomers !== 0" class="material-icons md-18 icon-success">check_circle</i>
                                                          <i *ngIf="mappedCustomers === 0" class="material-icons md-18">cancel</i>
                                                      </div>
                                                      <ng-template #agreementErrorIcon>
                                                          <i class="material-icons md-18 icon-danger">warning</i>
                                                      </ng-template>
                                                  </td>
                                                  <td class="pb-50">ConnectWise Agreements</td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>
                                  <div class="col-4 d-flex justify-content-end align-items-start">
                                      <button class="btn btn-outline-primary" (click)="openCustomersTab()">Map Customers</button>
                                  </div>
                              </div>
                          </div>
                          <div class="card-block">
                              <p class="card-text">
                                  Data synchronization between Cloud Backup and ConnectWise occurs daily at 3:00 AM Pacific Time.
                              </p>
                          </div>

                          <div class="card-footer d-flex align-items-center justify-content-between">
                              <div class="d-flex align-items-center">
                                  <span class="text-muted pr-50">Last Sync {{ lastSyncTime }}</span>
                              </div>
                          </div>
                        </div>

                        <div class="card sk-card mb-100">
                            <div class="card-header sk-card-header d-flex  align-items-center">
                                <i class="material-icons icon-primary mr-50">notifications_none</i>
                                <h4>Tickets</h4>
                                <i class="material-icons md-18 ml-50 icon-muted" ngbPopover="Tickets created against mapped board in case of a data push failure." triggers="mouseenter:mouseleave">help_outline</i>
                            </div>
    
                            <div class="card-block">
                                <div class="row">
                                    <div class="col-3 d-flex flex-column">
                                        <span class="text-muted cw-text-muted">ConnectWise Service Board</span>
                                    </div>
                                    <div class="col-5 d-flex flex-column">
                                        <table class="text-start">
                                            <tbody>
                                                <tr>
                                                    <td class="icon-td">
                                                        <i *ngIf="ticketMapping" class="material-icons md-18 icon-success">check_circle</i>
                                                        <i *ngIf="!ticketMapping" class="material-icons md-18">cancel</i>
                                                    </td>
                                                    <td class="pb-50">Ticket Mapping</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-4 d-flex justify-content-end align-items-start">
                                        <button class="btn btn-outline-primary" (click)="connectionWizard(WIZARDTYPES.maptickets)">Map Tickets</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #inactivePartnerDetails>
                        <p>
                            Sync Cloud Backup subscription data into ConnectWise for simplified customer invoicing.
                            <a href="https://support.skykick.com/hc/en-us/articles/360004178353-SkyKick-Cloud-Backup-ConnectWise-Manage-integration-overview"
                                target="_blank">Learn more</a>
                        </p>

                        <h5>Requirements</h5>
                        <ul class="pl-150">
                            <li>
                                An active 
                                <a href="https://www.connectwise.com/platform/business-management/psa"
                                target="_blank">ConnectWise PSA </a>
                                account.
                            </li>
                            <li>A new API member in ConnectWise PSA. For permission details review the 
                                <a href="https://support.skykick.com/hc/en-us/articles/360003975094-How-to-enable-the-Cloud-Backup-ConnectWise-integration"
                                target="_blank">Integration Setup Guide</a>
                            </li>
                        </ul>
                        
                        <h5>Daily Data Sync</h5>
                        <p>
                            Data synchronization between Cloud Backup and ConnectWise occurs daily at 3:00 AM Pacific Time.
                        </p>

                        <div class="card sk-card mb-100">
                            <div class="card-header sk-card-header d-flex justify-content-between">
                                <h4>Cloud Backup Customer Sync</h4>
                            </div>

                            <div class="card-block">
                                <h5 class="card-text">The integration will sync the following data:</h5>
                                <p class="card-text text-muted mt-150">
                                    <li>Company name</li>
                                    <li>Number of seats migrated</li>
                                    <li>SkyKick Invoice #</li>
                                    <li>Cost per seat</li>
                                    <li>Backup order creation date</li>
                                    <li>Total cost</li>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                </div>

                <div class="col-xl-4">
                </div>
            </div>
        </div>
    </div>
</div>
