import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { AuthService } from 'src/app/auth.service';
import { M365StepName } from 'src/app/settings/account/authentication/modals/ms365-authentication/m365-setup-steps';
import { M365ModalService } from 'src/app/settings/account/authentication/modals/ms365-authentication/m365modal.service';

import { AuthenticationType } from '../../../account/models/authentication-type';
import { M365ConnectionStatus } from '../../../account/models/connection-status';
import { PartnerAuthentication } from '../../../account/models/partner-authentication';

@Component({
    selector: 'sk-m365-authentication-alert',
    templateUrl: './m365-authentication-alert.component.html',
    styleUrls: ['./m365-authentication-alert.component.scss'],
})
export class M365AuthenticationAlertComponent implements OnInit, OnChanges {
    M365ConnectionStatus = M365ConnectionStatus;

    @Input() partnerAuthentication: PartnerAuthentication;
    @Input() m365AuthConnectionStatus: M365ConnectionStatus;
    @Input() groupsSyncEnabled: boolean;
    @Input() authType: AuthenticationType;

    @Output() reauthenticationRequired = new EventEmitter<boolean>();

    constructor(private m365modalService: M365ModalService, private authService: AuthService) {}

    ngOnInit(): void {
        this.emitReauthenticationStatus();
    }

    ngOnChanges(): void {
        this.emitReauthenticationStatus();
    }

    openAuthFlowModal(): void {
        this.m365modalService.openAndHandleResult({
            partnerAuthentication: this.partnerAuthentication,
            groupsSyncEnabled: this.groupsSyncEnabled,
            m365AuthConnectionStatus: this.m365AuthConnectionStatus,
            isM365MatchingProcess: false,
            initialStepName: M365StepName.GrantAccess,
        });
    }

    isReauthenticationRequired(): boolean {
        const isM365Auth = this.partnerAuthentication
            ? this.partnerAuthentication.authenticationType === AuthenticationType.M365Auth
            : this.authType === AuthenticationType.M365Auth;

        return isM365Auth && this.m365AuthConnectionStatus === M365ConnectionStatus.ReauthenticationRequired;
    }

    isCurrentUserAdmin = () => this.authService.isCurrentUserAdmin();

    private emitReauthenticationStatus() {
        this.reauthenticationRequired.emit(this.isReauthenticationRequired());
    }
}
