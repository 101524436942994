<div class="modal-header bg-white">
    <div class="d-flex flex-column">
        <div class="d-flex align-items-center">
            <h3 class="modal-title font-normal">{{'settings.O365.SETUP_M365_MODAL_TITLE' | translate }}</h3>
        </div>
    </div>

    <button type="button" class="btn-close" (click)="activeModal.dismiss()" id="closeBtn"></button>
</div>

<div class="d-flex flex-grow-1 overflow-auto">
    <!-- Steps sidebar -->
    <div class="d-flex" *ngIf="!displayUserMatchStepOnly">
        <div class="sk-panel-left sk-panel-secondary panel-xs-2">
            <div class="sk-panel-body">
                <div class="sk-panel-nav">
                    <ul class="nav sk-nav-vert flex-column">
                        <li *ngFor="let item of authenticationSteps; let i = index" class="nav-item">
                            <a id="wizardLink-{{i}}" class="nav-link d-flex align-items-center px-150"
                                [class.active]="item.active" [class.disabled]="true">
                                <i class="material-icons md-22 mr-50 icon-light d-flex"
                                    [class.icon-success]="item.completed">check_circle</i>
                                <span class="text-truncate" [translate]="'settings.O365.' + item.name"></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Steps body -->
    <div class="d-flex flex-column flex-grow-1" *ngIf="!displayUserMatchStepOnly; else matchUsersStep">
        <ng-container [ngSwitch]="currentStep.name">
            <ng-container *ngSwitchCase="M365StepName.GrantAccess">
                <ng-container [ngTemplateOutlet]="grandAccess"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="M365StepName.MatchUsers">
                <sk-match-users-step [isMFAWasEnabledOnSkyKickAuth]="this.partnerAuthentication.isMFAEnabled" [isBackButtonVisible]="!displayUserMatchStepOnly" class="h-100 w-100" [aadUrl]="aadUrl" [groupSyncEnabled]="groupAccess"
                    [mfaCheckValue]="mfaCheckValue"
                    (goBack)="navigateToStep(M365StepName.GrantAccess)"></sk-match-users-step>
            </ng-container>
        </ng-container>
    </div>
</div>

<!-- Grand Access step -->
<ng-template #grandAccess>
    <div class='modal-body'>
        <form [formGroup]="grantAccessForm" novalidate *ngIf="!isLoading; else spinner">
            <h4>{{'settings.O365.AZURE_ACTIVE_DIRECTORY_ACCESS' | translate}}</h4>
            <div class="my-150">
                <p class="font-medium mb-25" translate="settings.O365.USER_ACCESS_LABEL"></p>
                <p translate="settings.O365.USER_ACCESS_SUB_TEXT"></p>
            </div>

            <div class="my-150">
                <p class="font-medium mb-25" translate="settings.O365.GROUP_ACCESS"></p>
                <p translate="settings.O365.GROUP_ACCESS_SUB_TEXT"></p>

                <fieldset class="mb-100">
                    <div class="form-check mt-50">
                        <input type="checkbox" id="groupAccessCheck" class="form-check-input"
                            formControlName="groupAccess">
                        <label for="groupAccessCheck" class="form-check-label font-medium"
                            translate="settings.O365.GROUP_ACCESS_CHECKBOX_LABEL"></label>
                    </div>
                </fieldset>
            </div>

            <div class="my-150">
                <p class="font-medium mb-25" translate="settings.O365.TENANT_AUTH"></p>
                <p translate="settings.O365.TENANT_AUTH_SUB_TEXT"></p>
                <fieldset class="mb-100">
                    <div class="form-check mt-50">
                        <input type="checkbox" id="mfaCheck" class="form-check-input" 
                         [class.is-invalid]="grantAccessForm.get('mfaCheck').invalid && isMfaCheckTouched"
                         formControlName="mfaCheck">
                        <label for="mfaCheck" class="form-check-label font-medium"
                            translate="settings.O365.TENANT_AUTH_CHECKBOX_LABEL"></label>
                    </div>
                    <span *ngIf="grantAccessForm.get('mfaCheck').invalid && isMfaCheckTouched"
                        class="is-invalid d-flex align-items-center text-danger">
                        <i class="material-icons md-18 icon-danger pr-50">warning</i>
                        <small translate="settings.O365.CHECKBOX_CONFIRMATION_REQUIRED"></small>
                    </span>
                </fieldset>
            </div>
        </form>
    </div>

    <!-- Wizard footer -->
    <div class="modal-footer justify-content-between">
        <button id="m365grantAccess" sk-spinner-button [color]="'primary'" (click)="submitGrantAccessStep()"
            [isSpinning]="isLoading">{{ 'settings.O365.NEXT_STEP' | translate }}
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()"
            translate="COMMON.CANCEL"></button>
    </div>
</ng-template>

<ng-template #matchUsersStep>
    <sk-match-users-step [isBackButtonVisible]="!displayUserMatchStepOnly" class="h-100 w-100" [aadUrl]="aadUrl"
        [groupSyncEnabled]="groupAccess" [mfaCheckValue]="true"
        (goBack)="navigateToStep(M365StepName.GrantAccess)"></sk-match-users-step>
</ng-template>

<!-- Loader spinner -->
<ng-template #spinner>
    <sk-text-spinner class="h-100" message="{{'COMMON.LOADING' | translate}}"></sk-text-spinner>
</ng-template>
