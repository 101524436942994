export interface M365SetupStep {
    name: M365StepName;
    state: string;
    active: boolean;
    completed: boolean;
    disabled: boolean;
    next?: M365SetupStep;
    restricted: boolean;
}

export enum M365StepName {
    GrantAccess = 'GRANT_ACCESS',
    MatchUsers = 'MATCH_USERS',
}

const matchUsersStep: M365SetupStep = {
    name: M365StepName.MatchUsers,
    state: '',
    active: false,
    completed: false,
    disabled: false,
    restricted: false,
};

const grantAccessStep: M365SetupStep = {
    name: M365StepName.GrantAccess,
    state: '',
    active: true,
    completed: false,
    disabled: true,
    next: matchUsersStep,
    restricted: false,
};

export const m365authenticationSetupSteps: M365SetupStep[] = [grantAccessStep, matchUsersStep];