import { Component, OnInit } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SkyKickModalService } from '@skykick/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DistributorRelationship } from '../models/distributor-relationship';
import { DistributorsResourcesService } from '../services/distributors-resources.service';
import { AddEditDistributorRelationshipComponent } from './modals/add-edit-distributor-relationship/add-edit-distributor-relationship.component';

@Component({
    selector: 'sk-distributors',
    templateUrl: './distributors.component.html',
    styleUrls: ['./distributors.component.scss']
})
export class DistributorsComponent implements OnInit {
    private overlayConfig: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-panel', size: 'md' };
    private distributorRelationships: DistributorRelationship[];
    isLoading: boolean;
    distributorRelationships$: Observable<DistributorRelationship[]>;

    private getDistributorRelationships() {
        this.distributorRelationships$ = this.distributorsResourcesService.getDistributorRelationships().pipe(
            tap(distributorRelationships => {
                this.distributorRelationships = distributorRelationships;
                distributorRelationships.sort((x,y) => x.distributorName.toLowerCase() < y.distributorName.toLowerCase() ? -1 : 1);
            })
        );
    }

    constructor(
        private distributorsResourcesService: DistributorsResourcesService,
        private modalService: SkyKickModalService
    ) { }

    ngOnInit(): void {
        this.getDistributorRelationships();
    }

    addEditDistributor(distributorRelationship?: DistributorRelationship) {
        const modalRef =  this.modalService.open<AddEditDistributorRelationshipComponent, any>(
            AddEditDistributorRelationshipComponent,
            this.overlayConfig
        );
        modalRef.componentInstance.distributorRelationship = distributorRelationship;
        modalRef.componentInstance.distributorRelationships = this.distributorRelationships;
        modalRef.result.then(result => {
            if(result.wasClosed)
            {
                this.getDistributorRelationships();
            }
        }).catch(() => {});
    }
}
