import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router
} from '@angular/router';

import {
  AbstractUserProvider,
} from '@skykick/platform-identity-auth-auth0-angular';

export const HasPermissionGuard: CanActivateFn = (
  routeSnapshot: ActivatedRouteSnapshot
) => {
  const router = inject(Router);
  const abstractUserProvider = inject(AbstractUserProvider);
  const permissionAbsentRedirect = routeSnapshot.data['permissionAbsentRedirect'];
    const permissions = routeSnapshot.data['permissions'];
    const currentUser = abstractUserProvider.getCurrentUser();
    const hasPermission = permissions.some(permission => currentUser.hasPermission(permission));
    if (!hasPermission) {
      const redirect = !!permissionAbsentRedirect ? permissionAbsentRedirect : '/settings';
      router.navigate([redirect]);
      return false;
    }

    return hasPermission;
};
