import {
  Component,
  OnInit,
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  UrlSegment,
} from '@angular/router';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';

import { filter } from 'rxjs/operators';

import { BreadCrumb } from '../../models/breadcrumb';
import { UsersComponentContext } from './users-component-context';

@Component({
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  private commonSegments = ['settings', 'users'];
  private getBreadcrumbTitle(url: string): string {
    switch (true) {
      case /members$/.test(url): return 'settings.breadcrumbs.users';
      case /members\/add$/.test(url): return 'settings.breadcrumbs.add-member';
      case /members\/[\S]+\/edit$/.test(url): return 'settings.breadcrumbs.edit-member';
      case /customers$/.test(url): return 'settings.breadcrumbs.users';
      case /customers\/add$/.test(url): return 'settings.breadcrumbs.add-customer';
      case /customers\/[\S]+\/edit$/.test(url): return 'settings.breadcrumbs.edit-customer';
    }
  };

  breadcrumbs: BreadCrumb<{ url: string }>[];

  constructor(
    public usersComponentContext: UsersComponentContext,
    private router: Router,
    private userProvider: AbstractUserProvider) { }

  ngOnInit(): void {
    this.router
      .events
      .pipe(
          filter(evt => evt instanceof NavigationEnd)
      )
      .subscribe((evt: NavigationEnd) => this.breadcrumbs = this.populateBreadcrumbs(evt.urlAfterRedirects));

    this.breadcrumbs = this.populateBreadcrumbs(this.router.url);
  }

  showTabs(): boolean {
    const segments = this.getUrlSegmentsForUsersPage(this.router.url);
    return this.isUsersTab(segments);
  }

  showCustomersTab(): boolean {
    const user = this.userProvider.getCurrentUser();
    return user.hasPermission('Migrations_Full_Access') || user.hasPermission('Cloud_Backup_Full_Access');
  }

  populateBreadcrumbs(url: string): BreadCrumb<{ url: string }>[] {
    const segments = this.getUrlSegmentsForUsersPage(url);
    const res = segments.reduce(
      (pv, cv) => {
          pv.currentPath = `${pv.currentPath}/${cv.path}`;
          const breadcrumbTitle = this.getBreadcrumbTitle(pv.currentPath);
          
          if (breadcrumbTitle) {
              pv.segments.push({
                  title: breadcrumbTitle,
                  data: { url: pv.currentPath }
              });
          }

          return pv;
      },
      { currentPath: `/${this.commonSegments.join('/')}`, segments: [] })
      .segments;

    return res;
  }

  private isUsersTab(segments: UrlSegment[]): boolean{
    const usersTabsSegments = ['members', 'customers'];
    return segments.length === 1 && usersTabsSegments.includes(segments[0].path);
  }

  private getUrlSegmentsForUsersPage(url: string): UrlSegment[] {
    const primary = this.router.parseUrl(url).root.children.primary;
    return primary ? primary.segments.filter(x => !this.commonSegments.includes(x.path)) : []; // excluding common segments
  }
}
