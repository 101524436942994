import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';

export const DnsManagerGuard: CanActivateFn = () => {
  if (!inject(AuthService).isDnsManagerEnabled()) {
    inject(Router).navigate(['/not-found']);
    return false;
  }

  return true;
};