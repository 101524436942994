import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule, FeedbackModule } from '@skykick/core';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    ButtonsModule,
    FeedbackModule
  ],
  exports:[
    CommonModule,
    TranslateModule,
    NgbModule,
    ButtonsModule,
    FeedbackModule
  ]
})
export class SharedModule { }
