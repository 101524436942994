<sk-modal type="danger">
    <sk-modal-header (dismiss)="dismiss()">{{ 'settings.customers.remove-customer.title' | translate }}</sk-modal-header>

    <sk-modal-body>
        <p class="display-as-is">{{ 'settings.customers.remove-customer.description' | translate }}</p>
    </sk-modal-body>

    <sk-modal-footer>
        <button sk-button color="outline-secondary" (click)="close()">{{ 'settings.customers.remove-customer.cancel' | translate }}</button>
        <button id="submitBtn" sk-spinner-button [color]="'danger'" (click)="remove()" [isSpinning]="isDeleteRunning" 
            [disabled]="isDeleteRunning">{{ 'settings.customers.remove-customer.yes' | translate }}
        </button>
    </sk-modal-footer>
</sk-modal>
