import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ConnectwiseResourcesService } from '../services/connectwise-resources.service';

export const ConnectWisePartnerResolver: ResolveFn<any> = (
) => {
  const connectwiseResourcesService = inject(ConnectwiseResourcesService);

  const updatePartner = (service: ConnectwiseResourcesService, isValid: boolean, partnerInfo: any) => {
    if(partnerInfo.enabled !== isValid)
    {
      service.updatePartner({
        ...partnerInfo,
        enabled: isValid,
      }).subscribe();
    }
    
    return of({...partnerInfo, enabled: isValid})
  }
  
  return connectwiseResourcesService.getPartner().pipe(
    mergeMap((partnerRes) => {
      return connectwiseResourcesService
        .validatePermissions()
        .pipe(
          mergeMap((permissionsRes) => {
            return updatePartner(connectwiseResourcesService, permissionsRes.isValid, partnerRes);
          }),
          catchError(() => {
            // update partner data with enabled flag: false in case permissions validation failed
            return updatePartner(connectwiseResourcesService, false, partnerRes);
          })
        );
    }),
    catchError(() => {
      return of(null);
    })
  );
};
