<div class="alert-danger p-3 d-flex align-items-center" role="alert">
    <i class="material-icons md-22 mr-50">not_interested</i>
    <span>
        <strong>{{ title }}</strong>
         - 
        <span>{{ description }}</span>
        <ng-container ngIf*="contactLink">
            <br/>
            <small [innerHtml]="contactLink" class="text-muted"></small>
        </ng-container>
    </span>
</div>