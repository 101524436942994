import { CustomerStatus } from '../models';

export class CustomerStatusProvider {
  static readonly Statuses = [
    {
      key: CustomerStatus.Active,
      displayNameLocKey: 'settings.customers.table.filter.status.active',
      value: 'Active',
    },
    {
      key: CustomerStatus.Inactive,
      displayNameLocKey: 'settings.customers.table.filter.status.deactivated',
      value: 'Inactive',
    },
  ];

  static getStatusValueByKey(key: number): string {
    return this.Statuses.find((x) => x.key === key).value;
  }

  static getStatusKeyByValue(value: string): number {
    return this.Statuses.find((x) => x.value === value).key;
  }

  static getStatusDisplayNameLocKeyByValue(value: string): string {
    return this.Statuses.find((x) => x.value === value).displayNameLocKey;
  }
}
