import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
    Observable,
    throwError,
} from 'rxjs';
import {
    catchError,
    shareReplay,
} from 'rxjs/operators';

import {
    AppId,
    RouteResolverService,
} from '@skykick/core';

import { BackupsSummary } from '../models/BackupsSummary';
import { MigrationsSummary } from '../models/MigrationsSummary';
import { StatisticsObject } from '../models/StatisticsObject';

@Injectable({
    providedIn: 'root'
})
export class CardsResourcesService {

    constructor(
        private http: HttpClient,
        private routeResolverService: RouteResolverService
    ) { }

    private handleError(errorResponse: HttpErrorResponse): Observable<never> {
        if (errorResponse.error instanceof ErrorEvent) {
        console.error('Client Side Error: ', errorResponse.error.message);
        } else {
        console.error('Server Side Error: ', errorResponse);
        }
        console.log('There is a problem with the service. We are notified & working on it. Please try again later.');

        return throwError(() => errorResponse);
    }

    private getPAPIRoute(): string {
        return this.routeResolverService.generatePipeRoute(AppId.Papi);
    }

    private getBackupRoute(): string {
        return this.routeResolverService.generatePipeRoute(AppId.Backup);
    }

    getMigrationSummary(): Observable<MigrationsSummary> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.http.get<MigrationsSummary>(`${this.getPAPIRoute()}migration/summary`, { headers })
            .pipe(catchError(this.handleError));
    }

    getBackupSummary(): Observable<BackupsSummary> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.http.get<BackupsSummary>(`${this.getBackupRoute()}papi-backup/backup/summary`, { headers })
            .pipe(catchError(this.handleError));
    }

    getAlertStatistics(): Observable<StatisticsObject> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        return this.http.post<StatisticsObject>(`${this.getPAPIRoute()}common/alerts/v2/statistics`, { headers })
            .pipe(
                catchError(this.handleError),
                shareReplay()
                );
    }

    getCloudManagerCustomerCount(): Observable<any>{
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

      return this.http.get<any>(`${this.getPAPIRoute()}common/cloudmanager/reporting/customers`, { headers })
            .pipe(catchError(this.handleError));
    }

    getCloudManagerCommandCount():Observable<any>{
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

      return this.http.get<any>(`${this.getPAPIRoute()}common/cloudmanager/reporting/commands`, { headers })
            .pipe(catchError(this.handleError));
    }
}
