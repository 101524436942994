import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

import { AppId, RouteResolverService } from '@skykick/core';

@Component({
  selector: 'sk-gage',
  templateUrl: './gage.component.html',
})
export class GageComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private routeResolverService: RouteResolverService
  ) {}

  ngOnInit(): void {
    if (this.authService.validateExpirationTime()) {
      const papiUrl = this.routeResolverService.generateRatRoute(AppId.Papi);
      const redirectUrl = `${papiUrl}common/gage/sso`;

      window.location.href = redirectUrl;
    }
  }
}
