import { DatePipe } from '@angular/common';
import {
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import {
    EMPTY,
    Subject,
} from 'rxjs';
import {
    catchError,
    takeUntil,
    tap,
} from 'rxjs/operators';

import {
    NgbModal,
    NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { SkyKickDatePipe } from '@skykick/core';

import {
    ConnectionWizardComponent,
} from '../connection-wizard/connection-wizard.component';
import { PRORATIONSETTING } from '../core/constants/proration-setting.const';
import { SKYKICKPRODUCTS } from '../core/constants/skykick-products.const';
import { WIZARDTYPES } from '../core/constants/wizard-types.const';
import {
    ConnectwiseResourcesService,
} from '../core/services/connectwise-resources.service';
import {
    ConnectwiseStateManagerService,
} from '../core/services/connectwise-state-manager.service';

@Component({
    selector: '[sk-details]',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    @HostBinding('class.sk-content-primary') isActive = true;
    skykickProducts = SKYKICKPRODUCTS.map(product => { return {...product }; });
    missingProducts = new Array<string>();
    WIZARDTYPES = WIZARDTYPES;
    partnerData = null;
    ticketMapping = false;
    totalCustomers = 0;
    mappedCustomers = 0;
    totalProducts = 0;
    mappedProducts = 0;
    lastSyncTime: string;
    loadingCustomers = true;
    loadingProducts = true;
    hasAgreementErrors: boolean;
    hasProductErrors: boolean;

    constructor(
        private connectwiseResourcesService: ConnectwiseResourcesService,
        private modalService: NgbModal,
        private connectwiseStateManagerService: ConnectwiseStateManagerService,
        private router: Router) { }

    ngOnInit(): void {
        this.partnerData = this.connectwiseStateManagerService.getConnectWisePartner();
        const datePipe = new SkyKickDatePipe(new DatePipe('en-US'));
        this.lastSyncTime = datePipe.transform(this.partnerData.lastSync) ?? 'never';

        if (!this.isPartnerActive())
            return;

        this.totalProducts = this.skykickProducts.length;

        this.fetchPartnerProductMappings();
        this.fetchTicketMapping();

        this.connectwiseResourcesService.getCompanyMappings({ searchTerm: '', resultsPerPage: 10000, pageNumber: 1 })
            .pipe(
                tap(res => {
                    this.totalCustomers = res.totalRecords;
                    this.mappedCustomers = res.entities.filter(x => x.agreementId && x.companyId).length;
                    this.loadingCustomers = false;

                    this.hasAgreementErrors = res.entities.some(x => x.hasAgreementError) || res.entities.some(x => x.isEnabled === false);
                }),
                catchError(() => {
                  this.totalCustomers = 0;
                  this.mappedCustomers = 0;
                  this.loadingCustomers = false;
                  return EMPTY;
                }),
                takeUntil(this.onDestroy$)
            ).subscribe();
    }

    connectionWizard(settingType: string = WIZARDTYPES.mapproducts) {
        const overlayConfig: NgbModalOptions = {
          backdrop: 'static',
          windowClass: 'modal-panel modal-right',
          size: 'lg'
        };
        const modalRef = this.modalService.open(ConnectionWizardComponent, overlayConfig);
        modalRef.componentInstance.settingType = settingType;

        modalRef.result.then(() => {
            switch (settingType) {
                case WIZARDTYPES.mapproducts:
                    this.fetchPartnerProductMappings();
                    break;

                case WIZARDTYPES.maptickets:
                    this.fetchTicketMapping();
                    break;

                default: break;
            }
        });
    }

    fetchPartnerProductMappings(): void{
        this.connectwiseResourcesService.getProductMappings().pipe(
            tap(productMappings => {
                this.mappedProducts = productMappings.length;
                
                this.connectwiseResourcesService.getMissingProducts().pipe(
                    tap(missingProducts => {
                        this.missingProducts = missingProducts;
                        this.fillSkyKickProducts(this.skykickProducts, productMappings);
                        this.loadingProducts = false;                        
                    }),
                    takeUntil(this.onDestroy$)
                ).subscribe();
            }),
            takeUntil(this.onDestroy$)
        ).subscribe();
    }

    fetchTicketMapping(): void {
        this.connectwiseResourcesService.getTicketMapping()
            .pipe(
                tap(res => { this.ticketMapping = res; }),
                takeUntil(this.onDestroy$)
            ).subscribe();
    }

    fillSkyKickProducts(skykickProducts: Array<any>, productMappings: Array<any>) {
        for (let productIndex = 0; productIndex < SKYKICKPRODUCTS.length; productIndex++) {
            let productMapping = productMappings.find(({source}) => source === SKYKICKPRODUCTS[productIndex].source);
            let hasError = this.missingProducts.includes(SKYKICKPRODUCTS[productIndex].name);

            skykickProducts[productIndex] = { ...SKYKICKPRODUCTS[productIndex], ...productMapping, mappingError: hasError }
        }
    }

    hasMissingProducts():boolean{
        return !this.loadingProducts && this.missingProducts.length > 0;
    }

    prorationSettingEnabled(): boolean {
        return this.partnerData?.prorationSetting !== PRORATIONSETTING.Unset;
    }

    isPartnerActive(): boolean {
        return this.partnerData && this.partnerData.prorationSetting !== PRORATIONSETTING.Unset;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    openCustomersTab() {
      this.router.navigate(['/settings/integrations/connectwise/customers']);
    }
}
