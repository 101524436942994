import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AbstractUserProvider, ISkyKickPartnerPortalUser } from '@skykick/platform-identity-auth-auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountResourcesService } from 'src/app/settings/account/services/account-resources.service';
import { TIMEZONES } from '../constants/timezone.constant';
import { CompanyInfo } from '../models/company-info';

@Component({
    selector: 'sk-company-profile',
    templateUrl: './company-profile.component.html',
    styleUrls: ['./company-profile.component.scss'],
    providers: [NgbPopoverConfig]
})
export class CompanyProfileComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    TIMEZONES = TIMEZONES;
    isLoading: boolean;
    isBusy: boolean;
    companyInfoFormGroup: UntypedFormGroup;
    countries$: Observable<any>;
    states$: Observable<any>;
    supportedO365Countries$: Observable<any>;
    companyInfo: CompanyInfo;
    usCountryCode = 772370000;
    user: ISkyKickPartnerPortalUser;
    isAdmin = false;

    private isBusyOn() {
        this.isBusy = true;
        this.companyInfoFormGroup.disable();
    }
    private isBusyOff() {
        this.isBusy = false;
        this.companyInfoFormGroup.enable();
        this.companyInfoFormGroup.controls['name'].disable();
    }

    constructor(
        private toastrService: ToastrService,
        private translate: TranslateService,
        private accountResourcesService: AccountResourcesService,
        private formBuilder: UntypedFormBuilder,
        private userService: AbstractUserProvider,
        ngbPopoverConfig: NgbPopoverConfig,
    ) {
        ngbPopoverConfig.container = 'body';
        ngbPopoverConfig.triggers = 'mouseenter:mouseleave'
    }

    ngOnInit(): void {
        this.companyInfoFormGroup = this.formBuilder.group({
            mpnId: [{value: null}, [Validators.required]],
            partnerContactEmail: [null, [Validators.required, Validators.pattern("^([\\w\\.\\'&]+@([\\w-]+\\.)+[\\w-]{2,20})?$") ]],
            // defaultTimeZone: [null],
            selectedO365CountryId: [null, [Validators.required]],
            name: [{value: null, disabled: true}, [Validators.required]],
            address: [null, [Validators.required]],
            address2: [null],
            city: [null],
            selectedStateId: [null],
            postalCode: [null, [Validators.pattern("^[a-zA-Z0-9][a-zA-Z0-9\\- ]{0,10}[a-zA-Z0-9]$")]],
            selectedCountryId: [null, [Validators.required]],
            phone: [null, [Validators.required]]
        });

        // this.companyInfoFormGroup = this.formBuilder.group({
        //     accountRepEmail: [null],
        //     accountRepName: [null],
        //     accountRepPhone: [null],
        // });

        this.isLoading = true;
        this.accountResourcesService.getCompanyInfo().pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(res => {
            this.isLoading = false;
            if (res) {
                this.companyInfo = res;
                this.companyInfoFormGroup.patchValue(res);
            }
        });

        this.countries$ = this.accountResourcesService.getCountriesList();
        this.states$ = this.accountResourcesService.getStateList(this.usCountryCode);
        this.supportedO365Countries$ = this.accountResourcesService.getSupportedO365Countries();

        this.user = this.userService.getCurrentUser();
        this.isAdmin = this.user.hasPermission('PartnerPortalAdmin');
    }

    isFieldValid(field: string, type?: string) {
        if (type) {
            return this.companyInfoFormGroup.get(field).hasError(type) && !this.companyInfoFormGroup.get(field).pristine && !this.companyInfoFormGroup.disabled;
        }
        return !this.companyInfoFormGroup.get(field).valid && !this.companyInfoFormGroup.get(field).pristine && !this.companyInfoFormGroup.disabled;
    }

    displayFieldCss(field: string) {
        return {
            'is-invalid': this.isFieldValid(field)
        };
    }

    update(): void {
        if (this.companyInfoFormGroup.valid) {
              // FormGroup.value property returns all form fields except disabled ones. 
              let formValue = this.companyInfoFormGroup.value;
              let payload = {
                ...formValue,
                name: this.companyInfoFormGroup.controls['name'].value, // include disabled field value
                websiteUserName: this.user.email
              }
              this.isBusyOn();
              this.accountResourcesService.submitCompanyInfo(payload).subscribe({
                next: res => {
                  if (res === null) {
                      this.toastrService.success(this.translate.instant('COMMON.SUCCESS'));
                      this.isBusyOff();
                      this.companyInfo = this.companyInfoFormGroup.value;
                  } else {
                      this.toastrService.error(this.translate.instant('settings.common.error.request-issue'));
                      this.isBusyOff();
                  }
                }, 
                error: err => {
                  this.isBusyOff();

                  if (err && err.message.toLowerCase().indexOf('too many requests') !== -1) {
                      this.companyInfoFormGroup.controls['mpnId'].setErrors({'mpnThrottlingMessage': true});
                  }
                  if (err && err.error.message.toLowerCase().indexOf('mpnid validation error') !== -1) {
                      this.companyInfoFormGroup.controls['mpnId'].setErrors({'mpnInvalid': true});
                  }

                  this.toastrService.error(this.translate.instant('settings.common.error.request-issue'));
                }
            });
        }
    }

    cancel(): void {
        this.companyInfoFormGroup.patchValue(this.companyInfo);
        this.companyInfoFormGroup.markAsPristine();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
