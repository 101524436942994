import { Injectable } from '@angular/core';
import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class PasswordValidatorService {

  private hasPatternErrors: boolean = true;

  passwordPatternsMatch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const passwordPatterns: [string, RegExp][] = [
        ['hasNumber', /\d/],
        ['hasLowerCase', /[a-z]/],
        ['hasUpperCase', /[A-Z]/],
        ['hasSpecialCharacters', /[*!@#$%^&()\[\]{}:";'<>,.?\/~`_+=|\\]/]
      ];

      const errors: ValidationErrors = {};
      passwordPatterns.forEach(pattern => {
        const valid = pattern[1].test(control.value);
        if (!valid){
          errors[pattern[0]] = true;
        }
      });

      if (Object.keys(errors).length > 1){
        this.hasPatternErrors = true;
        return errors;
      }

      this.hasPatternErrors = false;
      return null;
    };
  }

  hasPasswordPatternErrors(): boolean {
    return this.hasPatternErrors;
  }

  isPasswordRuleValid(passwordControl: AbstractControl, ruleName: string): boolean {
    return passwordControl.value && !passwordControl.hasError(ruleName);
  }
}


