import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SkyKickModalService } from '@skykick/core';
import { ISkyKickPartnerPortalUser, ISkyKickPartnerPortalUserImpersonationDetailsProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PapiHttpService } from '../mpn-registration/services/papi-http.service';
import { TermsAndConditionsModalComponent } from './terms-and-conditions-modal/terms-and-conditions-modal.component';

@Injectable({ providedIn: 'root' })
export class TermsAndConditionsService {
    private modalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
    };

    constructor(
        private papi: PapiHttpService,
        private skModalService: SkyKickModalService,
        private impersonationProvider: ISkyKickPartnerPortalUserImpersonationDetailsProvider
    ) {}

    checkIfAccepted(user: ISkyKickPartnerPortalUser): Observable<any> {
        if (user.isCustomer || this.isImpersonating(user)) {
            return EMPTY;
        }

        return this.papi.checkTermsAndConditionsAccepted().pipe(
            map((isTermsAndConditionsAccepted) => {
                if (!isTermsAndConditionsAccepted) {
                    this.skModalService.open(TermsAndConditionsModalComponent, this.modalOptions);
                }
            })
        );
    }

    private isImpersonating = (user: ISkyKickPartnerPortalUser) => this.impersonationProvider.getImpersonationDetails(user).isImpersonation;
}
