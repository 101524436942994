import {
  PermissionScope,
} from 'src/app/settings/users/models/permission-scope';

import { CustomersAccessProvider } from './customers.access.provider';
import { CustomersPermissionProvider } from './customers.permission.provider';

export class CustomersPermissionSummaryProvider {
  public static getPermissionScopes(accessRight: string, accessPermission: string): PermissionScope[] {
    return [{
      displayNameLocKey: CustomersAccessProvider.getAccessDisplayNameLocKeyByValue(accessRight),
      permissionDisplayNameLocKeys: [ CustomersPermissionProvider.getPermissionDisplayNameLocKeyByValue(accessPermission) ]
    }];
  }
}
