import { inject } from '@angular/core';
import {
  CanActivateFn,
  Router
} from '@angular/router';
import { AppId, RouteResolverService } from '@skykick/core';
import { AuthService } from '../auth.service';

export const AuthGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const routeResolverService = inject(RouteResolverService);
  let isCustomerUser = authService.isCustomerUser();
    if (isCustomerUser) {
      if (authService.hasRole('Cloud_Backup_Full_Access')) {
        window.location.href = routeResolverService.generateRatRoute(
          AppId.Backup
        );
      } else if (authService.hasRole('Migrations_Full_Access')) {
        window.location.href = routeResolverService.generateRatRoute(
          AppId.Migrate
        );
      } else if (authService.hasRole('manager_view')) {
        window.location.href = routeResolverService.generateRatRoute(
          AppId.Manager
        );
      }
      inject(Router).navigate(['/login']);
      return false;
    }

    //If you are not a customer user you are allowed through
    return true;
};
