import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import {
    Observable,
    throwError,
} from 'rxjs';
import {
    catchError,
    switchMap,
    tap,
} from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorFactory } from 'src/app/shared/factories/errors.factory';
import { Member } from '../models/member';
import { MemberStatus } from '../models/member-status';
import { MembersStatusProvider } from './members.status.provider';

@Injectable({
    providedIn: 'root'
})
export class MemberActionEffectService {
    constructor(private translateService: TranslateService, private toastrService: ToastrService, private errorFactory: ErrorFactory) {}

    deactivate(
        deactivateObservable: Observable<boolean>,
        member: Member,
        successCallback: (member: Member) => void = () => { },
        errorCallback: (member: Member) => void = () => { })
        : Observable<any> {
        return deactivateObservable.pipe(
            switchMap(result => {
                if (result) {
                    member.status = MembersStatusProvider.getStatusValueByKey(MemberStatus.Inactive);
                    return this.translateService.get('settings.members.actions.deactivate-success').pipe(
                        tap((successText: string) => {
                            this.toastrService.success(successText);
                            successCallback(member);
                        })
                    );
                }
            }),
            catchError((response: HttpErrorResponse) =>
                this.errorFactory.getMessage(response?.error).pipe(
                    tap((errorText: string) => {
                        this.toastrService.error(errorText);
                        errorCallback(member);
                        throwError(() => response);
                    })
                )
            )
        );
    }

    resetPassword(
        resetPasswordObservable: Observable<boolean>,
        member: Member,
        successCallback: (member: Member) => void = () => {},
        errorCallback: (member: Member) => void = () => {})
        : Observable<any> {
        return resetPasswordObservable.pipe(
            switchMap(result => {
                if (result) {
                    return this.translateService.get('settings.members.actions.reset-password-success').pipe(
                        tap((successText: string) => {
                            this.toastrService.success(successText);
                            successCallback(member);
                        })
                    );
                }
            }),
            catchError((response: HttpErrorResponse) =>
                this.errorFactory.getMessage(response?.error).pipe(
                    tap((errorText: string) => {
                        this.toastrService.error(errorText);
                        errorCallback(member);
                        throwError(() => response);
                    })
                )
            )
        );
    }
}
