import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthenticationMethodResourcesService } from 'src/app/settings/account/services/authentication-method-resources.service';
import { ErrorFactory } from 'src/app/shared/factories/errors.factory';

@Component({
  selector: 'sk-reset-mfa-modal',
  templateUrl: './reset-mfa-modal.component.html',
})
export class ResetMfaModalComponent implements OnInit {

  memberEmail: string;
  isResetRunning = false;
  mfaReset = false;

  constructor(
      private modal: NgbActiveModal,
      private authenticationMethodResourcesService: AuthenticationMethodResourcesService,
      private translateService: TranslateService,
      private toastrService: ToastrService,
      private errorFactory: ErrorFactory) { }

  ngOnInit(): void {
  }

  reset(): void {
      if (this.isResetRunning) {
        return;
      }

      this.isResetRunning = true;

      const payload = {
          userEmail: this.memberEmail
      };
      this.authenticationMethodResourcesService.resetMfa(payload).pipe(
          switchMap(result => {
              if (result === null){
                  this.mfaReset = true;
                  return this.translateService.get('settings.members.reset-mfa.reset-success').pipe(
                      tap((successText: string) => this.toastrService.success(successText))
                  );
              }
          }),
          catchError((response: HttpErrorResponse) => this.errorFactory.getMessage(response?.error).pipe(
              tap((errorText: string) => this.toastrService.error(errorText))
          ))
      ).subscribe(() => {
          this.isResetRunning = false;
          this.modal.close(this.mfaReset);
      });
  }

  dismiss(): void {
      this.modal.dismiss();
  }

  close(): void {
      this.modal.close();
  }
}
