import { TranslateService } from "@ngx-translate/core";
import { AbstractUserProvider } from "@skykick/platform-identity-auth-auth0-angular";
import { CookieService } from "ngx-cookie-service";
import { UserProfileService } from "./settings/user-profile/services/user-profile.service";

export function LanguageLoader(
    translate: TranslateService,
    cookieService: CookieService,
    userService: AbstractUserProvider,
    userProfileService: UserProfileService
  ): () => Promise<void> {
    let promise = new Promise<void>((resolve) => {
      let currentUser = null;

      // Try to get info about current user.
      // We may get an exception if the user is not logged in.
      try {
        currentUser = userService.getCurrentUser();
      }
      catch(err) {
        console.info('[LanguageLoader] An error occurred while retrieving the current user', err);
      }

      const cookieLanguageisSetValue = cookieService.get('skLanguageCodeIsSet') || "false";
      const cookieLanguageisSet = cookieLanguageisSetValue == "true";

      if(currentUser && !cookieLanguageisSet) {
        const cookieLanguage = cookieService.get('skLanguageCode') || 'en';
        const userLanguage = currentUser.preferredCultureCode;

        // populate preferred language when no language is specified for user in db
        if(!userLanguage) {
          userProfileService.changeCulture(currentUser.email, cookieLanguage).subscribe(
            () => {}
          )
        }

        // update language in cookie when it is specified for user in db and is different from cookie value
        else if(userLanguage !== cookieLanguage)
        {
          cookieService.set('skLanguageCode', userLanguage, 9999, "/", ".skykick.com", false, "Lax");
        }
        cookieService.set('skLanguageCodeIsSet', "true", 9999, "/", ".skykick.com", false, "Lax");
      }

      const cookieLang = cookieService.get('skLanguageCode');
      const browserLang = cookieLang || translate.getBrowserLang();
      const lang = new RegExp(/en|ja|sq|de/).exec(browserLang) ? browserLang : 'en';
      translate.use(lang);
      resolve();
    });
    return async () => promise;
  }
