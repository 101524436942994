import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SkyKickModalOptions, SkyKickModalService, TaskManagerService } from '@skykick/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Subject, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TaskType } from 'src/app/settings/models/task-type';
import { WizardStepComponent } from '../../core/models/WizardStepComponent';
import { ActionButtonsService } from '../../core/services/action-buttons.service';
import { ConnectwiseResourcesService } from '../../core/services/connectwise-resources.service';
import { ConnectwiseStateManagerService } from '../../core/services/connectwise-state-manager.service';
import { PartnerResourcesService } from '../../core/services/partner-resources.service';

@Component({
    selector: 'sk-authenticate',
    templateUrl: './authenticate.component.html',
    styleUrls: [ './authenticate.component.scss' ]
})
export class AuthenticateComponent implements WizardStepComponent, OnInit {
    private onDestroy$ = new Subject<void>();
    private errorOptions: SkyKickModalOptions = {
        body: `<p class="mb-200">There was a problem processing your request</p>`,
        title: 'Error',
        btnLabel: 'OK'
    };
    @HostBinding('class') class='d-flex flex-column flex-grow-1';
    @Input() data: any;
    @Input() settingType: string;
    authenticateForm: UntypedFormGroup;
    showTandC: boolean;
    isLoadingCheckingAccess: boolean;
    isVerifyingCheckingAccess: boolean;
    verificationCheckingAccessSuccessful: boolean;
    requiresValidation: boolean = true;
    isPermissionsValid: boolean = true;
    credentials: any;
    errors: Array<string> = [];
    partnerData = this.connectwiseStateManagerService.getConnectWisePartner();
    isBusy: boolean = false;

    private validateAllFormFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsDirty({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    private showErrorInfo(res) {
        if (res) {
            if (res.status === 400 && res.error?.startsWith('HTTP 401') === true) {
                this.authenticateForm.setErrors({ credsInvalid: true});
            } else if (res.status === 404 || res.status === 400) {
                if (res.error?.includes('Invalid connectWise URL / companyName provided')) {
                    this.authenticateForm.setErrors({ urlOrCompanyInvalid: true});
                } else {
                    this.authenticateForm.setErrors({ urlInvalid: true});
                }
            } else {
                this.skyKickModalService.error(this.errorOptions);
            }
        }
    }

    private createConnectWisePartner(partnerName: string) {
        const payload = { partnerId: this.abstractUserProvider.getCurrentUser().partnerId, name: partnerName, enabled: true };
        this.connectwiseResourcesService.createPartner(payload).pipe(
            takeUntil(this.onDestroy$),
            switchMap(() => this.connectwiseResourcesService.getPartner().pipe(
                tap(res => {
                    this.connectwiseStateManagerService.setConnectWisePartner(res);
                    this.partnerData = res;
                }),
                catchError(() => {
                    this.skyKickModalService.error(this.errorOptions);
                    return EMPTY;
                })
            )),
            switchMap(() => this.connectwiseResourcesService.saveCredentials(this.credentials).pipe(
                catchError(() => {
                    this.skyKickModalService.error(this.errorOptions);
                    return EMPTY;
                })
            )),
            catchError(() => {
                this.skyKickModalService.error(this.errorOptions);
                return EMPTY;
            }),
            finalize(() => this.isBusy = false)
        ).subscribe(() => {
            this.connectwiseStateManagerService.markAsCompleteWizardNav(this.data.type);
            this.actionButtonsService.submitWizardStep(this.data);
        });
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        public activeModal: NgbActiveModal,
        private actionButtonsService: ActionButtonsService,
        private connectwiseResourcesService: ConnectwiseResourcesService,
        private toastrService: ToastrService,
        private connectwiseStateManagerService: ConnectwiseStateManagerService,
        private abstractUserProvider: AbstractUserProvider,
        private skyKickModalService: SkyKickModalService,
        private taskManagerService: TaskManagerService,
        private partnerResourcesService: PartnerResourcesService
    ) {}

    ngOnInit(): void {
        this.authenticateForm = this.formBuilder.group({
            companyName: ['', [Validators.required]],
            connectWiseURL: ['', [Validators.required]],
            partnerId: this.abstractUserProvider.getCurrentUser().partnerId,
            privateKey: ['', [Validators.required]],
            publicKey: ['', [Validators.required]],
            tandc: ['', Validators.requiredTrue]
        });

        this.authenticateForm.valueChanges.pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.isPermissionsValid = true;
                this.verificationCheckingAccessSuccessful = false;
                this.requiresValidation = true;
            })

        if (this.partnerData) {
            //if CW Partner exists
            this.isLoadingCheckingAccess = true;
            this.connectwiseResourcesService.getCredentials().pipe(
                takeUntil(this.onDestroy$),
                tap(res => {
                    this.credentials = res;
                    res.privateKey = '';
                    this.authenticateForm.patchValue(res);
                    this.isLoadingCheckingAccess = false;
                    this.connectwiseStateManagerService.markAsCompleteWizardNav(this.data.type);
                }),
                catchError(() => {
                    this.isLoadingCheckingAccess = false;
                    return EMPTY;
                })
            ).subscribe();
        }
    }

    isFieldValid(field: string, type?: string) {
        if (type) {
            return this.authenticateForm.get(field).hasError(type) && !this.authenticateForm.get(field).pristine && !this.authenticateForm.disabled;
        }
        return !this.authenticateForm.get(field).valid && !this.authenticateForm.get(field).pristine && !this.authenticateForm.disabled;
    }

    displayFieldCss(field: string) {
        return {
            'is-invalid': this.isFieldValid(field)
        };
    }

    validate() {
        this.errors.length = 0;
        this.isPermissionsValid = true;
        if (this.authenticateForm.valid) {
            let payload = this.authenticateForm.value;
            this.isVerifyingCheckingAccess = true;
            this.verificationCheckingAccessSuccessful = false;

            this.connectwiseResourcesService.validateCredentials(payload).pipe(
                switchMap(() => this.connectwiseResourcesService.validatePermissions(payload).pipe(
                    tap(res => {
                        if(!res.isValid)
                        {
                            this.isPermissionsValid = false;
                            this.isVerifyingCheckingAccess = false;
                        }
                        else
                        {
                            this.isPermissionsValid = true;
                            this.credentials = payload;
                            this.isVerifyingCheckingAccess = false;
                            this.verificationCheckingAccessSuccessful = true;
                            this.requiresValidation = false;
                            this.toastrService.success('Validation successful');
                        }
                    }),
                    catchError(() => {
                        this.isPermissionsValid = false;
                        this.isVerifyingCheckingAccess = false;
                        return EMPTY;
                    }),
                )),
                takeUntil(this.onDestroy$),
                catchError(error => {
                    this.showErrorInfo(error);
                    this.isVerifyingCheckingAccess = false;
                    return throwError(() => error);
                })
            ).subscribe();
        } else {
            this.validateAllFormFields(this.authenticateForm);
        }
    }

    updateConnectWisePartner() {
        this.isBusy = true;
        const payload = this.authenticateForm.value;
        this.connectwiseResourcesService.updateCredentials(payload).pipe(
            switchMap(() => {
                if(!this.partnerData.enabled) {
                    return this.connectwiseResourcesService.updatePartner({...this.partnerData, enabled: true}).pipe(
                        tap(() => {
                            const resultObject = {
                                ...this.partnerData, 
                                enabled: true
                            }
                            this.connectwiseStateManagerService.setConnectWisePartner(resultObject);
                            this.partnerData = resultObject;
                        }),
                        catchError(() => {
                            this.skyKickModalService.error(this.errorOptions);
                            return EMPTY;
                        })
                    )
                }
                
                return of(null);
            }),
            tap(() => {
                this.toastrService.success('Update successful');
            }),
            takeUntil(this.onDestroy$),
            catchError(() => {
                this.skyKickModalService.error(this.errorOptions);
                return EMPTY;
            }),
            finalize(() => this.isBusy = false)
        ).subscribe();
    }

    submit() {
        this.isBusy = true;
        if (!this.partnerData) {
            this.partnerResourcesService.getPartnerInfo()
                .subscribe(partnerInfo => {
                    this.createConnectWisePartner(partnerInfo.name);
                });
        } else {
            const payload = this.credentials;
            this.connectwiseResourcesService.updateCredentials(payload).pipe(
                takeUntil(this.onDestroy$),
                tap(() => {
                    this.toastrService.success('Update successful');
                }),
                catchError(() => {
                    this.skyKickModalService.error(this.errorOptions);
                    return EMPTY;
                }),
                finalize(() => this.isBusy = false)
            ).subscribe(() => {
                this.connectwiseStateManagerService.markAsCompleteWizardNav(this.data.type);
                this.actionButtonsService.submitWizardStep(this.data);
            });
        }
        this.taskManagerService.abortTask(TaskType.ConnectWiseInfo);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

}