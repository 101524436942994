import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { WIZARDTYPES } from 'src/app/settings/integrations/connectwise/core/constants/wizard-types.const';
import { WIZARDNAVS } from '../core/constants/wizard-navs.const';
import { WizardStepsDirective } from '../core/directives/wizard-steps.directive';
import { WizardNavigationItem } from '../core/models/WizardNavigationItem';
import { WizardStepComponent } from '../core/models/WizardStepComponent';
import { WizardStepItem } from '../core/models/WizardStepItem';
import { ActionButtonsService } from '../core/services/action-buttons.service';
import { ConnectwiseStateManagerService } from '../core/services/connectwise-state-manager.service';
import { WizardStepsService } from '../core/services/wizard-steps.service';

@Component({
    selector: 'sk-connection-wizard',
    templateUrl: './connection-wizard.component.html',
    styleUrls: ['./connection-wizard.component.scss'],
    encapsulation : ViewEncapsulation.None
})
export class ConnectionWizardComponent implements OnInit, OnDestroy {
    @Input() settingType: string;
    @HostBinding('class') class='modal-content';
    subscription: Subscription;
    WIZARDNAVS = WIZARDNAVS;
    wizardNav: WizardNavigationItem;
    wizardStepItem: WizardStepItem;

    //dynamic controller loader
    @ViewChild(WizardStepsDirective, {static: true}) skWizardSteps!: WizardStepsDirective;
    wizardStepItems: WizardStepItem[];

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private wizardStepsService: WizardStepsService,
        private actionButtonsService: ActionButtonsService,
        private connectwiseStateManagerService: ConnectwiseStateManagerService
    ) { }

    loadWizardComponent(wizardStepItem: WizardStepItem) {
        const viewContainerRef = this.skWizardSteps.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent<WizardStepComponent>(wizardStepItem.component);
        componentRef.instance.data = wizardStepItem.data;
        componentRef.instance.settingType = this.settingType;
    }

    ngOnInit(): void {
        this.wizardStepItems = this.wizardStepsService.getWizardSteps();
        this.wizardStepItem = this.wizardStepItems.find(item => item.data.type === this.settingType);
        if (this.wizardStepItem) {
            this.loadWizardComponent(this.wizardStepItem);
        } else {
            this.WIZARDNAVS.forEach(item => item.active = false);
            this.connectwiseStateManagerService.markAsActiveWizardNav(WIZARDTYPES.authenticate);
            this.loadWizardComponent(this.wizardStepItems[0]);
        }

        this.subscription = this.actionButtonsService.onSubmitWizardStep((data) => this.submit(data));
    }

    submit(data: any) {
        if (this.settingType === 'wizard') {
            switch (data.type) {
                case WIZARDTYPES.authenticate: {
                    this.connectwiseStateManagerService.markAsActiveWizardNav(WIZARDTYPES.mapproducts);
                    const nextStepItem = this.wizardStepItems.find(item => item.data.type === WIZARDTYPES.mapproducts);
                    this.loadWizardComponent(nextStepItem);
                    break;
                }
                case WIZARDTYPES.mapproducts: {
                    this.connectwiseStateManagerService.markAsActiveWizardNav(WIZARDTYPES.maptickets);
                    const nextStepItem = this.wizardStepItems.find(item => item.data.type === WIZARDTYPES.maptickets);
                    this.loadWizardComponent(nextStepItem);
                    break;
                }
                case WIZARDTYPES.maptickets: {
                    this.connectwiseStateManagerService.markAsCompleteWizardNav(WIZARDTYPES.maptickets);
                    this.activeModal.close();
                    this.router.navigate(['settings', 'integrations', 'connectwise','customers']);
                    break;
                }
                // case WIZARDTYPES.syncsettings: {
                //     this.connectwiseStateManagerService.markAsCompleteWizardNav(WIZARDTYPES.syncsettings);
                //     this.activeModal.close();
                //     this.router.navigate(['settings', 'integrations', 'connectwise','customers']);
                //     break;
                // }
            }
    
        } else {
            this.activeModal.close();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
