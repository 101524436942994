import { Component, OnInit } from '@angular/core';
import { SkyKickProductPage, TaskManagerService } from '@skykick/core';
import { ProductType } from '../../models/ProductTypeEnum';

@Component({
  selector: 'sk-migration-notification-settings',
  templateUrl: './migration-notification-settings.component.html',
  styleUrls: ['./migration-notification-settings.component.scss'],
})
export class MigrationNotificationSettingsComponent
  extends SkyKickProductPage
  implements OnInit
{
  ProductType = ProductType;

  constructor(taskManagerService: TaskManagerService) {
    super(taskManagerService);
  }

  ngOnInit(): void {}
}
