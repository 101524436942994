import { CustomerValidationStatus } from '../models';

export class CustomerValidationStatusProvider {
  static readonly Statuses = [
    {
      key: CustomerValidationStatus.Valid,
      displayMessage: '',
      value: 'Valid',
    },
    {
      key: CustomerValidationStatus.HasActiveOrder,
      displayMessage: 'settings.customer.add.errors.has-active-order',
      value: 'HasActiveOrder',
    },
    {
      key: CustomerValidationStatus.AlreadyAssociatedToProject,
      displayMessage: 'settings.customer.add.errors.already-associated-to-project',
      value: 'AlreadyAssociatedToProject',
    },
    {
      key: CustomerValidationStatus.BoundToActiveOrder,
      displayMessage: 'settings.customer.add.errors.bound-to-active-order',
      value: 'BoundToActiveOrder',
    },
    {
      key: CustomerValidationStatus.ContactDoesNotExist,
      displayMessage: 'settings.customer.add.errors.contact-does-not-exist',
      value: 'ContactDoesNotExist',
    },
    {
      key: CustomerValidationStatus.ContactCanNotBeModified,
      displayMessage: 'settings.customer.add.errors.contact-can-not-be-modified',
      value: 'ContactCanNotBeModified',
    },
    {
      key: CustomerValidationStatus.OrderNotFound,
      displayMessage: 'settings.customer.add.errors.order-not-found',
      value: 'OrderNotFound',
    },
    {
      key: CustomerValidationStatus.InvalidOrderType,
      displayMessage: 'settings.customer.add.errors.invalid-order-type',
      value: 'InvalidOrderType',
    },
  ];

  static getStatusValueByKey(key: number): string {
    return this.Statuses.find((x) => x.key === key).value;
  }

  static getStatusKeyByValue(value: string): number {
    return this.Statuses.find((x) => x.value === value).key;
  }

  static getStatusDisplayMessageByKey(key: number): string {
    return this.Statuses.find((x) => x.key === key).displayMessage;
  }
}
