import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { PapiHttpService } from 'src/app/mpn-registration/services/papi-http.service';

@Component({
    selector: 'sk-terms-and-conditions-modal',
    templateUrl: './terms-and-conditions-modal.component.html',
    styleUrls: ['./terms-and-conditions-modal.component.scss'],
})
export class TermsAndConditionsModalComponent {
    constructor(private papi: PapiHttpService, private modal: NgbActiveModal) {}

    accept(): void {
        this.papi
            .acceptTermsAndConditions()
            .pipe(first())
            .subscribe({
                next: () => {
                    this.modal.close();
                },
                error: (error) => {
                    console.error('Something happened during accepting SkyKick terms and conditions.', error);
                    this.modal.close();
                },
            });
    }
}
