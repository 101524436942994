import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppId, RouteResolverService } from '@skykick/core';
import { catchError, Observable, throwError } from 'rxjs';
import { WhiteLabelSettings } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class WhiteLabelDataService {
    private handleError(errorResponse: HttpErrorResponse) {
        if (errorResponse.error instanceof ErrorEvent) {
            console.error('Client Side Error: ', errorResponse.error.message);
        } else {
            console.error('Server Side Error: ', errorResponse);
        }
        console.log('There is a problem with the service. We are notified & working on it. Please try again later.');
        return throwError(() => errorResponse);
    }

    constructor(
        private http: HttpClient,
        private routeResolverService: RouteResolverService
    ) { }

    /**
     * @returns WhiteLabelSettings or null if no WhiteLabelSettings are found.
     */
    public getWhiteLabelSettings(): Observable<WhiteLabelSettings> {
        return this.http.get<any>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}partnersettings/backup`)
            .pipe(catchError(this.handleError));
    }
}
