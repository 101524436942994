import { Injectable } from '@angular/core';

import { M365User } from '../models/m365-user';
import { M365UserResult } from '../models/m365-user-result';

@Injectable({
    providedIn: 'root'
})
export class M365UserConverter {
    public convertToM365User(m365UserResult: M365UserResult): M365User{
        const m365User: M365User = {
            objectId: m365UserResult.ObjectId,
            displayName: m365UserResult.DisplayName,
            givenName: m365UserResult.GivenName,
            mail: m365UserResult.Mail,
            userPrincipalName: m365UserResult.UserPrincipalName,
            surname: m365UserResult.Surname,
            mobilePhone: m365UserResult.MobilePhone,
            businessPhone: m365UserResult.BusinessPhone,
            jobTitle: m365UserResult.JobTitle,
        };
        return m365User;
    }
}
