export class SubscriptionList {
    Name: string;
    PrimaryKey: string;
    SecondaryKey: string;
    Product: string;
}

export class PartnerInfoModel {
    FirstName: string;
    LastName: string;
    Email: string;
    Phone: string;
    Cell: string;
    PartnerName: string;
    Username: string;
    IsSkyKickAdmin: boolean;
    IsApiDeveloper: boolean;
    IsSyndicationDeveloper: boolean;
    ApiDeveloperKey: string;
    SubscriptionList: SubscriptionList[];
}

export interface PartnerInfoResponse {
    success: boolean;
    result: PartnerInfoModel;
    SaveOnly: boolean;
}

