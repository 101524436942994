import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sk-general-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  @Input() title: string;
  @Input() primaryText: string;
  @Input() secondaryText: string;
  @Input() btnText: string;

  constructor(private modal: NgbActiveModal) { }

  ngOnInit(): void {

  }

  dismiss(): void {
      this.modal.dismiss();
  }

  close(): void {
      this.modal.close();
  }
}
