import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Utils } from 'src/app/services/utils';
import { Distributor } from '../models/distributor';
import { DistributorRelationship } from '../models/distributor-relationship';

import {
    AppId,
    RouteResolverService,
} from '@skykick/core';

@Injectable({
    providedIn: 'root'
})
export class DistributorsResourcesService {

    constructor(
        private http: HttpClient,
        private abstractUserProvider: AbstractUserProvider,
        private routeResolverService: RouteResolverService
    ) { }

    getDistributorRelationships(): Observable<DistributorRelationship[]> {
        const partnerId = this.abstractUserProvider.getCurrentUser().partnerId;
        const url = `${this.getPAPIRoute()}distributor/relationships`;
        return this.http.get<DistributorRelationship[]>(url).pipe(
             catchError(error => Utils.handleError(error, 'getDistributorRelationships'))
        );
    }

    getDistributorsByCountry(selectedCountryId: number): Observable<Distributor[]> {
        const url = `${this.getPAPIRoute()}distributor/countries/${selectedCountryId}`;
        return this.http.get<Distributor[]>(url).pipe(
             catchError(error => Utils.handleError(error, 'getDistributors'))
        );
    }

    updateDistributorRelationship(payload: any): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.getPAPIRoute()}distributor/relationships`;
        return this.http.put<any>(url, payload, { observe: 'response' }).pipe(
            catchError(error => Utils.handleError(error, 'updateDistributorRelationship()'))
        );
    }

    getDistributorIdFromRelationship(countryId: number, affiliationId: number): Observable<any> {
        const url = `${this.getPAPIRoute()}distributor/countries/${countryId}/affiliation/${affiliationId}`;
        return this.http.get<any>(url).pipe(
            catchError(error => Utils.handleError(error, 'getDistributorIdFromRelationship()'))
        );
    }

    addDistributorRelationship(payload: any): Observable<any> {
        const url = `${this.getPAPIRoute()}distributor/relationships`;
        return this.http.post<any>(url, payload)
        .pipe(
            catchError(error => Utils.handleError(error, 'addDistributorRelationship()'))
        );
    }

    private getPAPIRoute(): string {
        return this.routeResolverService.generatePipeRoute(AppId.Papi);
    }
}

