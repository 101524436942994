import { CustomerStatus } from './customer-status';

export class CustomersSearchFilter {

    public constructor(init?: Partial<CustomersSearchFilter>) {
        Object.assign(this, init);
    }

    public searchTerm: string = null;
    public status: CustomerStatus[] = null;
    public accesses: number[] = null;
    public permissions: number[] = null;
    public pageNumber: number = null;
    public pageSize = 10;
    public orderBy: string = null;
    public orderName: string = null;
}
