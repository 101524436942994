<nav class="navbar sk-portal-header">
    <div class="container">
        <a href="../"><img class=" " src="https://core.skykick.com/images/svg/skykick-logo.svg" height="28"></a>
    </div>
</nav>
<main>
    <h1 class="text-center display-3 py-5" translate="REGISTRATION.TITLE">Complete Account Setup</h1>

    <div class="container card sk-card">
        <!-- card heading -->
        <div class="row sk-steps p-3">
            <div class="col-sm-12">
                <h2 class="mpn-subheading" translate="REGISTRATION.SUBTITLE">Account Details</h2>
            </div>
        </div>
        <!-- card body -->
        <div class="p-3">
            <div class="row">
                <div class="col-sm-12">
                    <h3 class="my-100" translate="REGISTRATION.DESCRIPTION_TITLE">Microsoft Partner Program Details</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12" translate="REGISTRATION.DESCRIPTION">SkyKick is dedicated to empowering
                    IT Providers to succeed in the cloud and access to our product is limited to Microsoft Solution
                    Providers. You must have a valid Microsoft Partner Network (MPN) ID to continue.</div>
            </div>
            <div class="row mt-100">
                <div class="col-sm-12">
                   <form class="formGroup" [formGroup]="mpnForm" (ngSubmit)="validateMpn()">
                        <label class="form-label" for="sk-mpn">
                            <strong translate="REGISTRATION.MPN">Microsoft Partner Network ID</strong>
                        </label>
                        <div class="input-group">
                            <input id="sk-mpn" formControlName="mpn" class="form-control" type="text" maxlength="10"
                                   (input)="mpnChange()" [disabled]="mpnValidationInProgress">
                            <div class="input-group-append">
                                <button sk-spinner-button id="sk-validate" type="submit" [color]="'primary'"
                                    translate="REGISTRATION.MPN_VALIDATE" [isSpinning]="mpnValidationInProgress"
                                    [disabled]="mpnValid || mpnValidationInProgress">Validate</button>
                            </div>
                        </div>

                        <div class="invalid-feedback" [hidden]="!showValidation || mpnValid"
                            translate="REGISTRATION.ERROR.MPN_ERROR">
                            MPN could not be verified
                        </div>

                        <div class="invalid-feedback" [hidden]="!showValidation || !showThrottlingMessage"
                            translate="REGISTRATION.ERROR.MPN_THROTTLED">
                            Too many requests attempted, please contact support at <a href="mailto:support@skykick.com">support&#64;skykick.com</a>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 text-muted" translate="REGISTRATION.FIND_YOUR_MPN"
                    translate-values="{ url: &quot;https://partner.microsoft.com/en-us/pcv/accountsettings/connectedpartnerprofile&quot; }">
                    Find your MPN ID in <a
                        href="https://partner.microsoft.com/en-us/pcv/accountsettings/connectedpartnerprofile"
                        target="_blank">Microsoft Partner Center</a></div>
            </div>

            <div class="row section">
                <div class="col-sm-12" translate="REGISTRATION.JOIN_MICROSOFT_PARTNER_NETWORK"
                    translate-values="{ url: &quot;https://partner.microsoft.com/en-US/membership&quot; }">Don't
                    have a <strong>MPN ID</strong>? <a href="https://partner.microsoft.com/en-US/membership"
                        target="_blank">Join the Microsoft Partner Network</a></div>
                <div class="col-sm-12 px-100 ng-scope" translate="REGISTRATION.CONNECT_WITH_SKYKICK"
                    translate-values="{ url: &quot;https://register.skykick.com/contactme&quot; }">Not an IT
                    Services Provider? <a href="https://register.skykick.com/contactme" target="_blank">Connect with
                        SkyKick</a></div>
            </div>
            <form class="formGroup" [formGroup]="addressForm" (ngSubmit)="submitAddressForm()">
                <!-- Need to only show this after mpn validated. -->
                <div class="section" [hidden]="!mpnValid">
                    <div class="row">
                        <div class="col-sm-12">
                            <h4 translate="REGISTRATION.MPN_DETAILS_FOUND">MPN Details Found</h4>
                        </div>
                    </div>
                    <div class="row mt-100">
                        <fieldset class="col-sm-6"
                            [class.is-invalid]="addressForm.get('organizationName').invalid && !addressForm.get('organizationName').pristine">
                            <label for="sk-organizationname" class="form-label">
                                <strong translate="REGISTRATION.ORGANIZATION_NAME">Organization Name</strong>
                            </label>
                            <input id="sk-organizationname" formControlName="organizationName" type="text"
                                class="form-control"
                                maxlength="50">
                        </fieldset>
                    </div>
                    <div class="row">
                        <fieldset class="col-sm-6"
                            [class.is-invalid]="addressForm.get('address1').invalid && !addressForm.get('address1').pristine">
                            <label for="sk-address1" class="form-label">
                                <strong translate="REGISTRATION.ADDRESS1">Address 1</strong>
                            </label>
                            <input id="sk-address1" formControlName="address1" type="text" required
                                class="form-control" maxlength="50">
                        </fieldset>
                        <fieldset class="col-sm-6">
                            <label for="sk-address2" class="form-label">
                                <strong translate="REGISTRATION.ADDRESS2">Address 2</strong>
                            </label>
                            <input id="sk-address2" formControlName="address2" type="text"
                                class="form-control" maxlength="50">
                        </fieldset>
                    </div>
                    <div class="row">
                        <fieldset class="col-sm-6"
                            [class.is-invalid]="addressForm.get('city').invalid && !addressForm.get('city').pristine">
                            <label for="sk-city" class="form-label">
                                <strong translate="REGISTRATION.CITY">City</strong>
                            </label>
                            <input id="sk-city" formControlName="city" type="text"
                                class="form-control"
                                maxlength="50">
                        </fieldset>
                        <fieldset class="col-sm-6"
                            [class.is-invalid]="addressForm.get('stateCode').invalid && !addressForm.get('stateCode').pristine">
                            <label for="sk-state" class="form-label">
                                <strong translate="REGISTRATION.STATE">State / Province</strong>
                            </label>
                            <input id="sk-state" formControlName="stateCode" type="text"
                                class="form-control"
                                maxlength="50">
                        </fieldset>
                    </div>
                    <div class="row">
                        <fieldset class="col-sm-6"
                            [class.is-invalid]="addressForm.get('zipCode').invalid && !addressForm.get('zipCode').pristine">
                            <label for="sk-postalcode" class="form-label">
                                <strong translate="REGISTRATION.POSTAL_CODE">Postal Code</strong>
                            </label>
                            <input id="sk-postalcode" formControlName="zipCode" type="text"
                                class="form-control"
                                maxlength="50">
                        </fieldset>
                    </div>
                    <div class="row">
                        <fieldset class="col-sm-6"
                            [class.is-invalid]="addressForm.get('countryOrRegion').invalid && !addressForm.get('countryOrRegion').pristine">
                            <label for="sk-country" class="form-label">
                                <strong translate="REGISTRATION.COUNTRY">Country or Region</strong>
                            </label>
                            <input id="sk-country" formControlName="countryOrRegion" type="text"
                                class="form-control"
                                maxlength="50">
                        </fieldset>
                    </div>
                    <div class="row">
                        <fieldset class="col-sm-6">
                            <label for="sk-phone" class="form-label">
                                <strong translate="REGISTRATION.BUSINESS_PHONE">Business Phone</strong>
                            </label>
                            <input id="sk-phone" formControlName="phoneNumber" type="text"
                                class="form-control"
                                maxlength="50">
                        </fieldset>
                    </div>
                    <div class="row">
                        <fieldset class="col-sm-6"
                            [class.is-invalid]="addressForm.get('technicalContactEmail').invalid && !addressForm.get('technicalContactEmail').pristine">
                            <label for="sk-email" class="form-label">
                                <strong translate="REGISTRATION.EMAIL">Technical Contact Email</strong>
                            </label>
                            <input id="sk-email" formControlName="technicalContactEmail" type="text"
                                class="form-control"
                                maxlength="50">
                        </fieldset>
                    </div>
                    <div class="row">
                        <fieldset class="col-sm-6"
                            [class.is-invalid]="addressForm.get('languagePreferenceCode').invalid && !addressForm.get('languagePreferenceCode').pristine">
                            <label for="sk-languagepreference" class="form-label">
                                <strong translate="REGISTRATION.LANGUAGE_PREFERENCE">Language Preference</strong>
                            </label>
                            <input id="sk-languagepreference" formControlName="languagePreferenceCode" type="text"
                                class="form-control"
                                maxlength="50">
                        </fieldset>
                    </div>
                </div><!-- /section -->
                <div class="row py-3 mt-300">
                    <div class="col-sm-12">
                        <h3 translate="REGISTRATION.PURCHASING_SKYKICK_THROUGH_DISTRIBUTOR">Purchasing
                            SkyKick through a Distributor</h3>

                        <p translate="REGISTRATION.SELECT_DISTRIBUTOR">It is easy to
                            select your preferred Distributor in the Admin section of SkyKick portal under Manage Account.
                        </p>

                        <p translate="REGISTRATION.SEE_DISTRIBUTORS"
                            translate-values="{ url: &quot;https://www.skykick.com/platform/distributors/&quot; }">See a
                            complete list of <a href="https://www.skykick.com/platform/distributors/"
                                target="_blank">SkyKick Distributors</a>.</p>
                    </div>
                </div>
                <div class="row py-3">
                    <div class="d-grid col-sm-12">
                        <button sk-spinner-button id="sk-submit" [color]="'primary'" class="btn-lg px-100" type="submit"
                            translate="REGISTRATION.SUBMIT" [disabled]="!addressForm.valid" [isSpinning]="addressFormSubmissionInProgress">Save and
                            Continue</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</main>
