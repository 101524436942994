import { Injectable } from '@angular/core';

import { Observable, forkJoin } from 'rxjs';

import { M365ConnectionStatus } from 'src/app/settings/account/models/connection-status';
import { PartnerAuthentication } from 'src/app/settings/account/models/partner-authentication';
import { AuthenticationMethodResourcesService } from 'src/app/settings/account/services/authentication-method-resources.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationSettingsService {
    constructor(
        private authenticationMethodResourcesService: AuthenticationMethodResourcesService
    ) { }
  
    fetchAuthenticationSettings() : Observable<[PartnerAuthentication, any, M365ConnectionStatus]> {
        return forkJoin([
            this.authenticationMethodResourcesService.getPartnerAuthenticationSettings(),
            this.authenticationMethodResourcesService.getGroupSyncStatus(),
            this.authenticationMethodResourcesService.getM365AuthConnectionStatus()
        ])
    }
}