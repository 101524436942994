import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MpnRegistrationComponent } from './component/mpn-registration.component';

// For checking impersonation.
import { AuthModule } from '@skykick/platform-identity-auth-auth0-angular';

// For popups.
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonsModule, FeedbackModule } from '@skykick/core';

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    ButtonsModule,
    FeedbackModule,
    AuthModule,
    NgbModule
  ],
  declarations: [
    MpnRegistrationComponent
  ],
  bootstrap: [MpnRegistrationComponent]
})
export class MpnRegistrationModule { }
