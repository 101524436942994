import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  OnInit,
} from '@angular/core';

import {
  CardsResourcesService,
} from 'src/app/home/services/cards-resources.service';
import { CardsUtilities } from 'src/app/home/utilities/cards-utilities';

import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';

import {
  AppId,
  NotificationsControlService,
  ProductTypes,
  RouteResolverService,
  SeverityTypes,
} from '@skykick/core';

@Component({
  selector: 'sk-backup-card',
  templateUrl: './backup-card.component.html',
  styleUrls: ['./backup-card.component.scss'],
  animations: [
    trigger(
      'animateIn',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('.5s ease-out',
                    style({ opacity: 1 }))
          ]
        ),
      ]
    ),
    trigger(
      'expandIn',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('.5s ease-out',
                    style({ height: 35, opacity: 1 }))
          ]
        ),
      ]
    )
  ]
})
export class BackupCardComponent implements OnInit {
  constructor(private routeResolverService: RouteResolverService,
              private cardsResourcesService: CardsResourcesService,
              private cardsUtilities: CardsUtilities,
              private abstractUserProvider: AbstractUserProvider,
              private notificationsControlService: NotificationsControlService) { }

  public activeBackups: string;
  public pendingBackups: string;
  public gettingCounts = true;
  public gettingAlerts = true;
  public formattedCritialAlertCount = '0';
  public showBlurb = true;

  readonly backupFullAccessPermission = 'Cloud_Backup_Full_Access';

  ngOnInit(): void {
    this.getSummary();
    this.getCriticalAlerts();
  }

  getBackupHomeUrl():string {
    return this.routeResolverService.generateRatRoute(AppId.Backup);
  }

  goToBackup() {
    window.location.href = this.getBackupHomeUrl();
  }

  getSummary(): void{
    if (this.canAccess()) {
        this.cardsResourcesService.getBackupSummary().subscribe({
          next: x => {
            this.activeBackups = this.cardsUtilities.abbreviateCount(x.active);
            this.pendingBackups =  this.cardsUtilities.abbreviateCount(x.pending);
            this.gettingCounts = false;
            this.showBlurb = (this.activeBackups === '0' && this.pendingBackups === '0');
          },
          error: () => {
            this.gettingCounts = false;
          }
      });
    } else {
        this.activeBackups = '0';
        this.pendingBackups =  '0';
        this.gettingCounts = false;
        this.showBlurb = true;
    }
  }

  getCriticalAlerts(): void {
    if (this.canAccess()) {
        this.cardsResourcesService.getAlertStatistics().subscribe({
          next: x => {
            this.formattedCritialAlertCount = this.cardsUtilities.abbreviateCount(x.backupStatistics.totalCriticalAlerts);
            this.gettingAlerts = false;
          },
          error: () => {
            this.gettingAlerts = false;
          }
      });
    } else {
        this.formattedCritialAlertCount = '0';
        this.gettingAlerts = false;
    }
  }

  onGoToBackupsClicked(): void {
    window.location.href = this.routeResolverService.generateRatRoute('backup', 'partner/cloud-backup/manager');
  }

  onCritAlertsClick(): void {
    this.notificationsControlService.setModalFilter(true, ProductTypes.Backup, SeverityTypes.Critical);
  }

  canAccess(): boolean {
    return this.abstractUserProvider.getCurrentUser().hasPermission(this.backupFullAccessPermission);
  }
}
