<div class="card mt-200" id="setupo365authentication">
    <div class="card-header sk-card-header d-flex justify-content-between">
        <h4 translate="settings.O365.AUTHENTICATION"></h4>
    </div>
    <div class="card-block">

        <sk-feedback *ngIf="hasOngoingAuthFlow()" class="mb-100" type="info"
            [message]="'settings.O365.INPROGRESS_MESSAGE' | translate">
            <button sk-button (click)="openAuthFlowModal()" id="o365-authentication-continue" class="btn btn-outline-secondary d-inline-block" translate>settings.O365.INPROGRESS_BTN</button>
        </sk-feedback>

        <sk-feedback class="mb-50" *ngIf="isReauthenticationRequired()" type="danger"
            [subject]="'settings.O365.ACCOUNT_O365_BAD_STATE_AUTH_TITLE' | translate" [message]="'settings.O365.ACCOUNT_O365_BAD_STATE_AUTH_MESSAGE' | translate">
            <button sk-button translate="settings.O365.ACCOUNT_O365_BAD_STATE_REENABLE_BUTTON_TEXT" class="ml-100"
                (click)="openAuthFlowModal()"></button>
        </sk-feedback>

        <div class="row">
            <div class="col-12">
                <sk-text-spinner *ngIf="isLoading" message="Loading authentication"></sk-text-spinner>

                <form *ngIf="!isLoading" [formGroup]="authSettingsForm" novalidate>
                    <div class="mb-50">
                      <sk-unmatched-users-feedback *ngIf="isUnmatchedBannerShoudBeVisible()" [partnerAuthentication]="partnerAuthentication"
                        [connectionStatus]="m365AuthConnectionStatus"
                        [groupSyncEnabled]="groupSyncStatus?.groupsSyncEnabled"></sk-unmatched-users-feedback>
                    </div>
                    <p class="text-muted mb-50" translate="settings.O365.AUTHENTICATION_MESSAGE"><a href='https://support.skykick.com/hc/en-us/articles/360024880214' target='_blank' translate="settings.O365.LEARN_MORE"></a></p>

                    <fieldset class="mb-100">
                        <div class="form-check">
                            <input formControlName="authenticationType" [value]="0"
                                type="radio" id="authenticationType1" name="authenticationType" class="form-check-input">
                            <label class="form-check-label" for="authenticationType1" translate="settings.O365.SKYKICK_AUTHENTICATION"></label>
                            <div class="sk-form-text">
                                <small class="text-muted" translate="settings.O365.SKYKICK_AUTHENTICATION_MESSAGE"></small>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="ml-200 mb-100">
                        <div class="form-check">
                            <input type="checkbox" id="isMFAEnabled" class="form-check-input" formControlName="isMFAEnabled">
                            <label for="isMFAEnabled" class="form-check-label font-medium" translate="settings.O365.ENABLE_MFA"></label>
                        </div>
                        <small class="sk-form-text text-muted" translate="settings.O365.ENABLE_MFA_MESSAGE"><a href ='https://support.skykick.com/hc/en-us/articles/360024880354' target='_blank' translate="settings.O365.LEARN_MORE"></a></small>
                    </fieldset>

                    <fieldset class="mb-100">
                        <div class="form-check">
                            <input formControlName="authenticationType" [value]="1"
                                type="radio" id="authenticationType2" name="authenticationType" class="form-check-input">
                            <label class="form-check-label" for="authenticationType2" translate="settings.O365.OFFICE_365_AUTHENTICATION"></label>
                            <div class="sk-form-text">
                                <small class="text-muted" translate="settings.O365.OFFICE_365_AUTHENTICATION_MESSAGE">
                                    <a href='https://support.skykick.com/hc/en-us/articles/360020368794' target='_blank' translate="settings.O365.LEARN_MORE"></a>
                                </small>
                            </div>
                        </div>
                    </fieldset>

                    <sk-feedback class="mb-100" type="info" *ngIf="hasSwitchedToM365()" [message]="'settings.O365.RE_AUTHENTICATION_MESSAGE' | translate"></sk-feedback>

                    <button id="submitBtn" sk-spinner-button class="mr-100" [color]="'primary'" (click)="updateAuthentication()" [isSpinning]="isBusy" 
                        [disabled]="isDisabled()">{{ 'COMMON.UPDATE' | translate }}
                    </button>
                    <button id="cancelBtn"
                        [disabled]="isDisabled()"
                        (click)="cancel()" type="button" class="btn btn-outline-secondary" translate="COMMON.CANCEL">
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
