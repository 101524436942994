<div class="modal-header bg-white">
    <div class="d-flex flex-column">
        <div class="d-flex align-items-center">
            <h3>Integration Settings</h3>
        </div>
    </div>

    <button type="button" class="btn-close" (click)="activeModal.close()" id="closeBtn"></button>
</div>

<div class="d-flex flex-grow-1 overflow-auto">
    <!-- Column Left -->
    <div *ngIf="settingType === 'wizard'" class="d-flex">
        <div class="sk-panel-left sk-panel-secondary panel-xs-2">
            <div class="sk-panel-body">
                <div class="sk-panel-nav">
                    <ul class="nav sk-nav-vert flex-column">
                        <li *ngFor="let item of WIZARDNAVS;let i = index" class="nav-item">
                            <a id="wizardLink{{i}}" class="nav-link d-flex align-items-center px-150" [class.active]="item.active" [class.disabled]="true">
                                <i class="material-icons md-22 mr-50 icon-light d-flex" [class.icon-success]="item.complete">check_circle</i>
                                <span class="text-truncate">{{item.name}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Column Right -->
    <ng-template skWizardSteps></ng-template>

</div>
