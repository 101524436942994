import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs/operators';

import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  SkyKickModal,
  SkyKickModalService,
  SkyKickModalWarningOptions,
  SkyKickTabViewContainer,
  TaskManagerService,
  WarningModalComponent,
} from '@skykick/core';

import { Utils } from 'src/app/services/utils';
import { SKYKICKPRODUCTS } from '../../core/constants/skykick-products.const';
import { CompanyMapping } from '../../core/models/CompanyMapping';
import {
  ActionButtonsService,
} from '../../core/services/action-buttons.service';
import {
  ConnectwiseResourcesService,
} from '../../core/services/connectwise-resources.service';

@Component({
    selector: '[sk-customer-settings]',
    templateUrl: './customer-settings.component.html',
    styleUrls: ['./customer-settings.component.scss']
})
export class CustomerSettingsComponent extends SkyKickTabViewContainer<any> implements OnInit, OnDestroy {
    private subscription: Subscription;
    private firstAgreementRun: boolean = true;
    @HostBinding('class.sk-panel-scroll')
    @HostBinding('class.px-150') isActive = true;
    form: UntypedFormGroup;
    customer: any;
    connectWiseAccounts$: Observable<any>;
    agreements$: Observable<any>;
    isLoadingCWAccounts: boolean = true;
    isLoadingAgreements: boolean;
    searchingAccounts: boolean;
    agreementMessage: string;
    searchTermBehaviorSubject$: BehaviorSubject<string> = new BehaviorSubject('*');

    private customerUpdate(isDeleteMapping: boolean) {
        const formA = this.form.get('connectWiseAccountSelect').value;
        const formB = this.form.get('agreementSelect').value;
        const formC = this.form.get('includeUserNamesOnInvoice').value;
        this.customer.agreementId = formB.agreementId;
        this.customer.agreementName = formB.agreementName;
        this.customer.companyId = formA.companyId;
        this.customer.companyName = formA.companyName;
        this.customer.includeUserNamesOnInvoice = formC || false;
        this.customer.isEnabled = true;
        this.customer.prorated = formB.prorated;

        this.actionButtonsService.setPrimaryIsBusy(true);
        const orderMappingOperation$ = isDeleteMapping
            ? this.connectwiseResourcesService.deleteOrderMapping(this.customer)
              .pipe(tap(() => {
                this.customer.orderMappingId = this.customer.agreementId = this.customer.agreementName = this.customer.companyId =
                this.customer.companyName = this.customer.includeUserNamesOnInvoice = this.customer.isEnabled = this.customer.prorated = null;
                this.customer.missingProducts = null;
              }))
            : this.customer.orderMappingId
              ? this.connectwiseResourcesService.updateOrderMapping(this.customer)
              : this.connectwiseResourcesService.saveOrderMapping(this.customer)
                .pipe(tap(mapping => this.customer.orderMappingId = mapping.orderMappingId));
        orderMappingOperation$.pipe().subscribe({
          complete: () => {
            this.actionButtonsService.setPrimaryIsBusy(false);
            this.toastrService.success('Update successful');
            this.actionButtonsService.closeTask();
          }, 
          error:() => {
              this.actionButtonsService.setPrimaryIsBusy(false);
              this.toastrService.error('There is a problem with the service. We are notified & working on it. Please try again later.');
          }
        });
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        protected config: NgbPopoverConfig,
        private skyKickModalService: SkyKickModalService,
        private connectwiseResourcesService: ConnectwiseResourcesService,
        private actionButtonsService: ActionButtonsService,
        protected taskManagerService: TaskManagerService,
        private toastrService: ToastrService
    ) {
        super();
        config.triggers = 'hover';
    }

    ngOnInit(): void {
        this.customer = this.Data;
        
        this.form = this.formBuilder.group({
            connectWiseAccountSelect: [{ value: null, disabled: true },
              [Validators.required],
              [this.connectWiseAccountNameValidator()]
            ],
            agreementSelect: [{ value: null, disabled: true }, [Validators.required]],
            includeUserNamesOnInvoice: [this.customer.includeUserNamesOnInvoice]
        });
        this.form.valueChanges.subscribe(controls => {
            if (controls.connectWiseAccountSelect
              && !this.form.get('connectWiseAccountSelect').errors
              && controls.agreementSelect) {
                this.actionButtonsService.setPrimaryIsDisabled(false);
            } else {
                this.actionButtonsService.setPrimaryIsDisabled(true);
            }
        });

        this.connectWiseAccounts$ = this.searchTermBehaviorSubject$.asObservable().pipe(
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.searchingAccounts = true),
          switchMap(searchTerm => this.connectwiseResourcesService.getOrderMappings().pipe(
            switchMap(orders => this.connectwiseResourcesService.searchConnectWiseAccountNames(searchTerm, 1000)
                .pipe(
                    skipWhile(val => !val),
                    tap(accounts =>
                      orders
                        .filter(order => order.orderMappingId !== this.customer.orderMappingId)
                        .forEach(order => {
                          const mappedAccount = accounts.find(x => x.companyId === order.companyId);
                          if (mappedAccount != undefined)
                            mappedAccount.isMapped = true;
                        })
                    ),
                    catchError((error) => {
                      return Utils.handleError(error, 'mapConnectWiseAccounts');
                    })
                )
            ),
            map(res => res.filter(x => !x.isMapped)),          
            tap(res => {
                setTimeout(() => {
                    this.isLoadingCWAccounts = false;
                    this.searchingAccounts = false;
                    this.form.get('agreementSelect').patchValue(null);
                    this.form.get('connectWiseAccountSelect').enable({ emitEvent: false });
                    if (this.customer.companyId && this.customer.companyName) {
                      let cwAccount: CompanyMapping = res.find(item => item.companyId === this.customer.companyId);
                      if (!cwAccount){
                        cwAccount = { companyId: this.customer.companyId, companyName: this.customer.companyName, isMapped: false};
                        res.push(cwAccount);
                        res.sort((x,y) => x.companyName.toLowerCase() < y.companyName.toLowerCase() ? -1 : 1);
                      }
                      this.form.get('connectWiseAccountSelect').patchValue(cwAccount);
                    }
                });
            })
          ))
        );

        this.agreements$ = this.form.get('connectWiseAccountSelect').valueChanges.pipe(
            distinctUntilChanged(),
            skipWhile(val => !val),
            tap(() => {
                this.agreementMessage = null;
                this.isLoadingAgreements = true;
                this.form.get('agreementSelect').patchValue(null);
                this.form.get('agreementSelect').disable({ emitEvent: false });
            }),
            switchMap(account => this.connectwiseResourcesService.getConnectWiseAgreements(account.companyId).pipe(
                tap((agreements) => {
                    if (agreements) {
                        this.form.get('agreementSelect').enable({ emitEvent: false });
                        if (this.firstAgreementRun && this.customer.agreementId) {
                            const cwagreement = agreements.find(item => item.agreementId == this.customer.agreementId);
                            if (cwagreement) {
                                this.form.get('agreementSelect').patchValue(cwagreement);
                            }
                            this.firstAgreementRun = false;
                        }
                    } else {
                        this.agreementMessage = "No agreements found";
                    }
                    this.isLoadingAgreements = false;
                }),
                catchError(res => {
                    if (res.error) {
                        this.agreementMessage = res.error.message;
                    }
                    return EMPTY;
                })
            ))
        );

        this.subscription = this.actionButtonsService.onSubmitPrimaryButton(() => {
            this.customerUpdate(false);
        });
    }

    removeMapping() {
        this.form.disable();
        let warningModal: SkyKickModal<WarningModalComponent, void>;
        const options: SkyKickModalWarningOptions = {
            body: `<p class="mb-200">We will no longer be able to push billing data for Cloud Backup Subscriptions.</p>`,
            title: 'Remove Customer Mapping to ConnectWise',
            btnLabel: 'Yes, Remove Mapping',
            alternative: {
                btnLabel: 'Cancel',
                btnCallback: () => {
                    warningModal.dismiss();
                    this.form.enable();
                }
            }
        };
        warningModal = this.skyKickModalService.warning(options);
        warningModal.result.then(res => {
            if (res.wasClosed) {
                this.customerUpdate(true);
                this.form.enable();
            }
        });
    }

    connectWiseAccountNameValidator(): AsyncValidatorFn {
      return (control: AbstractControl): Observable<ValidationErrors> => {
          return of(control.value.companyId).pipe(
            switchMap(companyId =>
              this.connectwiseResourcesService.getOrderMappings()
              .pipe(
                  map((result) =>
                    result.filter(mc => mc.orderMappingId !== this.customer.orderMappingId && mc.companyId === companyId).length
                      ? { mappingExists: true }
                      : null),
                  catchError((error) => error)
              ))
          )
      };
    }

    getMissingProducts(): Array<string>{
      const missingProducts = [];

      if (this.customer.missingProducts === 25){
        missingProducts.push(SKYKICKPRODUCTS[1].name);
        missingProducts.push(SKYKICKPRODUCTS[2].name);
      }
      else{
        let productIndex = SKYKICKPRODUCTS.findIndex(({source}) => source === this.customer.missingProducts);
        missingProducts.push(SKYKICKPRODUCTS[productIndex].name);
      }

      return missingProducts;
    }

    selectAccount(account: any): void {
      this.form.get('connectWiseAccountSelect').patchValue(account);
    }

    getSelectedAccount(): any {
      const selectedAccount = this.form.get('connectWiseAccountSelect').value;
      return selectedAccount ? selectedAccount : { companyName: null };
    }

    searchAccounts(event: any): void {
      const searchTerm = event.target.value;
      this.searchTermBehaviorSubject$.next(!searchTerm ? '*' : searchTerm);
    }

    accountSelectHasMappingError(): boolean {
      return this.form.controls['connectWiseAccountSelect'].hasError('mappingExists');
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
