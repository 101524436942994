import { Component, OnInit } from '@angular/core';
import { AppId, RouteResolverService } from '@skykick/core';

@Component({
  selector: 'sk-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private routeResolverService: RouteResolverService) {}

  ngOnInit(): void {}

  getMicrosoftPartnerCenterRoute(): string {
      return this.routeResolverService.generateRatRoute(AppId.PartnerCenter);
  }
}
