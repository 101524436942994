import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Utils } from '../../../services/utils';
import { CompanyInfo } from '../models/company-info';
import { CreditCardInfo } from '../models/credit-card-info';
import { Distributor } from '../models/distributor';
import { DistributorRelationship } from '../models/distributor-relationship';
import { UnsettledOrder } from '../models/unsettled-order';

import {
    AppId,
    RouteResolverService,
} from '@skykick/core';

@Injectable({
    providedIn: 'root',
})

export class AccountResourcesService {
    constructor(
        private http: HttpClient,
        private abstractUserProvider: AbstractUserProvider,
        private routeResolverService: RouteResolverService
    ) {}

    // Company Info
    getCompanyInfo(): Observable<CompanyInfo> {
        const partnerId = this.abstractUserProvider.getCurrentUser().partnerId;
        const url = `${this.getPAPIRoute()}partner/admin/${partnerId}/companyinfo`;
        return this.http.get<CompanyInfo>(url)
        .pipe(
             catchError(error => Utils.handleError(error, partnerId))
        );
    }

    getSupportedO365Countries(): Observable<any>{
        const url = `${this.getPAPIRoute()}o365/supportedo365countries`;
        return this.http.get<any>(url)
        .pipe(
             catchError(error => Utils.handleError(error, 'SupportedO365Countries()'))
        );
    }

    getCountriesList(): Observable<any>{
        const url = `${this.getPAPIRoute()}location/countries`;
        return this.http.get<any>(url)
        .pipe(
             catchError(error => Utils.handleError(error, 'getCountriesList()'))
        );
    }

    getStateList(countryId: number): Observable<any>{
        //Do we every get anything but US states for this?
        const url = `${this.getPAPIRoute()}location/states/${countryId}`;
        return this.http.get<any>(url)
        .pipe(
             catchError(error => Utils.handleError(error, 'getStateList()'))
        );
    }

    submitCompanyInfo(payload: any): Observable<any> {
        const url = `${this.getPAPIRoute()}partner/admin/${this.abstractUserProvider.getCurrentUser().partnerId}/companyinfo`;
        return this.http.post<any>(url, payload).pipe(
            catchError(error => Utils.handleError(error, 'submitCompanyInfo()'))
        );
    }

    // Credit Card Info
    getCreditCardInfo(): Observable<CreditCardInfo> {
        const url = `${this.getPAPIRoute()}partner/admin/${this.abstractUserProvider.getCurrentUser().partnerId}/creditcardinfo`;
        return this.http.get<CreditCardInfo>(url)
        .pipe(
             catchError(error => Utils.handleError(error, 'getCreditCardInfo()'))
        );
    }

    getDistributor(): Observable<any> {
      const url = `${this.getPAPIRoute()}partner/distributor`;
        return this.http.get<any>(url)
        .pipe(
             catchError(error => Utils.handleError(error, 'getDistributor()'))
        );
    }

    submitCreditCardInfo(formData: any): Observable<any> {
        const url = `${this.getPAPIRoute()}partner/admin/${this.abstractUserProvider.getCurrentUser().partnerId}/creditcardinfo`;
        return this.http.post<any>(url, formData)
        .pipe(
            catchError(error => Utils.handleError(error, 'submitCreditCardInfo()'))
        );
    }

    getFirstUnsettledOrder(): Observable<UnsettledOrder> {
        const url = `${this.getPAPIRoute()}partner/admin/${this.abstractUserProvider.getCurrentUser().partnerId}/firstunsettledorder`;
        return this.http.get<UnsettledOrder>(url)
        .pipe(
             catchError(error => Utils.handleError(error, 'getFirstUnsettledOrder()'))
        );
    }

    // Distributors Info
    getDistributorRelationships(): Observable<Array<DistributorRelationship>> {
        const url = `${this.getPAPIRoute()}/distributor/relationships`;
        return this.http.get<Array<DistributorRelationship>>(url)
        .pipe(
             catchError(error => Utils.handleError(error, 'getDistributors()'))
        );
    }

    getDistributorCountriesById(countryId: number): Observable<Array<Distributor>> {
        const url = `${this.getPAPIRoute()}distributor/countries/${countryId}`;
        return this.http.get<Array<Distributor>>(url)
        .pipe(
             catchError(error => Utils.handleError(error, 'getDistributors()'))
        );
    }

    getDistributorIdFromRelationship(countryId: number, affiliationId: number): Observable<any> {
        const url = `${this.getPAPIRoute()}distributor/countries/${countryId}/affiliation/${affiliationId}`;
        return this.http.get<any>(url)
        .pipe(
             catchError(error => Utils.handleError(error, 'getFirstUnsettledOrder()'))
        );
    }

    addDistributorRelationship(formData: any): Observable<any> {
        const url = `${this.getPAPIRoute()}distributor/relationships`;
        return this.http.post<any>(url, formData)
        .pipe(
            catchError(error => Utils.handleError(error, 'addDistributorRelationship()'))
        );
    }

    updateDistributorRelationship(formData: any): Observable<any> {
        const url = `${this.getPAPIRoute()}distributor/relationships`;
        return this.http.put<any>(url, formData)
        .pipe(
            catchError(error => Utils.handleError(error, 'updateDistributorRelationship()'))
        );
    }

    // Logo
    uploadLogo(logo: any): Observable<any> {
        const url = `${this.getPAPIRoute()}partner/logo`;
        return this.http.post<any>(url, logo)
        .pipe(
            catchError(error => Utils.handleError(error, 'uploadLogo()'))
        );
    }

    private getPAPIRoute(): string {
        return this.routeResolverService.generatePipeRoute(AppId.Papi);
    }
}
