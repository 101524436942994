<div class="d-flex flex-column h-100">
    <div class="modal-body">

        <ng-container *ngIf="(users$ | async) as users; else spinner">
            <h4>{{ 'settings.O365.MATCH_USERS_TO_TENANT_TITLE' | translate }}</h4>
            <p class="my-100" translate="settings.O365.MATCH_USERS_SUB_TEXT"></p>
            <sk-feedback *ngIf="shouldBannerBeVisible(users)" icon="warning" type="danger"
                [subject]="'settings.O365.WARNING_MATCHING_BANNER.SUBJECT' | translate"
                [message]="'settings.O365.WARNING_MATCHING_BANNER.MESSAGE' | translate"></sk-feedback>

            <div class="d-flex my-150 justify-content-between">
                <strong>{{users.length}} {{'settings.O365.USERS' | translate}}</strong>
                <div class="d-flex align-items-center">
                    <span class="text-muted mr-150" translate="settings.O365.FILTER_BY"></span>
                    <sk-dropdown type="basic" [label]="activeFilter.key" [options]="filterOptions"
                        (optionSelected)="handleChangeFilter($event)"></sk-dropdown>
                </div>
            </div>

            <!-- Users table -->
            <div class="scrollable-h-70" *ngIf="!(this.activeFilter.key === this.filterOptions[1].key && users.length === 0); else emptyUnmappedUsersTemplate"
                [formGroup]="usersForm">
                <table class="table table-hover sk-standard-wrap-table sk-sticky-header fixed-layout">
                    <thead class="thead-white thead-sm thead-border-none sticky-header">
                        <tr class="text-muted">
                            <th class='w-50' translate="settings.O365.MISMATCHED_USERNAMES"></th>
                            <th class='w-50' translate="settings.O365.OFFICE_365_CREDENTIALS"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr [formGroupName]="getUserFormGroupName(user.skUserName)"
                            *ngFor="let user of users; let index = index" id="user-{{index}}">
                            <td class="d-flex align-items-center">
                                <span [ngbTooltip]="user.skUserName" openDelay="700" class="text-truncate">{{user.skUserName}}</span>
                            </td>
                            <td>
                                <div class="sk-input-group">
                                    <input id="m365-users-matching-field-{{index}}" class="form-control" formControlName="o365LoginName" type="text"
                                        (selectItem)="typeaheadOnSelect($event)" #instance="ngbTypeahead"
                                        (blur)="handleBlur($event, getUserFormGroupName(user.skUserName))"
                                        [ngbTypeahead]="getUserFormGroupName(user.skUserName) | applyPure : searchType"
                                        [class.is-invalid]="usersForm.controls[getUserFormGroupName(user.skUserName)].invalid"
                                        [value]="user.o365LoginName" />
                                    <div *ngIf="usersForm.controls[getUserFormGroupName(user.skUserName)].get('isLoading').value" class="sk-spin-loader loader-sm"></div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>

    </div>

    <!-- Wizard Footer -->
    <div class="modal-footer justify-content-between">
        <div>
            <button *ngIf="isBackButtonVisible" type="button" (click)="stepBack()" class="btn btn-outline-secondary mr-100" translate="COMMON.BACK"></button>
            <button id="submitMatching" sk-spinner-button [color]="'primary'" (click)="saveUserMatching()" [isSpinning]="isLoading" 
                [disabled]="isLoading || usersForm.invalid">{{ 'settings.O365.SAVE_AND_CLOSE' | translate }}
            </button>
        </div>

        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()"
            translate="COMMON.CANCEL"></button>
    </div>

</div>

<!-- Loader spinner -->
<ng-template #spinner>
    <div class="d-flex justify-content-center w-100">
        <sk-text-spinner class="h-100" message="{{'COMMON.LOADING' | translate}}"></sk-text-spinner>
    </div>
</ng-template>

<ng-template #emptyUnmappedUsersTemplate>
    <div class="align-items-center d-flex flex-column justify-content-center p-3 text-muted">
        <i class="material-icons opacity-50 md-60 icon-primary">person</i>
        <p translate="settings.O365.USERS_MATCHING.USERS_SUCCESSFULLY_MATCHED"></p>
    </div>
</ng-template>
