import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ActionButtonsService {
    private submitPrimarySource = new Subject<void>();
    private submitPrimary$ = this.submitPrimarySource.asObservable();
    private setPrimaryIsBusySource = new BehaviorSubject(false);
    setPrimaryIsBusy$ = this.setPrimaryIsBusySource.asObservable();
    private setPrimaryIsDisabledSource = new BehaviorSubject(false);
    setPrimaryIsDisabled$ = this.setPrimaryIsDisabledSource.asObservable();
    
    private submitWizardStepSource = new Subject<string>();
    private submitWizardStep$ = this.submitWizardStepSource.asObservable();
    private setWizardStepIsBusySource = new BehaviorSubject(false);
    wizardStepIsBusy$ = this.setWizardStepIsBusySource.asObservable();
    private setWizardStepIsDisabledSource = new BehaviorSubject(false);
    wizardStepIsDisabled$ = this.setWizardStepIsDisabledSource.asObservable();

    private closeTaskSource = new Subject<void>();
    private closeTask$ = this.closeTaskSource.asObservable();


    constructor() { }

    submitPrimaryButton() {
        this.submitPrimarySource.next();
    }
    onSubmitPrimaryButton(handler: () => void): Subscription {
        return this.submitPrimary$.subscribe(handler);
    }
    setPrimaryIsBusy(value: boolean) {
        this.setPrimaryIsBusySource.next(value);
    }
    setPrimaryIsDisabled(value: boolean) {
        this.setPrimaryIsDisabledSource.next(value);
    }

    submitWizardStep(type: string) {
        this.submitWizardStepSource.next(type);
    }
    onSubmitWizardStep(handler: (data: any) => void): Subscription {
        return this.submitWizardStep$.subscribe(handler);
    }
    setWizardStepIsBusy(value: boolean) {
        this.setWizardStepIsBusySource.next(value);
    }
    settWizardStepIsDisabled(value: boolean) {
        this.setWizardStepIsDisabledSource.next(value);
    }

    closeTask() {
        this.closeTaskSource.next();
    }
    onCloseTask(handler: () => void): Subscription {
        return this.closeTask$.subscribe(handler);
    }

}
