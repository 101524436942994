<div class="card mt-200" id="uploadImage">
    <div class="card-header sk-card-header d-flex justify-content-between">
        <h4>{{ 'settings.account.PLATFORM_BRANDING.CARD_TITLE' | translate }}</h4>
    </div>
    <div class="card-block">
        <p>{{ 'settings.account.PLATFORM_BRANDING.CARD_DESCRIPTION' | translate }}</p>
        <div class="row">
            <div class="col-7">
                <div class="mb-100">
                    <div class="input-width-max">
                        <label for="selectedFile" class="font-medium form-label" translate="settings.account.PLATFORM_BRANDING.UPLOAD_NEW_COMPANY_LOGO"></label>
                        <input #fileInput id="selectedFile" class="form-control" type="file" accept=".png,.jpg,.jpeg,.gif"
                            (change)="onChange(fileInput.files[0])" />
                    </div>
                    <small id="logoHelp" class="sk-form-text text-muted">{{'settings.account.PLATFORM_BRANDING.RECOMMENDED_LOGO_SIZE' | translate}}</small>
                </div>
                <p><ngb-progressbar type="success" textType="white" [value]="progress" [showValue]="true"></ngb-progressbar></p>
            </div>
            <div class="col-5">
                <div class="mb-100">
                    <label class="font-medium">{{ 'settings.account.PLATFORM_BRANDING.PREVIEW' | translate }}</label>
                    <div class="sk-partner-brand">
                        <img *ngIf="!cardImageBase64" class="partner-logo" src="/assets/images/360x88.gif" />
                        <img *ngIf="cardImageBase64" class="partner-logo" [src]="cardImageBase64" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-7">
            <div *ngIf="imageError" class="mb-100 is-invalid">
              <small class="invalid-feedback">{{imageError | translate}}</small>
            </div>

            <button id="uploadImageBtn" sk-spinner-button [color]="'primary'" class="mr-100" (click)="onUpload()"
                [disabled]="!file?.name || isUploading || isDimensionTooLarge" [isSpinning]="isUploading">{{ 'COMMON.UPLOAD' | translate }}
            </button>
            <button *ngIf="cardImageBase64" type="button" id="removeImageBtn" class="btn btn-outline-secondary"
              (click)="removeImage()" [disabled]="isUploading">{{ 'COMMON.CANCEL' | translate }}</button>
          </div>
        </div>
    </div>
</div>
