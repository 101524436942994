import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';

import { Observable } from 'rxjs';
import {
  catchError,
  map,
  tap,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MembersService } from './members.service';

@Injectable({
  providedIn: 'root',
})
export class MemberEmailValidator {

    constructor( private toastrService: ToastrService, private translateService: TranslateService) {}

    createValidator(membersService: MembersService): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            const requestIssueLocKey = 'settings.common.error.request-issue';
            const tryAgainLocKey = 'settings.common.try-again-later';

            return membersService
                .memberExists(control.value)
                .pipe(
                    map((result: boolean) => result ? { memberEmailExists: true } : null),
                    catchError(async () => this.translateService.get([requestIssueLocKey, tryAgainLocKey]).pipe(
                      tap((translations: string[]) => 
                        this.toastrService.error(`${translations[requestIssueLocKey]}. ${translations[tryAgainLocKey]}`))
                  ).subscribe())
                );
        };
    }
}
