import { Component, HostBinding, OnInit } from '@angular/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { NOTIFICATIONS_ROUTES_PERMISSIONS } from '../notifications/notifications-routing.module';
import { PAYMENT_ROUTES_PERMISSIONS } from '../payment/payment-routing.module';
import { MembersRoleProvider } from '../users/components/members/services/members.role.provider';

@Component({
  selector: 'sk-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
})
export class LeftNavComponent implements OnInit {
  @HostBinding('class') classes = 'sk-panel-left sk-panel-primary panel-xs-1';

  isCurrentUserAdmin: boolean;
  hasAccessToNotifications: boolean;
  hasAccessToPayment: boolean;

  constructor(private abstractUserProvider: AbstractUserProvider) {}

  ngOnInit(): void {
    const currentUser = this.abstractUserProvider.getCurrentUser();
    this.isCurrentUserAdmin =
      currentUser.hasPermission(MembersRoleProvider.PartnerPortalAdmin.key);

    // checking if user has access to any of the Payment tab (based on the setup from Payment RoutingModule)
    this.hasAccessToPayment = PAYMENT_ROUTES_PERMISSIONS.some(permission => currentUser.hasPermission(permission));

    // checking if user has access to any of the Notifications tabs (based on the setup from NotificationsRoutingModule)
    this.hasAccessToNotifications =
      NOTIFICATIONS_ROUTES_PERMISSIONS.some(permission => currentUser.hasPermission(permission));
  }
}
