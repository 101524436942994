export interface PartnerPortalUserClaims {
    authenticationType: AuthenticationType | undefined;
    isMFAEnabled: boolean;
    isSelfServicePageEnabled: boolean;
    legacyAccessRole: string;
}

export enum AuthenticationType {
    SkyKickAuth = 0,
    M365Auth = 1
}
