import { MemberErrorState } from "../models/member-error-state";

export class MembersErrorProvider {

    static readonly UnmatchedInM365 = {
        key: MemberErrorState.UnmatchedInM365.toString(),
        displayNameLocKey: 'settings.members.table.filter.error-state.unmatched-in-m365'
    };

    static readonly Errors = [
        MembersErrorProvider.UnmatchedInM365,
    ];
}
