import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sk-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  @Input() showWelcome: boolean;
  @Output() showWelcomeChange = new EventEmitter<boolean>();


  constructor() { }

  ngOnInit(): void {
  }

  onCloseButtonClicked():void{
    this.showWelcomeChange.emit(!this.showWelcome);
  }
}
