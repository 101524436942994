import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ERRORSTATES } from '../constants/error-state.const';
import { FILTERTYPE } from '../constants/filter-type.const';

@Injectable({
    providedIn: 'root'
})
export class CustomerAccountsService {
    private filteredCustomerAccounts$: BehaviorSubject<any> = new BehaviorSubject(null);
    private selectedCustomer$: BehaviorSubject<any> = new BehaviorSubject(null);
    private _customerAccounts: any;
    private _filteredAccounts: any;
    private _customersMappingFilterType: any;
    private _errorStateFilterType: any = [];

    getCustomerAccounts() {
        return this._customerAccounts;
    }

    setCustomerAccounts(customerAccounts: any) {
        this._customerAccounts = customerAccounts;
    }

    getFilteredCustomerAccounts(): Observable<any> {
        return this.filteredCustomerAccounts$.asObservable();
    }

    setFilteredCustomerAccounts(customerAccounts: any) {
        this.filteredCustomerAccounts$.next(customerAccounts);
    }

    filterCustomerAccountsByMappingFilter(filterType: string, searchString: string, resultsPerPage: number) {
        this._customersMappingFilterType = filterType;
        this.filterCustomerAccounts(searchString, resultsPerPage);
    }

    filterCustomerAccountsByErrorFilter(errorStates: string[], searchString: string, resultsPerPage: number) {
        this._errorStateFilterType = errorStates;
        this.filterCustomerAccounts(searchString, resultsPerPage);
    }

    filterCustomerAccounts(searchString: string, resultsPerPage: number) {
        if (!this._customerAccounts) {
            return;
        }

        this._filteredAccounts = this._customerAccounts;

        if (searchString) {
            searchString = searchString.toLocaleLowerCase();
            this._filteredAccounts = this._filteredAccounts.filter(item => {
                return item.orderName.toLocaleLowerCase().includes(searchString);
            });
        }

        this._filteredAccounts = this.filterCustomerAccountByErrorStates();
        this._filteredAccounts = this.filterCustomerAccountByMappingType();

        this._filteredAccounts = this._filteredAccounts.slice(0, resultsPerPage);
        this.setFilteredCustomerAccounts(this._filteredAccounts);
    }

    filterCustomerAccountByMappingType(){
        return this._filteredAccounts.filter(item => {
            if (this._customersMappingFilterType === FILTERTYPE[0].value) {
                return item;
            }
            if (this._customersMappingFilterType === FILTERTYPE[1].value) {
                return (item.agreementId && item.companyId) || item.hasAgreementError;
            }
            if (this._customersMappingFilterType === FILTERTYPE[2].value) {
                return !item.agreementId && !item.companyId;
            }
        });
    }

    filterCustomerAccountByErrorStates(){
        if (this._errorStateFilterType.length !== 0){
            return this._filteredAccounts.filter(item => {
                const hasMissingProducts = this._errorStateFilterType.some(x => x === ERRORSTATES[0].value);
                const hasAgreementError = this._errorStateFilterType.some(x => x === ERRORSTATES[1].value);

                if (hasMissingProducts && hasAgreementError){
                    return item.missingProducts || item.hasAgreementError;
                }
                if (hasMissingProducts) {
                    return item.missingProducts;
                }
                if (hasAgreementError) {
                    return item.hasAgreementError;
                }
            });
        }

        return this._filteredAccounts;
    }

    getSelectedCustomer(): Observable<any> {
        return this.selectedCustomer$.asObservable();
    }

    setSelectedCustomer(selectedCustomer: any) {
        this.selectedCustomer$.next(selectedCustomer);
    }
}
