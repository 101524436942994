import {
  PermissionFilterItemGroup,
} from 'src/app/settings/models/permission-filter-item-group';

import { CustomerPermission } from '../models';

export class CustomersPermissionProvider {
  static readonly Permissions = [
    {
      key: CustomerPermission.SearchAndRestore, // backup
      displayNameLocKey: 'settings.customers.table.filter.permission.searchandrestore',
      value: 'SearchAndRestore',
      group: PermissionFilterItemGroup.CloudBackup,
    },
    {
      key: CustomerPermission.FullAccess, // backup
      displayNameLocKey: 'settings.customers.table.filter.permission.fullaccess',
      value: 'FullAccess',
      group: PermissionFilterItemGroup.CloudBackup,
    },
    {
      key: CustomerPermission.ReadOnly, // migrations
      displayNameLocKey: 'settings.customers.table.filter.permission.readonly',
      value: 'ReadOnly',
      group: PermissionFilterItemGroup.Migrations,
    },
    {
      key: CustomerPermission.ReadAndWrite, // migrations
      displayNameLocKey: 'settings.customers.table.filter.permission.readandwrite',
      value: 'ReadAndWrite',
      group: PermissionFilterItemGroup.Migrations,
    },
  ];

  static getPermissionValueByKey(key: number): string {
    return this.Permissions.find((x) => x.key === key).value;
  }

  static getPermissionKeyByValue(value: string): number {
    return this.Permissions.find((x) => x.value === value).key;
  }

  static getPermissionDisplayNameLocKeyByValue(value: string): string {
    return this.Permissions.find((x) => x.value === value).displayNameLocKey;
  }
}
