import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';

import {
  AppId,
  RouteResolverService,
} from '@skykick/core';

import { Utils } from '../../../../../../services/utils';
import Order from '../../models/order/order';
import OrderResult from '../../models/order/order-result';
import OrderSearchFilter from '../../models/order/order-search-filter';
import { OrderConverter } from './orders.converter';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    constructor(
        private http: HttpClient,
        private routeResolverService: RouteResolverService
      ) {}

    getOrders(filter: OrderSearchFilter): Observable<Order[]> {
        return this.http.post<OrderResult[]>(
          `${this.routeResolverService.generatePipeRoute(AppId.Papi)}partner/order/search`, { withCredentials: true, ...filter })
          .pipe(
              map<OrderResult[], Order[]>(
                resultArray => resultArray.map(x => OrderConverter.convertToOrder(x))),
              catchError(error => Utils.handleError(error, OrderService.name))
            );
      }
}