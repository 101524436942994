import { HttpErrorResponse } from '@angular/common/http';

import {
  Observable,
  throwError,
} from 'rxjs';

export class Utils {
  public static handleError(errorResponse: HttpErrorResponse, serviceName: string): Observable<never> {
    console.error(`Error occurred in ${serviceName}`, errorResponse);
    return throwError(() => errorResponse);
  }
  
  public static handleHttpError(error: HttpErrorResponse, serviceName: string) {
    // Copied from an Angular example, this error handler differentiates
    // between client and server side errors.
    console.error(`ERROR OCCURED IN ${serviceName}`);
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Client or network error:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend error ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  public static logError(context: string, errorInfo: any) {
    console.error(`${context} ERROR: ${JSON.stringify(errorInfo)}`);
  }

  public static logDebug(context: string, debugInfo: any) {
    //uncomment for debugging
    //console.debug(`${context} DEBUG: ${JSON.stringify(debugInfo)}`);
  }
}
