import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, Subject, throwError } from 'rxjs';
import { catchError, first, map, takeUntil } from 'rxjs/operators';

import { SkyKickModalOptions, SkyKickModalService } from '@skykick/core';

import { AuthenticationType } from '../../../models/authentication-type';
import { M365ConnectionStatus } from '../../../models/connection-status';
import { AuthenticationMethodResourcesService } from '../../../services/authentication-method-resources.service';
import { m365authenticationSetupSteps, M365SetupStep, M365StepName } from './m365-setup-steps';

@Component({
    selector: 'sk-m365-authentication',
    templateUrl: './m365-authentication.component.html',
})
export class M365AuthenticationComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'modal-content';

    @Input() partnerAuthentication: any;
    @Input() groupsSyncEnabled: boolean;
    @Input() m365AuthConnectionStatus: number;
    @Input() initialStepName: M365StepName = M365StepName.GrantAccess;
    // Indicate if we have a users matching from danger banner or not.
    @Input() displayUserMatchStepOnly: boolean = false;

    M365ConnectionStatuses = M365ConnectionStatus;
    M365StepName = M365StepName;

    debugEnabled = false;
    grantAccessForm: UntypedFormGroup;
    misMatchForm: UntypedFormGroup;
    isLoading: boolean;
    aadUrl: any;
    mismatchedUsers = [];
    currentStep: M365SetupStep;
    authenticationSteps: M365SetupStep[] = m365authenticationSetupSteps;

    destroy$ = new Subject<void>();

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: UntypedFormBuilder,
        private authenticationMethodResourcesService: AuthenticationMethodResourcesService,
        private translate: TranslateService,
        private skyKickModalService: SkyKickModalService
    ) {}

    ngOnInit(): void {
        this.debug('ngOnInit');
        this.currentStep = this.authenticationSteps.find((s) => s.name === this.initialStepName);

        const groupAccessDefaultValue = this.determineGroupAccessDefaultValue();

        this.grantAccessForm = this.formBuilder.group({
            mfaCheck: [false, Validators.requiredTrue],
            groupAccess: [groupAccessDefaultValue, Validators.required],
        });

        if (!this.displayUserMatchStepOnly)
            this.determineStepToBeOn();
    }

    ngOnDestroy(): void {
        this.navigateToStep(M365StepName.GrantAccess);
        this.currentStep.completed = false;

        this.destroy$.next();
        this.destroy$.complete();
    }

    determineGroupAccessDefaultValue(): boolean {
      // If the partner has already passed the 1st step of switching to m365 authentication, let's set the value that he chose earlier.
      if (this.hasOngoingAuthFlow())
          return this.partnerAuthentication?.oAuthFlowState?.userWantedGroupSync;

      // If the affiliate already has an M365 type, this happens if we go through user matching from the banner.
      if (this.partnerAuthentication.authenticationType === AuthenticationType.M365Auth)
          return this.groupsSyncEnabled;

      // Otherwise, the checkbox should be active by default.
      return true;
    }

    navigateToStep(name: M365StepName) {
        const activeState = this.authenticationSteps.find((s) => s.name === name);
        const activeIndex = this.authenticationSteps.findIndex((s) => s.name === name);

        if (activeState) {
            this.authenticationSteps.forEach((step, index) => {
                step.active = false;
                step.disabled = true;

                if (index < activeIndex) {
                    step.completed = true;
                }
            });

            activeState.active = true;
            activeState.disabled = false;

            this.currentStep = activeState;
        }
    }

    determineStepToBeOn() {
        this.isLoading = true;
        this.debug('determineStepToBeOn');

        if (this.m365AuthConnectionStatus === M365ConnectionStatus.ReauthenticationRequired) {
            this.navigateToStep(M365StepName.GrantAccess);
            this.isLoading = false;
            return;
        }

        try {
          this.debug('checking connection');
            this.authenticationMethodResourcesService
                .hasValidConnection()
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: result => {
                        this.debug('connection checked');
                        // result will be null if valid connection is found, otherwise will be 404
                        if (result == null) {
                            this.grantAccessForm.get('mfaCheck').setValue(true);
                            this.getSetupLoginInfo();
                            this.navigateToStep(M365StepName.MatchUsers);
                            return;
                        }

                        if (!this.partnerAuthentication.confirmProperMFAUse || !this.mfaCheckValue) {
                            this.debug('Resetting to step 1 because Proper MFA use checkbox not set');
                            throw Error('Proper MFA use checkbox not set');
                        }
                    },
                    error: () => {
                        // A 404 is expected if valid connection is not found
                        this.isLoading = false;
                    }
                });
        } catch (e) {
            this.debug('caught exception');
            console.error(e);
            this.navigateToStep(M365StepName.GrantAccess);
            this.isLoading = false;
        }
    }

    get groupAccess(): boolean {
        return this.grantAccessForm.get('groupAccess').value;
    }

    get mfaCheckValue(): boolean {
        return this.grantAccessForm.get('mfaCheck').value;
    }

    get isMfaCheckTouched(): boolean {
        return this.grantAccessForm.get('mfaCheck').touched;
    }

    submitGrantAccessStep() {
        if (!this.isMfaCheckTouched && this.grantAccessForm.invalid) {
            this.grantAccessForm.get('mfaCheck').markAsTouched();
            return;
        }

        if (this.grantAccessForm.invalid) {
            return;
        }

        if (
            this.hasOngoingAuthFlow() &&
            this.m365AuthConnectionStatus === M365ConnectionStatus.Healthy &&
            this.partnerAuthentication?.oAuthFlowState?.userWantedGroupSync === this.groupAccess
        ) {
            this.navigateToStep(this.currentStep.next.name);
            return;
        }

        if (
            this.partnerAuthentication.authenticationType === AuthenticationType.M365Auth &&
            this.groupsSyncEnabled === this.groupAccess &&
            this.m365AuthConnectionStatus === M365ConnectionStatus.Healthy
        ) {
            this.navigateToStep(this.currentStep.next.name);
            return;
        }

        this.debug('Step 1: submitGrandAccessStep');
        this.isLoading = true;
        try {
            forkJoin([this.populateAADUrl(), this.saveFlowState().pipe(first())]).subscribe({
                next: ([populateADUrlResult, saveFlowStateResult]) => {
                    this.debug('Saved flow state and populated AADA url successfully', saveFlowStateResult, populateADUrlResult);

                    // Redirect to M365 authentication page
                    this.activeModal.close({
                        status: 'redirect',
                        url: this.aadUrl,
                    });
                    this.isLoading = false;
                },
                error: () => {
                    this.isLoading = false;
                    this.skyKickModalService.error(this.optionsError);
                }
            });
        } catch (e) {
            this.isLoading = false;
            this.skyKickModalService.error(this.optionsError);
        }
    }

    private debug(...args: any[]) {
        if (!this.debugEnabled) return;
        console.debug('M365AuthenticationComponent', arguments);
    }

    private optionsError: SkyKickModalOptions = {
        title: this.translate.instant('ERROR'),
        body: this.translate.instant('COMMON.PLEASE_TRY_AGAIN_OR_CONTACT'),
        btnLabel: this.translate.instant('COMMON.CLOSE'),
    };

    private hasOngoingAuthFlow() {
        if (this.partnerAuthentication.oAuthFlowState) {
            return !!this.partnerAuthentication.oAuthFlowState.authorizationStarted;
        }
        return false;
    }

    private populateAADUrl(): Observable<void> {
        const payload = {
            // #setupo365authentication is key for jumping to step 3 later in the process.
            originatingUri: window.location.origin + '/settings/account#setupo365authentication',
        };

        if (this.groupAccess) {
            return this.authenticationMethodResourcesService.getM365OAuthGroupLink(payload).pipe(
                takeUntil(this.destroy$),
                map((link) => (this.aadUrl = link)),
                catchError((error) => {
                    this.debug('Error in getM365OAuthGroupLink:', error);
                    return throwError(() => error);
                })
            );
        }

        return this.authenticationMethodResourcesService.getM365OAuthUserLink(payload).pipe(
            takeUntil(this.destroy$),
            map((link) => (this.aadUrl = link)),
            catchError((error) => {
                this.debug('Error in getM365OAuthUserLink:', error);
                return throwError(() => error);
            })
        );
    }

    private saveFlowState(): Observable<any> {
        this.debug('saveFlowState');
        const payload = JSON.stringify({
            authorizationStarted: true,
            userWantedGroupSync: this.grantAccessForm.get('groupAccess').value,
            confirmProperMFAUse: this.grantAccessForm.get('mfaCheck').value,
        });
        return this.authenticationMethodResourcesService.saveOAuthFlowState({ State: payload });
    }

    private async getSetupLoginInfo() {
        try {
            this.authenticationMethodResourcesService
                .getO365SetupLoginInfo()
                .pipe(first())
                .subscribe((data) => {
                    if (data?.users?.hasBasicAccess) {
                        this.isLoading = false;
                    } else {
                        throw Error('There was an issue in getting O365 login information');
                    }
                });
        } catch (err) {
            this.debug('Problem with getO365SetupLoginInfo()');
            throw Error('Problem with getO365SetupLoginInfo()');
        }
    }
}
