<sk-modal type="warning">
    <sk-modal-header (dismiss)="dismiss()">{{ 'settings.members.reset-mfa.title' | translate }}</sk-modal-header>

    <sk-modal-body>
        <p class="display-as-is">{{ 'settings.members.reset-mfa.description' | translate }}</p>
    </sk-modal-body>

    <sk-modal-footer>
        <button sk-button color="outline-secondary" (click)="close()">{{ 'settings.members.reset-mfa.cancel' | translate }}</button>
        <button sk-spinner-button [color]="'primary'" (click)="reset()" [isSpinning]="isResetRunning" 
            [disabled]="isResetRunning">{{ 'settings.members.reset-mfa.yes' | translate }}
        </button>
    </sk-modal-footer>
</sk-modal>
