<div [hidden]="!showWelcome" class="row g-0 min-width-xl max-width-xxl">
    <div class="col">
        <div class="card rounded-0 border-0 alert fade show py-0 d-flex flex-row bg-light">
            <div class="card-body">
                <div class="row  max-width-xl">
                    <div class="col-auto">
                        <img class="img-fluid" style="width: 100px;"
                            src="https://cdn2.hubspot.net/hubfs/2047087/Portal-Home/art-cloud-home.svg">
                    </div>
                    <!-- /.col -->

                    <div class="col col-xxl-10">
                        <div class="row pr-200">
                            <div class="col d-flex align-items-center justify-content-between mb-50">
                                <h3 class="font-weight-normal">{{'home.welcome' | translate}}</h3>
                            </div>
                        </div>

                        <div class="row ">
                            <div class="col-sm-4 pb-50">
                                <!--HubSpot Call-to-Action Code -->
                                <span class="hs-cta-wrapper"
                                    id="hs-cta-wrapper-33382d22-e27d-4be6-a2ec-759688237aea">
                                    <span class="hs-cta-node hs-cta-33382d22-e27d-4be6-a2ec-759688237aea"
                                        id="hs-cta-33382d22-e27d-4be6-a2ec-759688237aea">
                                        <!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]-->
                                        <a href="https://cta-redirect.hubspot.com/cta/redirect/2047087/33382d22-e27d-4be6-a2ec-759688237aea"
                                            target="_blank" rel="noopener">
                                            <img class="hs-cta-img"
                                                id="hs-cta-img-33382d22-e27d-4be6-a2ec-759688237aea"
                                                style="border-width:0px;"
                                                src="https://no-cache.hubspot.com/cta/default/2047087/33382d22-e27d-4be6-a2ec-759688237aea.png"
                                                alt="New! Cloud Manager Feature Release Steamline Cloud Admin, Accelerate Teams and deliver Cloud Security. Learn more" />
                                        </a>
                                    </span>
                                </span>
                                <!-- end HubSpot Call-to-Action Code -->
                            </div>
                            <!-- /.col -->
                            <div class="col-sm-4 pb-50 border-left">
                                <!--HubSpot Call-to-Action Code --><span class="hs-cta-wrapper"
                                    id="hs-cta-wrapper-bdabb81d-62e3-425d-be1e-6898cca706b4">
                                    <span class="hs-cta-node hs-cta-bdabb81d-62e3-425d-be1e-6898cca706b4"
                                        id="hs-cta-bdabb81d-62e3-425d-be1e-6898cca706b4">
                                        <!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]-->
                                        <a href="https://cta-redirect.hubspot.com/cta/redirect/2047087/bdabb81d-62e3-425d-be1e-6898cca706b4"
                                            target="_blank" rel="noopener"><img class="hs-cta-img"
                                                id="hs-cta-img-bdabb81d-62e3-425d-be1e-6898cca706b4"
                                                style="border-width:0px;"
                                                src="https://no-cache.hubspot.com/cta/default/2047087/bdabb81d-62e3-425d-be1e-6898cca706b4.png"
                                                alt="Request a Cloud Manager demo Get hands on with a live demo of the New Cloud Manager Book Now" />
                                        </a>
                                    </span>
                                </span><!-- end HubSpot Call-to-Action Code -->
                            </div>
                            <!-- /.col -->
                            <div class="col-sm-4 pb-50 border-left">
                                <!--HubSpot Call-to-Action Code --><span class="hs-cta-wrapper"
                                    id="hs-cta-wrapper-64429c17-133d-44b1-ba08-fb5bdd041ac7">
                                    <span class="hs-cta-node hs-cta-64429c17-133d-44b1-ba08-fb5bdd041ac7"
                                        id="hs-cta-64429c17-133d-44b1-ba08-fb5bdd041ac7">
                                        <!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]-->
                                        <a href="https://cta-redirect.hubspot.com/cta/redirect/2047087/64429c17-133d-44b1-ba08-fb5bdd041ac7"
                                            target="_blank" rel="noopener"><img class="hs-cta-img"
                                                id="hs-cta-img-64429c17-133d-44b1-ba08-fb5bdd041ac7"
                                                style="border-width:0px;"
                                                src="https://no-cache.hubspot.com/cta/default/2047087/64429c17-133d-44b1-ba08-fb5bdd041ac7.png"
                                                alt="Migration or Backup Questions? Find quick answers in our updated knowledge base. Search Help Center" /></a>
                                    </span>
                                </span><!-- end HubSpot Call-to-Action Code -->
                            </div>
                            <!-- /.col -->
                        </div>
                        <!-- /.row -->
                    </div>
                    <!-- /.col -->

                </div>
                <!-- /.row -->
            </div>
            <!-- /.card-body -->
            <div class="pr-100">
                <button type="button" class="btn-close px-0 g-0" data-bs-dismiss="alert" aria-label="Close" (click)="onCloseButtonClicked()">
                </button>
            </div>

        </div>
        <!-- /.card -->
    </div>
    <!-- /.col -->
</div>