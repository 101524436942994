import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sk-built-with-love',
  templateUrl: './built-with-love.component.html',
  styleUrls: ['./built-with-love.component.scss']
})
export class BuiltWithLoveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
