<div class="card mt-200">
    <div class="card-header sk-card-header d-flex justify-content-between">
        <div class="d-flex align-items-start">
            <h4 translate="settings.account.DISTRIBUTORS"></h4>
            <button class="btn sk-btn-icon">
                <div class="d-inline" (mouseleave)="p.close()">
                    <ng-template #popContent>
                        <div [innerHTML]="'settings.account.DISTRIBUTOR_HOVER' | translate: {url: 'https://support.skykick.com/hc/en-us/articles/360014078573'}"></div>
                    </ng-template>
                    <button class="btn sk-btn-icon" [ngbPopover]="popContent" popoverClass="sk-popover-shim" #p="ngbPopover" (mouseenter)="p.open()">
                        <i class="material-icons md-20 icon-muted align-middle">help_outline</i>    
                    </button>
                </div>
            </button>
        </div>
        <button type="button" id="addDistributorBtn" class="btn btn-link"
            (click)="addEditDistributor()" translate="settings.account.DISTRIBUTOR_ADD_DISTRIBUTOR"></button>
    </div>
    <div class="card-block">
        <table class="table table-hover sk-sortable sk-standard-wrap-table">
            <thead class="thead-white thead-sm thead-border-none">
                <tr>
                    <th translate="settings.account.DISTRIBUTOR_NAME"></th>
                    <th translate="settings.account.DISTRIBUTOR_PROVIDED_ID"></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody *ngIf="(distributorRelationships$ | async) as distributor; else loading">
                <tr *ngFor="let item of distributor">
                    <td>{{item.distributorName}}</td>
                    <td>{{item.resellerId}}</td>
                    <td>
                        <span *ngIf="item.primary" class="badge badge-default" translate="settings.account.DISTRIBUTOR_PRIMARY"></span>
                    </td>
                    <td>
                        <button *ngIf="!item.primary" type="button" id="editDistributor" (click)="addEditDistributor(item)" class="btn sk-btn-icon">
                            <i class="material-icons md-22">edit</i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <ng-template #loading>
            <tbody>
                <tr><td colspan="4">
                    <sk-text-spinner message="Loading distributors"></sk-text-spinner>
                </td></tr>
            </tbody>
        </ng-template>
    </div>
</div>