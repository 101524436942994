<div class="sk-flex-table">
    <div class="sk-scroll-container">
        <div class="d-flex flex-column min-width-sm max-width-lg py-200">
            <sk-feedback *ngIf="hasMissingProducts" class="mb-100 justify-content-between" type="danger"
                [subject]="'Product mapping issue'" [message]="'Additional mapping required to sync all customers data with ConnectWise.'">
                <button sk-button (click)="connectionWizard()" class="btn btn-outline-secondary d-inline-block" translate>Map Products</button>
            </sk-feedback>
            <sk-feedback *ngIf="hasAgreementErrors" class="mb-100" type="danger"
                [subject]="'Customer mapping issue'">
                    Resolve Customer Agreement mapping issues to resume data sync with ConnectWise.
                    <a href="https://support.skykick.com/hc/en-us/articles/360004178353-SkyKick-Cloud-Backup-ConnectWise-Manage-integration-overview"
                        target="_blank">Learn More
                    </a>
            </sk-feedback>
            <div class="d-flex align-items-center">
                <h4>Cloud Backup Customers</h4>
            </div>
            <p>
                Map your Cloud Backup Customer Names to the corresponding ConnectWise Customer Name and Agreement. Data
                synchronization occurs daily at 3:00 AM Pacific Time.
            </p>

            <div class="row m-0 mb-200 sk-min-width-lg">
                <div class="font-medium m-0 p-0 align-content-center col-sm-auto">
                    Showing {{(filteredCustomerAccounts$ | async)?.length}} of {{totalRecords}} Customers
                </div>
                <div class="ml-200 col-sm-auto">
                    <button *ngIf="(partnerData$ | async) as partnerData" (click)="syncBillingInfo()" type="button" 
                        class="btn btn-outline-secondary sk-btn-combo text-truncate" 
                        [disabled]="isSyncBillingInfo || partnerData.prorationSetting === PRORATIONSETTING.Unset">
                        <i class="material-icons md-22">sync</i>
                        <span>Sync Now</span>
                    </button>        
                </div>
                
                <div class="btn-group push-right col-sm-auto">
                    <label class="text-muted cw-text-muted mr-100 align-content-center">Filter By</label>
                    <div *ngIf="hasAgreementErrors || hasMissingProducts" id="errorStatesDropdown" ngbDropdown container="body" >
                        <button type="button" class="btn btn-basic dropdown-toggle" ngbDropdownToggle>Error State</button>
                        <div ngbDropdownMenu class="dropdown-menu">
                            <ng-container *ngFor="let filter of ERRORSTATES">
                              <div class="dropdown-item">
                                  <div class="form-check">
                                      <input [checked]="filter.isSelected" (change)="filterCustomerAccountsByErrorFilter(filter, resultsPerPage)" type="checkbox" class="form-check-input form-check-input-sm" [id]="filter.value">
                                      <label class="form-check-label" [for]="filter.value">{{filter.displayName}}</label>
                                  </div>
                              </div>
                          </ng-container>
                        </div>
                    </div>
                    <div ngbDropdown container="body" >
                        <button type="button" class="btn btn-basic dropdown-toggle" ngbDropdownToggle>{{ filterTypeName }}</button>
                        <div ngbDropdownMenu class="dropdown-menu">
                            <a *ngFor="let item of FILTERTYPE; let index = index" 
                                type="button" ngbDropdownItem class="dropdown-item" id="filterType{{index}}"
                                [class.active]="item.value === filterTypeValue"
                                (click)="filterTypeValue = item.value; filterTypeName = item.name; filterCustomerAccountsByMappingFilter(resultsPerPage)">{{item.name}}
                                <i *ngIf="item.value === filterTypeValue" class="material-icons md-22 ml-100">check</i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="d-inline-flex flex-shrink-0 col-sm-auto">
                    <div class="sk-input-icon input-width-sm">
                        <form [formGroup]="form" novalidate>
                            <input type="text" class="form-control" formControlName="search" placeholder="Search" />
                            <i *ngIf="!form.get('search').value" class="material-icons md-18">search</i>
                            <i *ngIf="form.get('search').value" class="material-icons md-18 sk-reset-search cursor-pointer" 
                                (click)="form.get('search').setValue('')" title="Reset and Exit Search"></i>
                        </form>
                    </div>
                </div>
            </div>

            <table class="table table-hover sk-standard-wrap-table sk-sortable sk-sticky-header sk-min-width-lg">
                <thead class="thead-white thead-sm thead-border-none">
                    <tr>
                        <th>SkyKick Customer Name</th>
                        <th>ConnectWise Customer Name</th>
                        <th>ConnectWise Agreement</th>
                        <th>Products Mapped</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody *ngIf="(filteredCustomerAccounts$ | async) as filteredCustomerAccounts; else loading">
                    <tr *ngIf="!filteredCustomerAccounts?.length">
                        <td colspan="4">
                            No results available
                        </td>
                    </tr>

                    <tr *ngFor="let company of filteredCustomerAccounts" (click)="selectItem(company, $event)" [class.active]="(selectedCustomer$ | async)?.backupServiceId == company.backupServiceId">
                        <td>{{company.orderName}}</td>
                        <td [class.text-muted]="!company.companyName">{{company.companyName}}</td>
                        <td [class.text-muted]="!company.agreementName">
                            <div *ngIf="!company.hasAgreementError; else agreementErrorIcon" class="d-flex align-items-center">
                                {{company.agreementName}}
                                <i *ngIf="company.isEnabled === false && company.agreementName" class="material-icons md-18 icon-danger ml-50" ngbPopover="Mapping is disabled. Re-create mapping to activate it." triggers="mouseenter:mouseleave">warning</i>
                            </div>
                            <ng-template #agreementErrorIcon>
                                <i class="material-icons md-18 icon-danger" ngbPopover="Issue" triggers="mouseenter:mouseleave">warning</i>
                            </ng-template>
                        </td>
                        <td>
                            <div *ngIf="company.agreementName">
                                <div *ngIf="!company.missingProducts; else productErrorIcon">
                                    <i class="material-icons md-18 icon-success" ngbPopover="Active" triggers="mouseenter:mouseleave">check_circle</i>
                                </div>
                                <ng-template #productErrorIcon>
                                    <i class="material-icons md-18 icon-danger" ngbPopover="Issue" triggers="mouseenter:mouseleave">warning</i>
                                </ng-template>
                            </div>
                        </td>
                        <td>
                            <div *ngIf="company.orderMappingId" id="sk-actions-menu" ngbDropdown class="btn-group" skStopPropagation>
                                <button ngbDropdownToggle type="button" class="btn sk-btn-icon caret-hidden">
                                    <i class="material-icons md-22">more_horiz</i>
                                </button>
                                <div class="dropdown-menu" ngbDropdownMenu>
                                    <a class="dropdown-item" (click)="removeMapping(company)" ngbDropdownItem>Remove Mapping</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <ng-template #loading>
                    <tbody>
                        <tr><td colspan="5">
                            <sk-text-spinner message="Loading customer mappings"></sk-text-spinner>
                        </td></tr>
                    </tbody>
                </ng-template>
            </table>

        </div>
    </div>
</div>
