import { IBuildInfo } from '../models/IBuildInfo';

export abstract class BuildInfo {

  // These are intended to be replaced during the build process`    
  private static _buildId = '${BUILD_VERSION}';


  static isEnvironmentReplacement(value: string): boolean {
    if (!value) {
      return false;
    }
    // Checks if the value is in the form of ${xxxx}
    // If so, the variable has NOT been replaced.
    return value.startsWith('${') && value.endsWith('}');

  }

  static get(): IBuildInfo {
    return {
      version: this.isEnvironmentReplacement(this._buildId) ? '2.0' : this._buildId
    }
  }
}