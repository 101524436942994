import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CardsUtilities {
    abbreviateCount(input: number): string{


        if (!input) {
            return '0';
        }

        const count = Math.floor(input);
        let result = 0;

        if (count < 1000)
        {
            return count.toString();
        }
        if (count >= 1000 && count < 10000)
        {
            result = Math.floor(count / 100.0) / 10;
            return result.toString() + 'K';
        }
        if (count >= 10000 && count < 1000000)
        {
            result = Math.floor(count / 100.0) / 10;
            return result.toString() + 'K';
        }
        if (count >= 1000000 && count < 10000000)
        {
            result = Math.floor(count / 100.0) / 10;
            return result.toString() + 'M';
        }
        else
        {
            return '10M+';
        }
    }
}
