export const TIMEZONES = [
    "Etc/GMT+12",
    "Etc/GMT+11",
    "America/Adak",
    "Pacific/Honolulu",
    "Pacific/Marquesas",
    "America/Anchorage",
    "Etc/GMT+9",
    "America/Tijuana",
    "Etc/GMT+8",
    "America/Los_Angeles",
    "America/Phoenix",
    "America/Chihuahua",
    "America/Denver",
    "America/Guatemala",
    "America/Chicago",
    "Pacific/Easter",
    "America/Mexico_City",
    "America/Regina",
    "America/Bogota",
    "America/Cancun",
    "America/New_York",
    "America/Port-au-Prince",
    "America/Havana",
    "America/Indianapolis",
    "America/Grand_Turk",
    "America/Asuncion",
    "America/Halifax",
    "America/Caracas",
    "America/Cuiaba",
    "America/La_Paz",
    "America/Santiago",
    "America/St_Johns",
    "America/Araguaina",
    "America/Sao_Paulo",
    "America/Cayenne",
    "America/Buenos_Aires",
    "America/Godthab",
    "America/Montevideo",
    "America/Punta_Arenas",
    "America/Miquelon",
    "America/Bahia",
    "Etc/GMT+2",
    "Atlantic/Azores",
    "Atlantic/Cape_Verde",
    "Etc/GMT",
    "Europe/London",
    "Atlantic/Reykjavik",
    "Africa/Sao_Tome",
    "Africa/Casablanca",
    "Europe/Berlin",
    "Europe/Budapest",
    "Europe/Paris",
    "Europe/Warsaw",
    "Africa/Lagos",
    "Asia/Amman",
    "Europe/Bucharest",
    "Asia/Beirut",
    "Africa/Cairo",
    "Europe/Chisinau",
    "Asia/Damascus",
    "Asia/Hebron",
    "Africa/Johannesburg",
    "Europe/Kiev",
    "Asia/Jerusalem",
    "Europe/Kaliningrad",
    "Africa/Khartoum",
    "Africa/Tripoli",
    "Africa/Windhoek",
    "Asia/Baghdad",
    "Europe/Istanbul",
    "Asia/Riyadh",
    "Europe/Minsk",
    "Europe/Moscow",
    "Africa/Nairobi",
    "Asia/Tehran",
    "Asia/Dubai",
    "Europe/Astrakhan",
    "Asia/Baku",
    "Europe/Samara",
    "Indian/Mauritius",
    "Europe/Saratov",
    "Asia/Tbilisi",
    "Europe/Volgograd",
    "Asia/Yerevan",
    "Asia/Kabul",
    "Asia/Tashkent",
    "Asia/Yekaterinburg",
    "Asia/Karachi",
    "Asia/Qyzylorda",
    "Asia/Calcutta",
    "Asia/Colombo",
    "Asia/Katmandu",
    "Asia/Almaty",
    "Asia/Dhaka",
    "Asia/Omsk",
    "Asia/Rangoon",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Hovd",
    "Asia/Krasnoyarsk",
    "Asia/Novosibirsk",
    "Asia/Tomsk",
    "Asia/Shanghai",
    "Asia/Irkutsk",
    "Asia/Singapore",
    "Australia/Perth",
    "Asia/Taipei",
    "Asia/Ulaanbaatar",
    "Australia/Eucla",
    "Asia/Chita",
    "Asia/Tokyo",
    "Asia/Pyongyang",
    "Asia/Seoul",
    "Asia/Yakutsk",
    "Australia/Adelaide",
    "Australia/Darwin",
    "Australia/Brisbane",
    "Australia/Sydney",
    "Pacific/Port_Moresby",
    "Australia/Hobart",
    "Asia/Vladivostok",
    "Australia/Lord_Howe",
    "Pacific/Bougainville",
    "Asia/Srednekolymsk",
    "Asia/Magadan",
    "Pacific/Norfolk",
    "Asia/Sakhalin",
    "Pacific/Guadalcanal",
    "Asia/Kamchatka",
    "Pacific/Auckland",
    "Etc/GMT-12",
    "Pacific/Fiji",
    "Pacific/Chatham",
    "Etc/GMT-13",
    "Pacific/Tongatapu",
    "Pacific/Apia",
    "Pacific/Kiritimati"
  ]