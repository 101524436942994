import { MemberStatus } from '../models/member-status';

export class MembersStatusProvider {

    static readonly Statuses = [
        { key: MemberStatus.Active, displayNameLocKey: 'settings.members.table.filter.status.active', value: 'Active' },
        { key: MemberStatus.Inactive, displayNameLocKey: 'settings.members.table.filter.status.deactivated', value: 'Inactive' },
        { key: MemberStatus.Pending, displayNameLocKey: 'settings.members.table.filter.status.pending', value: 'Pending' },
    ];

    static getStatusValueByKey(key: MemberStatus): string {
        return this.Statuses.find(x => x.key === key).value;
    }

    static getStatusDisplayNameLocKeyByValue(value: string): string {
        return this.Statuses.find(x => x.value === value).displayNameLocKey;
    }
}
