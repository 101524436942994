import {
  Component,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { throwError } from 'rxjs';
import {
  catchError,
  tap,
} from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import {
  PasswordValidatorService,
} from '../settings/shared/services/password-validator.service';
import {
  InvitationValidationResultType,
} from './models/invitation-validation-result-type';
import { Language } from './models/language.enum';
import { MemberProfileService } from './services/member-profile.service';

@Component({
  selector: 'sk-app-complete-member-profile-form',
  templateUrl: './complete-member-profile-form.component.html',
  styleUrls: ['./complete-member-profile-form.component.scss']
})
export class CompleteMemberProfileFormComponent implements OnInit {
  loading: boolean = false;
  executingMainAction: boolean = false;
  error: boolean = false;
  errorTitle: string;
  errorDescription: string;
  errorContactLink: string;
  invitationInvalid: boolean = false;
  invitationExpired: boolean = false;
  invitationUsed: boolean = false;
  formDisabled: boolean = true;
  openLanguageDropdown: boolean = false;
  selectedLanguage: string = '';
  languages: Array<string> = Object.keys(Language).map(key => Language[key]);
  token: string = "";
  emailAddress: string = "";
  portalUrl: string = "";
  showPassword: boolean = false;
  showReenteredPassword: boolean = false;
  completeProfileForm: UntypedFormGroup;
  isFormSubmitted: boolean = false;
  year: number = new Date().getFullYear();

  private namePattern: string = "^[^<>:;()\\\/*+=@#$%!]+$";
  private businessExtensionPattern: string = "^[0-9]+$";

  constructor(
    private activatedRoute: ActivatedRoute,
    private memberProfileService: MemberProfileService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private passwordValidatorService: PasswordValidatorService) {
  }

  ngOnInit(): void {
    const somethingWrongLocKey = 'settings.common.error.something-wrong';
    const requestIssueLocKey = 'settings.common.error.request-issue';
    const tryAgainLocKey = 'settings.common.try-again-later';
    const contactForAssistanceLockKey = 'settings.common.error.contact-for-assistance';
    this.translateService.get([somethingWrongLocKey, requestIssueLocKey, tryAgainLocKey, contactForAssistanceLockKey])
        .pipe(
            tap(translations => {
                this.errorTitle = translations[somethingWrongLocKey];
                this.errorDescription = `${translations[requestIssueLocKey]}. ${translations[tryAgainLocKey]}`;
                this.errorContactLink = translations[contactForAssistanceLockKey];
            }))
        .subscribe();

    this.completeProfileForm = this.formBuilder.group(
      {
        firstName: new UntypedFormControl('', [ Validators.required, Validators.pattern(this.namePattern) ]),
        lastName: new UntypedFormControl('', [ Validators.required, Validators.pattern(this.namePattern) ]),
        jobTitle: new UntypedFormControl('', [ Validators.required ]),
        cellPhone: new UntypedFormControl('', [ Validators.required ]),
        businessPhone: new UntypedFormControl('', [ Validators.required ]),
        extension: new UntypedFormControl('', [ Validators.pattern(this.businessExtensionPattern), Validators.min(1), Validators.max(9999999999)]),
        password: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(64),
          this.passwordValidatorService.passwordPatternsMatch()
        ]),
        reenteredPassword: new UntypedFormControl('', [ Validators.required ])
      },
      {
        validators: this.passwordsMatch('password', 'reenteredPassword')
      });
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
      this.loading = true;
      this.memberProfileService.validateInvitation(this.token).pipe(
          tap((response) => {
            this.portalUrl = response.PortalUrl;
            if (response.EmailAddress) {
              this.emailAddress = response.EmailAddress;
            }
            switch (response.InvitationValidationResultType) {
              case InvitationValidationResultType.InvitationValid:
                this.formDisabled = false;
                break;
              case InvitationValidationResultType.InvitationExpired:
                this.invitationExpired = true;
                break;
              case InvitationValidationResultType.InvitationUsed:
                this.invitationUsed = true;
                break;
              default:
                this.invitationInvalid = true;
            }
            this.loading = false;
          }),
          catchError((error) => {
            this.error = true;
            this.loading = false;
            return throwError(() => error);
          })
        ).subscribe();
    });
  }

  toggleLanguageDropdown($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.openLanguageDropdown = !this.openLanguageDropdown;
  }

  setLanguage(language: string) {
    this.selectedLanguage = language;
    this.translateService.use(language);
    this.openLanguageDropdown = false;
  }

  isFieldEmpty(fieldName: string): boolean {
    return this.isFormSubmitted && this.completeProfileForm.get(fieldName).hasError("required");
  }

  isFieldPatternInvalid(fieldName: string): boolean {
    return this.isFormSubmitted && this.completeProfileForm.get(fieldName).hasError("pattern");
  }

  isFieldRangeInvalid(fieldName: string): boolean {
    return this.isFormSubmitted && (this.completeProfileForm.get(fieldName).hasError("min") || this.completeProfileForm.get(fieldName).hasError("max"));
  }

  isFieldInvalid(fieldName: string): boolean {
    return this.isFormSubmitted && this.completeProfileForm.get(fieldName).invalid;
  }

  isPasswordInvalid(): boolean {
    return this.isFormSubmitted && this.completeProfileForm.errors && this.completeProfileForm.errors.noMatch === true;
  }

  markInvalidFormFields() {
    this.completeProfileForm.markAllAsTouched();
    Object.keys(this.completeProfileForm.controls).forEach(field => {
      const control = this.completeProfileForm.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  passwordsMatch(firstControl, secondControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get(firstControl).value;
      const reenteredPassword = control.get(secondControl).value;

      if (password !== "" && reenteredPassword != "" && password != reenteredPassword) { return { 'noMatch': true } }

      return null;
    };
  }

  hasPasswordPatternErrors(): boolean {
    return this.passwordValidatorService.hasPasswordPatternErrors();
  }

  isPasswordRuleValid(ruleName: string): boolean {
    return this.passwordValidatorService.isPasswordRuleValid(this.completeProfileForm.controls.password, ruleName);
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  toggleReenteredPassword(): void {
    this.showReenteredPassword = !this.showReenteredPassword;
  }

  completeMemberProfile() {
    if (this.formDisabled) return;

    this.isFormSubmitted = true;

    if (this.completeProfileForm.invalid) {
      this.markInvalidFormFields();
      return;
    }

    this.executingMainAction = true;
    this.memberProfileService.completeProfile({
          FirstName: this.completeProfileForm.controls.firstName.value,
          LastName: this.completeProfileForm.controls.lastName.value,
          JobTitle: this.completeProfileForm.controls.jobTitle.value,
          CellPhone: this.completeProfileForm.controls.cellPhone.value,
          BusinessPhone: this.completeProfileForm.controls.businessPhone.value,
          Extension: this.completeProfileForm.controls.extension.value,
          Password: this.completeProfileForm.controls.password.value
        },
      this.token).pipe(
        tap((response) => {
          window.location.href = response.PortalUrl;
        }),
        catchError((error) => {
          this.error = true;
          this.executingMainAction = false;
          return throwError(() => error);
        })
    ).subscribe();
  }
}
