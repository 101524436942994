export class LogoUpload {
    base64Encoding: string;
    contentType: string;
    name: string;
    constructor(
        base64Encoding: string,
        contentType: string,
        name: string
    ) {
        this.base64Encoding = base64Encoding;
        this.contentType = contentType;
        this.name = name;
    }
}