import { Injectable } from '@angular/core';

import Order from '../../models/order/order';
import OrderResult from '../../models/order/order-result';

@Injectable({
    providedIn: 'root'
})
export class OrderConverter {
    static convertToOrder(orderResult: OrderResult): Order{
        const order: Order = {
            id: orderResult.id,
            salesOrderId: orderResult.salesOrderId,
            name: orderResult.name,
            productTag: orderResult.productTag,
            number: orderResult.number,
            orderType: orderResult.orderType
        };
        return order;
    }
}
